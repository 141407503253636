import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import _ from 'lodash'
import moment from 'moment-timezone'

import { API, makeResourceReducer, ResourceAction } from '../api'
import {
  BATTERY_ACCUMULATED_THROUGHPUT_KWH_TAG,
  BATTERY_ANNUAL_CYCLE_COUNT_TAG,
  EXPANDED_TELEMETRY_PREFIX,
  INTERVAL_SIZE_MINS,
  WARRANTY_TAG,
  BATTERY_COMMAND_SETTING_TAG,
  COMMERCIAL_BATTERY_COMMAND_SETTING_TAG
} from '../../utility/constants'
import { getIndex } from '../../utility/utility'
import { getChartData, intervalStateCreator } from './interval'

const select4sTelemetryData = createSelector(
  [
    // Get 4 second telemetry data
    (stateTelemetryRoot, stateSubRootSimulated, powerTag4s, powerTagSimulated, startTime, startDate) => {
      const telemetryData = getChartData(stateTelemetryRoot, powerTag4s, startTime)
      const telemetryValues = telemetryData?.data?.map(datum => datum?.value)
      const telemetryChartData = {
        ...telemetryData,
        data: { startTime: moment(startTime).toISOString(), values: [telemetryValues] },
      }
      return telemetryChartData
    },
    // Get market simulated data (we will extract forecast data from this stream)
    (stateSubRoot4s, stateSubRootSimulated, powerTag4s, powerTagSimulated, startTime, startDate) => {
      return getChartData(stateSubRootSimulated, powerTagSimulated, startTime)
    },
    // Fn just to pass all args to the output function
    (stateSubRoot4s, stateSubRootSimulated, powerTag4s, powerTagSimulated, startTime, startDate) => {
      return { stateSubRoot4s, stateSubRootSimulated, powerTag4s, powerTagSimulated, startTime, startDate }
    },
  ],
  (chartData4s, chartDataSimulated, otherArgs) => {
    let result = {}
    const forecastStartTime = moment(_.get(chartDataSimulated, 'data.forecastStartTime'))
    if (forecastStartTime.isValid()) {
      const forecastStartIndex = getIndex(
        forecastStartTime,
        moment(otherArgs.startDate),
        moment(otherArgs.startDate).add(1, 'days'),
        INTERVAL_SIZE_MINS,
      )
      result = { ...chartData4s }
      if (forecastStartIndex !== -1) {
        const tempBatteryPowerData = _.get(chartData4s, ['data', 'values', 0], []).slice(0, forecastStartIndex)
        const tempForecastedPowerData = _.get(chartDataSimulated, ['data', 'values', 0], []).slice(forecastStartIndex)
        _.set(result, 'data.values[0]', [...tempBatteryPowerData, ...tempForecastedPowerData])
      }
    }
    return result
  },
)

const create4sTelemetrySelector = (...allArgs) => {
  return select4sTelemetryData(...allArgs)
}

// Selector Last Contracts
export const selectLastWarrantyContract = state => {
  return _.get(state, 'assetOperation.lastWarrantyContract')
}

export const selectors = {
  create4sTelemetrySelector,
  select4sTelemetryData,
  selectLastWarrantyContract,
}

const GET_ASSET_INTERVALS_TYPES = {
  request: 'GET_ASSET_INTERVALS_REQUEST',
  success: 'GET_ASSET_INTERVALS_SUCCESS',
  error: 'GET_ASSET_INTERVALS_ERROR',
}

export const getAssetIntervals = (assetId, tag, startTime, endTime) => {
  return API.marketIntervals.getAssetIntervals({
    pathParams: { assetId },
    queryParams: {
      tag,
      startTime: moment(startTime).toISOString(),
      endTime: moment(endTime).toISOString(),
    },
    types: GET_ASSET_INTERVALS_TYPES,
    cache: false,
    meta: { updatedOn: moment() },
  })
}

const GET_AGG_INTERVALS_TYPES = {
  request: 'GET_AGG_INTERVAL_REQUEST',
  success: 'GET_AGG_INTERVAL_SUCCESS',
  error: 'GET_AGG_INTERVAL_ERROR',
}
const GET_AGG_INTERVALS_MULTIPLE_TYPES = {
  request: 'GET_AGG_INTERVALS_MULTIPLE_REQUEST',
  success: 'GET_AGG_INTERVALS_MULTIPLE_SUCCESS',
  error: 'GET_AGG_INTERVALS_MULTIPLE_ERROR',
}

export const GET_SOE_CALC_INTERVAL_TYPES = {
  request: 'GET_SOE_CALC_INTERVAL_REQUEST',
  success: 'GET_SOE_CALC_INTERVAL_SUCCESS',
  error: 'GET_SOE_CALC_INTERVAL_ERROR',
}
export const EXTENDED_TELEMETRY_LATEST_VALS = 'EXTENDED_TELEMETRY_LATEST_VALS'
export const saveExtendedTelemetryLatestVals = payload => ({
  type: EXTENDED_TELEMETRY_LATEST_VALS,
  payload,
})

export const getAggregatedIntervals = (
  assetId,
  tag,
  startTime,
  endTime,
  duration,
  aggregationFunction,
  filterDirection,
  intervalConfig,
  intervalTimeConfig,
  ifFillNaN = false,
  types = GET_AGG_INTERVALS_TYPES,
) => {
  return API.marketIntervals.getAssetAggregatedIntervals({
    pathParams: { assetId },
    queryParams: {
      tag,
      startTime: moment(startTime).toISOString(),
      endTime: moment(endTime).toISOString(),
      duration,
      aggregationFunction,
      filterDirection,
      ifFillNaN: ifFillNaN,
    },
    types: types,
    cache: false,
    meta: { AGG: 'TEST', intervalConfig, intervalTimeConfig },
  })
}

export const getAggregatedIntervalsMultiple = (
  assetId,
  tags,
  startTime,
  endTime,
  duration,
  aggregationFunction,
  filterDirection,
  intervalConfig,
  intervalTimeConfig,
  ifFillNaN = false,
  types = GET_AGG_INTERVALS_MULTIPLE_TYPES,
) => {
  return API.marketIntervals.getAssetAggregatedIntervalsMultiple({
    pathParams: { assetId },
    queryParams: {
      tags,
      startTime: moment(startTime).toISOString(),
      endTime: moment(endTime).toISOString(),
      duration,
      aggregationFunction,
      filterDirection,
      ifFillNaN: ifFillNaN,
    },
    types: types,
    cache: false,
    meta: { AGG: 'TEST', intervalConfig, intervalTimeConfig },
  })
}

const GET_LAST_CONTRACTS_TYPES = {
  request: 'GET_LAST_CONTRACTS_REQUEST',
  success: 'GET_LAST_CONTRACTS_SUCCESS',
  error: 'GET_LAST_CONTRACTS_ERROR',
}

export const getLastWarrantyContracts = (
  assetId,
  startTime = moment().toISOString(),
  contractType = WARRANTY_TAG,
  asOf = null,
  deleted = false,
) => {
  return API.marketContracts.getLastContracts({
    pathParams: { assetId },
    queryParams: {
      startTime: moment(startTime).toISOString(),
      contractType,
      asOf: moment(asOf).toISOString(),
      deleted,
    },
    types: GET_LAST_CONTRACTS_TYPES,
    cache: false,
  })
}

// Reducer Last Contracts. store one contract from array of contracts
const lastWarrantyContract = (state = {}, action) => {
  switch (action.type) {
    case GET_LAST_CONTRACTS_TYPES.success: {
      if (_.isEmpty(action.payload) && !_.isEqual(action.payload, state)) {
        return action.payload
      } else if (!_.isEmpty(action.payload) && !_.isEqual(action.payload, state)) {
        return action.payload.sort((a, b) => moment(b.createdOn) - moment(a.createdOn))[0]
      }
      return state
    }
    default:
      return state
  }
}

const getLastIntervalDataResourceStart = tag =>
  new ResourceAction({
    type: `GET_LAST_INTERVAL_START_${tag}`.toUpperCase(),
    config: (assetId, asOf) => {
      return {
        endpoint: `/market/intervals/asset/${assetId}/last?asOf=${encodeURIComponent(asOf.toISOString())}&tag=${tag}`,
      }
    },
  })

const getLastIntervalDataResource = tag =>
  new ResourceAction({
    type: `GET_LAST_INTERVAL_${tag}`.toUpperCase(),
    config: (assetId, asOf) => {
      return {
        endpoint: `/market/intervals/asset/${assetId}/last?asOf=${encodeURIComponent(asOf.toISOString())}&tag=${tag}`,
      }
    },
  })

export const accumulatedThroughputResourceStart = getLastIntervalDataResourceStart(
  BATTERY_ACCUMULATED_THROUGHPUT_KWH_TAG,
)
export const accumulatedThroughputResource = getLastIntervalDataResource(BATTERY_ACCUMULATED_THROUGHPUT_KWH_TAG)
export const annualCycleCountResource = getLastIntervalDataResource(BATTERY_ANNUAL_CYCLE_COUNT_TAG)

const accumulatedThroughputStart = makeResourceReducer(accumulatedThroughputResourceStart, {})
const accumulatedThroughput = makeResourceReducer(accumulatedThroughputResource, {})

const annualCycleCount = makeResourceReducer(annualCycleCountResource, {})

const GET_RANGE_ACTIVE_ASSET_SCHEDULED_DATA_TYPES = {
  request: 'GET_RANGE_ACTIVE_ASSET_SCHEDULED_DATA_TYPES_REQUEST',
  success: 'GET_RANGE_ACTIVE_ASSET_SCHEDULED_DATA_TYPES_SUCCESS',
  error: 'GET_RANGE_ACTIVE_ASSET_SCHEDULED_DATA_TYPES_ERROR',
}

export const getRangeOfActiveAssetScheduledData = (assetId, startTime, endTime, intervalConfig, intervalTimeConfig) => {
  return API.marketIntervals.getRangeOfActiveAssetScheduledData({
    pathParams: { assetId },
    queryParams: {
      startTime: moment(startTime).toISOString(),
      endTime: moment(endTime).toISOString(),
      tag: BATTERY_COMMAND_SETTING_TAG,
    },
    types: GET_RANGE_ACTIVE_ASSET_SCHEDULED_DATA_TYPES,
    cache: false,
    meta: { AGG: 'TEST', intervalConfig, intervalTimeConfig },
  })
}

const activeAssetScheduledData = (state = {}, action) => {
  switch (action.type) {
    case GET_RANGE_ACTIVE_ASSET_SCHEDULED_DATA_TYPES.success: {
      if (_.isEmpty(action.payload) && !_.isEqual(action.payload, state)) {
        return action.payload
      } else if (!_.isEmpty(action.payload) && !_.isEqual(action.payload, state)) {
        return action.payload
      }
      return state
    }
    default:
      return state
  }
}

const GET_COMMERCIAL_SCHEDULED_DATA_TYPES = {
  request: 'GET_COMMERCIAL_SCHEDULED_DATA_TYPES_REQUEST',
  success: 'GET_COMMERCIAL_SCHEDULED_DATA_TYPES_SUCCESS',
  error: 'GET_COMMERCIAL_SCHEDULED_DATA_TYPES_ERROR',
}

export const getCommercialScheduledData = (assetId, startTime, endTime, intervalConfig, intervalTimeConfig) => {
  return API.marketIntervals.getCommercialScheduledData({
    pathParams: { assetId },
    queryParams: {
      startTime: moment(startTime).toISOString(),
      endTime: moment(endTime).toISOString(),
      tag: COMMERCIAL_BATTERY_COMMAND_SETTING_TAG,
    },
    types: GET_COMMERCIAL_SCHEDULED_DATA_TYPES,
    cache: false,
    meta: { AGG: 'TEST', intervalConfig, intervalTimeConfig },
  })
}

const commercialScheduledData = (state = {}, action) => {
  switch (action.type) {
    case GET_COMMERCIAL_SCHEDULED_DATA_TYPES.success: {
      if (_.isEmpty(action.payload) && !_.isEqual(action.payload, state)) {
        return action.payload
      } else if (!_.isEmpty(action.payload) && !_.isEqual(action.payload, state)) {
        return action.payload
      }
      return state
    }
    default:
      return state
  }
}

export const warrantyConstraintSettingSelector = state =>
  _.get(state, 'assetOperation.warrantyConstraintSetting.payload')

const intervals = intervalStateCreator([GET_AGG_INTERVALS_TYPES])
const expandedTelemetryIntervals = intervalStateCreator([GET_AGG_INTERVALS_MULTIPLE_TYPES])

const expandedTelemetryLatestVals = (state = [], action) => {
  switch (action.type) {
    case EXTENDED_TELEMETRY_LATEST_VALS: {
      const index = action.payload.index
      state[index] = action.payload.val
      return state
    }
    default:
      return state
  }
}

export default combineReducers({
  accumulatedThroughputStart,
  accumulatedThroughput,
  annualCycleCount,
  intervals,
  [EXPANDED_TELEMETRY_PREFIX]: expandedTelemetryIntervals,
  expandedTelemetryLatestVals,
  lastWarrantyContract,
  activeAssetScheduledData,
  commercialScheduledData,
})
