import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import clsx from 'clsx'
import _ from 'lodash'
import moment from 'moment-timezone'
import download from 'downloadjs'
import numeral from 'numeral'
import { Box, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Download as DownloadIcon } from '@fluence/core'
import { getMarketStartGivenTimestamp, isSelectedDateActiveMarketDay } from '../../utility/time-utils'
import {
  availabilityTag,
  convertkWToMW,
  findProduct,
  getAssetCapacityText,
  getCardSubtitleByDate,
  getIndex,
  powerTag,
  soeTag as soeTagSuffix,
  processSOELimits,
  getTradingStart,
} from '../../utility/utility'
import { getChartData, getKey, IntervalConfig, IntervalTimeConfig } from '../../redux/features/interval'
import { getCollatedAssetIntervals, getWarrantyConstraintIntervals } from '../../redux/features/collated-interval'
import { getAssetProductInterval } from '../../redux/features/forecast'
import { globalAppSelectors } from '../../redux/features/app'
import {
  getAssetIntervals,
  getAggregatedIntervals,
  selectors as assetOperationSelectors,
  getAggregatedIntervalsMultiple,
  saveExtendedTelemetryLatestVals,
  getRangeOfActiveAssetScheduledData,
  getCommercialScheduledData,
} from '../../redux/features/asset-operation'
import Card from '../Card'
import {
  BATTERY_4SEC_TAGS,
  CONSUMED_RESERVE_ENERGY,
  DATE_FORMAT,
  DATE_FORMAT_NO_SEPARATOR,
  DATE_FORMAT_NO_SEPARATOR_WITH_SECONDS,
  DATETIME_FORMAT,
  DATETIME_FORMAT_WITH_SECONDS,
  EXPANDED_TELEMETRY_PREFIX,
  EXPANDED_TELEMETRY_TAGS,
  FIVE_MINUTE_DURATION,
  NUM_FIVE_MIN_INTERVALS_PER_DAY,
  TIME_FORMAT,
  TIME_FORMAT_WITH_SEC,
  WARRANTY_TAG,
} from '../../utility/constants'
import AssetOperationsGraph from './graphs/AssetOperationsGraph'

const DISPLAY_NAME = 'AssetOperationsChartCard'

const useStyles = makeStyles(
  theme => ({
    root: {},
    card: {
      minHeight: 470,
    },
    action: {
      paddingRight: theme.spacing(),
    },
  }),
  { name: DISPLAY_NAME },
)

function AssetOperationsChartCard(props) {
  const classes = useStyles(props)
  const { className: classNameProp, asset, selectedRange, timezone = 'Etc/GMT-10' } = props
  const {
    assetOperation,
    collated,
    forecast,
    fetchData,
    dispatchGetAssetIntervals,
    getSettlementIntervalByTime,
    dispatchSaveExtendedTelemetryLatestVals,
    expandedTelemetryLatestVals,
  } = props

  moment.tz.setDefault(timezone)
  const assetId = _.get(asset, 'assetId')
  const isBattery = _.get(asset, 'data.tag_prefix') === 'battery'
  const isLive = _.get(asset, 'data.is_live', false)
  const marketStartHour = _.get(asset, 'market.data.trading_day_start_hour')
  const bidInterval = _.get(asset, 'market.data.bid_interval')
  const horizon = 0
  const productId = _.get(findProduct(asset, 'ENERGY', 'GEN'), 'productId')
  const numBatteries = _.get(asset, 'data.number_batteries')
  const batteryCapacity = _.get(asset, 'data.battery_capacity_kwh')
  const totalCapacity = batteryCapacity * numBatteries
  const { start: startTime, end: endTime } = selectedRange

  const [lastUpdated, setLastUpdated] = useState()
  const [loading, setLoading] = useState(true)
  const [displayRange, setDisplayRange] = useState({
    start: null,
    end: null,
  })
  const [downloadInProgress, setDownloadInprogress] = useState(false)

  const tag = isLive ? BATTERY_4SEC_TAGS.LIVE.POWER_TAG : BATTERY_4SEC_TAGS.SIMULATED.POWER_TAG
  const key4sPower = `${asset.assetId}_${tag}`
  const keySimulatedPower = `${asset.assetId}_${asset.data.tag_prefix + powerTag}`
  const startTimestamp = moment(startTime).valueOf()
  const startDate = getMarketStartGivenTimestamp(moment(startTime), marketStartHour, timezone)
  const startDateTimestamp = moment(startDate).valueOf()

  const battery4sPowerData = isBattery
    ? assetOperationSelectors.create4sTelemetrySelector(
      assetOperation,
      collated,
      key4sPower,
      keySimulatedPower,
      startTimestamp,
      startDateTimestamp,
    )
    : { isLoading: true }

  const powerChartData = isBattery
    ? battery4sPowerData
    : getChartData(collated, `${asset.assetId}_${asset.data.tag_prefix + powerTag}`, startTime)

  const dataPrefix = getKey(`${asset.assetId}_${EXPANDED_TELEMETRY_PREFIX}`, startTime)
  const expandedTelemetryChartData = _.get(assetOperation, [EXPANDED_TELEMETRY_PREFIX, dataPrefix])
  const soeTag = isLive ? BATTERY_4SEC_TAGS.LIVE.SOE_TAG : BATTERY_4SEC_TAGS.SIMULATED.SOE_TAG
  const battery4sSoeKey = `${asset.assetId}_${soeTag}`
  const aemoSoeDataKey = `${asset.assetId}_${asset.data.tag_prefix + soeTagSuffix}`
  const soeChartData = isBattery
    ? assetOperationSelectors.create4sTelemetrySelector(
      assetOperation,
      collated,
      battery4sSoeKey,
      aemoSoeDataKey,
      startTimestamp,
      startDateTimestamp,
    )
    : { isLoading: true }

  const activeAssetScheuledData = _.get(assetOperation, `activeAssetScheduledData`)
  const commercialScheduledData = _.get(assetOperation, `commercialScheduledData`)

  const scheduledChargeLimitValues = []
  const scheduledDisChargeLimitValues = []
  const scheduledMinSOEValues = []
  const scheduledMaxSOEValues = []

  const commercialChargeLimtValues = []
  const commercialDischargeLimitValues = []
  const commercialMinSOEValues = []
  const commercialMaxSOEValues = []

  if (activeAssetScheuledData.length > 0) {
    activeAssetScheuledData.forEach(interval => {
      scheduledChargeLimitValues.push(interval.data?.charge_limit_kw)
      scheduledDisChargeLimitValues.push(interval.data?.discharge_limit_kw)
      scheduledMinSOEValues.push(interval.data?.soe_min_kwh)
      scheduledMaxSOEValues.push(interval.data?.soe_max_kwh)
    })
  }

  if (commercialScheduledData.length > 0) {
    commercialScheduledData.forEach(interval => {
      commercialChargeLimtValues.push(interval.data?.charge_limit_kw)
      commercialDischargeLimitValues.push(interval.data?.discharge_limit_kw)
      commercialMinSOEValues.push(interval.data?.soe_min_kwh)
      commercialMaxSOEValues.push(interval.data?.soe_max_kwh)
    })
  }

  let availabilityChartData = { isLoading: true }
  let warrantyConstraintChartData = { isLoading: true } // warranty contraint updates scheduled for the trading day
  warrantyConstraintChartData = getChartData(
    collated,
    `${asset.assetId}_${asset.data.tag_prefix}_${WARRANTY_TAG}`,
    startTime,
  )
  const consumedReserveEnergyChartData = getChartData(
    assetOperation,
    `${asset.assetId}_${CONSUMED_RESERVE_ENERGY}`,
    startTime,
  )
  const SOEMaxValues = []
  const SOEMinValues = []
  let capacityChartData = { isLoading: true }
  if (startTime) {
    const availabilityStateKey = `${asset.assetId}_${productId}_${availabilityTag}_${horizon}`
    availabilityChartData = getChartData(forecast, availabilityStateKey, startTimestamp)
    if (asset.dualType) {
      let currentTime = _.get(selectedRange, 'start')
      if (isLive) {
        const capacityTag = BATTERY_4SEC_TAGS.LIVE.AVAILABLE_CAPACITY_TAG
        const battery4sCapacityKey = `${asset.assetId}_${capacityTag}`
        capacityChartData = isBattery
          ? getChartData(assetOperation, battery4sCapacityKey, startTimestamp)
          : { isLoading: true }
        if (isSelectedDateActiveMarketDay(selectedRange.start, marketStartHour)) {
          const powerData = _.get(powerChartData, 'data')
          const forecastStartTime = moment(powerData.forecastStartTime)
          const forecastStartIndex = getIndex(forecastStartTime, selectedRange.start, selectedRange.end, bidInterval)
          if (_.get(capacityChartData, 'data.length') > 0) {
            let lastCapacity = totalCapacity
            for (let i = 0; i < NUM_FIVE_MIN_INTERVALS_PER_DAY; i++) {
              currentTime = moment(currentTime).add(FIVE_MINUTE_DURATION)
              if (i >= forecastStartIndex) {
                const soeLimits = processSOELimits(lastCapacity, currentTime, warrantyConstraintChartData)
                SOEMaxValues.push(soeLimits.soeMax)
                SOEMinValues.push(soeLimits.soeMin)
              } else {
                const availableCapacity = capacityChartData.data[i].value
                const soeLimits = processSOELimits(availableCapacity, currentTime, warrantyConstraintChartData)
                SOEMaxValues.push(soeLimits.soeMax)
                SOEMinValues.push(soeLimits.soeMin)
                if (_.isNumber(availableCapacity)) {
                  lastCapacity = availableCapacity
                }
              }
            }
          }
        } else {
          if (_.get(capacityChartData, 'data.length') > 0) {
            let lastCapacity = totalCapacity
            for (let i = 0; i < NUM_FIVE_MIN_INTERVALS_PER_DAY; i++) {
              currentTime = moment(currentTime).add(FIVE_MINUTE_DURATION)
              if (currentTime >= moment.now()) {
                const soeLimits = processSOELimits(lastCapacity, currentTime, warrantyConstraintChartData)
                SOEMaxValues.push(soeLimits.soeMax)
                SOEMinValues.push(soeLimits.soeMin)
              } else {
                const availableCapacity = capacityChartData.data[i].value
                const soeLimits = processSOELimits(availableCapacity, currentTime, warrantyConstraintChartData)
                SOEMaxValues.push(soeLimits.soeMax)
                SOEMinValues.push(soeLimits.soeMin)
                if (_.isNumber(availableCapacity)) {
                  lastCapacity = availableCapacity
                }
              }
            }
          }
        }
      } else {
        for (let cnt = 0; cnt < NUM_FIVE_MIN_INTERVALS_PER_DAY; cnt++) {
          currentTime = moment(currentTime).add(FIVE_MINUTE_DURATION)
          const soeLimits = processSOELimits(totalCapacity, currentTime, warrantyConstraintChartData)
          SOEMaxValues.push(soeLimits.soeMax)
          SOEMinValues.push(soeLimits.soeMin)
        }
      }
    }
  }

  useEffect(() => {
    if (!_.isNil(startTime) && moment(startTime).isValid()) {
      setDisplayRange({
        start: moment(startTime).unix(),
        end: moment(endTime).unix(),
      })
    }
  }, [startTime, endTime])

  const downloadIntervals = async (asset, selectedDate) => {
    const timezone = _.get(asset, 'market.data.timezone')
    const settlementInterval = _.get(asset, 'market.data.settlement_interval')
    const settlementIntervalByTime = getSettlementIntervalByTime(selectedDate)
    let asOf = lastUpdated && lastUpdated.format(DATETIME_FORMAT_WITH_SECONDS)
    const start = moment.unix(displayRange.start).tz(timezone)
    const end = moment.unix(displayRange.end).tz(timezone)
    // creating csv headers
    const INTERVAL = 'Interval'
    const TRADING_INTERVAL = 'Trading Interval'
    const DISPATCH_INTERVAL = 'Dispatch Interval'
    const TIME_WINDOW = 'Time Window'
    const ACTUAL_OR_FORECAST = 'Actual_or_Forecast'
    const POWER = 'Power'
    const STATE_OF_ENERGY = 'State of Energy'
    const STATE_OF_ENERGY_MIN = 'State of Energy Min'
    const STATE_OF_ENERGY_MAX = 'State of Energy Max'
    const FORECAST_AVAILABILITY = 'Forecast Availability'
    const ACTUAL_AVAILABILITY = 'Actual Availability'
    const DISCHARGE_CAPCITY_LIMIT = 'Discharge Power Capacity Limit'
    const CHARGE_CAPCITY_LIMIT = 'Charge Power Capacity Limit'
    const OVERRIDE_CHARGE = 'Override Charge Power Capacity Limit'
    const OVERRIDE_DISCHARGE = 'Override Discharge Power Capacity Limit'
    const MIN_ACTIVE_POWER = 'Min Active Power Limit'
    const MAX_ACTIVE_POWER = 'Max Active Power Limit'
    const CONSUMED_RESERVE = 'Consumed Reserved'

    const intervalHeaders =
      settlementIntervalByTime === bidInterval ? [INTERVAL] : [TRADING_INTERVAL, DISPATCH_INTERVAL]

    const dataColumnHeaders = isBattery
      ? [
        ...intervalHeaders,
        TIME_WINDOW,
        ACTUAL_OR_FORECAST,
        POWER,
        STATE_OF_ENERGY,
        STATE_OF_ENERGY_MIN,
        STATE_OF_ENERGY_MAX,
        DISCHARGE_CAPCITY_LIMIT,
        CHARGE_CAPCITY_LIMIT,
        OVERRIDE_DISCHARGE,
        OVERRIDE_CHARGE,
        MIN_ACTIVE_POWER,
        MAX_ACTIVE_POWER,
        CONSUMED_RESERVE,
      ].join(',')
      : [...intervalHeaders, TIME_WINDOW, ACTUAL_OR_FORECAST, POWER, FORECAST_AVAILABILITY, ACTUAL_AVAILABILITY].join(
        ',',
      )

    // get index of the current viewport
    const startIndex = getIndex(start, selectedDate, moment(selectedDate).add(1, 'd'), bidInterval)
    const endIndex = getIndex(
      moment(end).subtract(bidInterval, 'minutes'),
      selectedDate,
      moment(selectedDate).add(1, 'd'),
      bidInterval,
    )

    // console.log('info', endIndex)

    const powerData = _.get(powerChartData, 'data')
    const forecastStartTime = moment(powerData.forecastStartTime)
    const soeData = _.get(soeChartData, 'data')
    const availabilityData = _.get(availabilityChartData, 'data')
    const [availabilityForecaseValues, availabilityActualValues] = _.get(availabilityData, 'values', [])
    const data = {}
    let dataCsvString = ''
    if (isBattery) {
      if (selectedDate.isAfter(moment())) {
        const forecastEndIndex = getIndex(
          moment(forecastStartTime).add(1, 'd'),
          selectedDate,
          moment(selectedDate).add(1, 'd'),
          bidInterval,
        )

        _.range(startIndex, endIndex + 1).forEach(index => {
          const startTime = moment(selectedDate).add(index * bidInterval, 'minutes')
          const endTime = moment(startTime).add(bidInterval, 'minutes')
          const timeWindow = `${startTime.format(TIME_FORMAT)} to ${endTime.format(TIME_FORMAT)}`
          const rowInterval = index + 1
          const powerMw = convertkWToMW(_.get(powerData, ['values', 0, index]))
          const power = _.isNaN(powerMw) ? '' : numeral(powerMw).format('0.00')
          const soeMwh = asset.dualType ? convertkWToMW(_.get(soeData, ['values', 0, index])) : null
          const soe = _.isNumber(soeMwh) && !_.isNaN(soeMwh) ? `${numeral(soeMwh).format('0.00')}` : ''
          const availableCapacity = totalCapacity
          const soeLimits = processSOELimits(availableCapacity, moment(startTime), warrantyConstraintChartData)
          const SOEMaxValueMwh = convertkWToMW(soeLimits.soeMax)
          let SOEMaxValue =
            _.isNumber(SOEMaxValueMwh) && !_.isNaN(SOEMaxValueMwh) ? numeral(SOEMaxValueMwh).format('0.00') : ''
          const SOEMinValueMwh = convertkWToMW(soeLimits.soeMin)
          let SOEMinValue =
            _.isNumber(SOEMinValueMwh) && !_.isNaN(SOEMinValueMwh) ? numeral(SOEMinValueMwh).format('0.00') : ''
          if (index > forecastEndIndex) {
            SOEMaxValue = ''
            SOEMinValue = ''
          }
          _.set(data, [startTime.format(), INTERVAL], rowInterval)
          _.set(data, [startTime.format(), TIME_WINDOW], timeWindow)
          _.set(data, [startTime.format(), ACTUAL_OR_FORECAST], 'F')
          _.set(data, [startTime.format(), POWER], power)
          _.set(data, [startTime.format(), STATE_OF_ENERGY], soe)
          _.set(data, [startTime.format(), STATE_OF_ENERGY_MAX], SOEMaxValue)
          _.set(data, [startTime.format(), STATE_OF_ENERGY_MIN], SOEMinValue)
        })
        for (const [, row] of Object.entries(data)) {
          dataCsvString += `${row[INTERVAL]},${row[TIME_WINDOW]},${row[ACTUAL_OR_FORECAST]},${row[POWER]},${row[STATE_OF_ENERGY]},${row[STATE_OF_ENERGY_MAX]},${row[STATE_OF_ENERGY_MIN]}\n`
        }
      } else {
        const powerTag = isLive ? BATTERY_4SEC_TAGS.LIVE.POWER_TAG : BATTERY_4SEC_TAGS.SIMULATED.POWER_TAG
        const powerRequest = dispatchGetAssetIntervals(assetId, powerTag, start, end)
        const soeTag = isLive ? BATTERY_4SEC_TAGS.LIVE.SOE_TAG : BATTERY_4SEC_TAGS.SIMULATED.SOE_TAG
        const soeRequest = dispatchGetAssetIntervals(assetId, soeTag, start, end)
        const availabilityTag = isLive
          ? BATTERY_4SEC_TAGS.LIVE.AVAILABLE_CAPACITY_TAG
          : BATTERY_4SEC_TAGS.SIMULATED.AVAILABLE_CAPACITY_TAG
        const availabilityRequest = dispatchGetAssetIntervals(assetId, availabilityTag, start, end)
        const dischargeCapacityTag = isLive
          ? BATTERY_4SEC_TAGS.LIVE.AVAIL_DISCHARGE_TAG
          : BATTERY_4SEC_TAGS.SIMULATED.AVAIL_DISCHARGE_TAG
        const dischargeCapacityRequest = dispatchGetAssetIntervals(assetId, dischargeCapacityTag, start, end)
        const chargeCapacityTag = isLive
          ? BATTERY_4SEC_TAGS.LIVE.AVAIL_CHARGE_TAG
          : BATTERY_4SEC_TAGS.SIMULATED.AVAIL_CHARGE_TAG
        const chargeCapacityRequest = dispatchGetAssetIntervals(assetId, chargeCapacityTag, start, end)
        const overrideDischargeCapacityTag = isLive
          ? BATTERY_4SEC_TAGS.LIVE.OVERRIDE_DISCHARGE_TAG
          : BATTERY_4SEC_TAGS.SIMULATED.OVERRIDE_DISCHARGE_TAG
        const overrideDischargeCapacityRequest = dispatchGetAssetIntervals(
          assetId,
          overrideDischargeCapacityTag,
          start,
          end,
        )
        const overrideChargeCapacityTag = isLive
          ? BATTERY_4SEC_TAGS.LIVE.OVERRIDE_CHARGE_TAG
          : BATTERY_4SEC_TAGS.SIMULATED.OVERRIDE_CHARGE_TAG
        const overrideChargeCapacityRequest = dispatchGetAssetIntervals(assetId, overrideChargeCapacityTag, start, end)
        const minActivePowerLimitTag = isLive
          ? BATTERY_4SEC_TAGS.LIVE.MIN_ACTIVE_POWER_LIMIT_TAG
          : BATTERY_4SEC_TAGS.SIMULATED.MIN_ACTIVE_POWER_LIMIT_TAG
        const minActivePowerLimitRequest = dispatchGetAssetIntervals(assetId, minActivePowerLimitTag, start, end)
        const maxActivePowerLimitTag = isLive
          ? BATTERY_4SEC_TAGS.LIVE.MAX_ACTIVE_POWER_LIMIT_TAG
          : BATTERY_4SEC_TAGS.SIMULATED.MAX_ACTIVE_POWER_LIMIT_TAG
        const maxActivePowerLimitRequest = dispatchGetAssetIntervals(assetId, maxActivePowerLimitTag, start, end)
        const consumedReservedEnergyTag = isLive
          ? BATTERY_4SEC_TAGS.LIVE.CONSUMED_RESERVE_ENERGY_TAG
          : BATTERY_4SEC_TAGS.SIMULATED.CONSUMED_RESERVE_ENERGY_TAG
        const consumedReservedEnergyRequest = dispatchGetAssetIntervals(assetId, consumedReservedEnergyTag, start, end)
        setDownloadInprogress(true)

        const [
          powerResponse,
          soeResponse,
          availabilityResponse,
          dischargeCapacityResponse,
          chargeCapacityResponse,
          overrideDischargeCapacityResponse,
          overrideChargeCapacityResponse,
          minActivePowerLimitResponse,
          maxActivePowerLimitResponse,
          consumedReservedEnergyResponse,
        ] = await Promise.all([
          powerRequest,
          soeRequest,
          availabilityRequest,
          dischargeCapacityRequest,
          chargeCapacityRequest,
          overrideDischargeCapacityRequest,
          overrideChargeCapacityRequest,
          minActivePowerLimitRequest,
          maxActivePowerLimitRequest,
          consumedReservedEnergyRequest,
        ])

        setDownloadInprogress(false)
        asOf = moment().format(DATETIME_FORMAT_WITH_SECONDS)

        _.get(powerResponse, 'payload', []).forEach(i => {
          if (moment(i.time).isValid() && moment(i.time).isSameOrAfter(start) && moment(i.time).isSameOrBefore(end)) {
            const interval = getIndex(moment(i.time), selectedDate, moment(selectedDate).add(1, 'd'), bidInterval)
            const startTime = moment(i.time)
            const endTime = moment(startTime).add(4, 'seconds')
            const timeWindow = `${startTime.format(TIME_FORMAT_WITH_SEC)} to ${endTime.format(TIME_FORMAT_WITH_SEC)}`
            const powerMw = convertkWToMW(i.value)
            const power = _.isNaN(powerMw) ? '' : numeral(powerMw).format('0.00')
            _.set(data, [moment(i.time).format(), INTERVAL], interval + 1)
            _.set(data, [moment(i.time).format(), TIME_WINDOW], timeWindow)
            _.set(data, [moment(i.time).format(), ACTUAL_OR_FORECAST], 'A')
            _.set(data, [moment(i.time).format(), POWER], power)
          }
        })
        _.get(soeResponse, 'payload', []).forEach(i => {
          if (moment(i.time).isValid() && moment(i.time).isSameOrAfter(start) && moment(i.time).isSameOrBefore(end)) {
            const soeMwh = asset.dualType ? convertkWToMW(i.value) : null
            const soe = _.isNumber(soeMwh) && !_.isNaN(soeMwh) ? `${numeral(soeMwh).format('0.00')}` : ''
            const interval = getIndex(moment(i.time), selectedDate, moment(selectedDate).add(1, 'd'), bidInterval)
            const startTime = moment(i.time)
            const endTime = moment(startTime).add(4, 'seconds')
            const timeWindow = `${startTime.format(TIME_FORMAT_WITH_SEC)} to ${endTime.format(TIME_FORMAT_WITH_SEC)}`
            _.set(data, [moment(i.time).format(), INTERVAL], interval + 1)
            _.set(data, [moment(i.time).format(), TIME_WINDOW], timeWindow)
            _.set(data, [moment(i.time).format(), ACTUAL_OR_FORECAST], 'A')
            _.set(data, [moment(i.time).format(), STATE_OF_ENERGY], soe)
          }
        })
        let availableCapacity = null
        let lastCapacity = totalCapacity
        _.get(availabilityResponse, 'payload', []).forEach(i => {
          const time = moment(i.time)
          availableCapacity = _.get(i, 'value')
          if (
            _.isNumber(availableCapacity) &&
            moment(i.time).isValid() &&
            moment(i.time).isSameOrAfter(start) &&
            moment(i.time).isSameOrBefore(end)
          ) {
            const soeLimits = processSOELimits(availableCapacity, time, warrantyConstraintChartData)
            const SOEMaxValueMwh = convertkWToMW(soeLimits.soeMax)
            const SOEMaxValue =
              _.isNumber(SOEMaxValueMwh) && !_.isNaN(SOEMaxValueMwh) ? `${numeral(SOEMaxValueMwh).format('0.00')}` : ''
            const SOEMinValueMwh = convertkWToMW(soeLimits.soeMin)
            const SOEMinValue =
              _.isNumber(SOEMinValueMwh) && !_.isNaN(SOEMinValueMwh) ? `${numeral(SOEMinValueMwh).format('0.00')}` : ''
            _.set(data, [moment(i.time).format(), STATE_OF_ENERGY_MAX], SOEMaxValue)
            _.set(data, [moment(i.time).format(), STATE_OF_ENERGY_MIN], SOEMinValue)
            lastCapacity = availableCapacity
          }
        })

        _.get(dischargeCapacityResponse, 'payload', []).forEach(i => {
          if (moment(i.time).isValid() && moment(i.time).isSameOrAfter(start) && moment(i.time).isSameOrBefore(end)) {
            const interval = getIndex(moment(i.time), selectedDate, moment(selectedDate).add(1, 'd'), bidInterval)
            const startTime = moment(i.time)
            const endTime = moment(startTime).add(4, 'seconds')
            const timeWindow = `${startTime.format(TIME_FORMAT_WITH_SEC)} to ${endTime.format(TIME_FORMAT_WITH_SEC)}`
            const dischargeCapacityMw = convertkWToMW(i.value)
            const dischargeCapacity = _.isNaN(dischargeCapacityMw) ? '' : numeral(dischargeCapacityMw).format('0.00')
            _.set(data, [moment(i.time).format(), INTERVAL], interval + 1)
            _.set(data, [moment(i.time).format(), TIME_WINDOW], timeWindow)
            _.set(data, [moment(i.time).format(), ACTUAL_OR_FORECAST], 'A')
            _.set(data, [moment(i.time).format(), DISCHARGE_CAPCITY_LIMIT], dischargeCapacity)
          }
        })

        _.get(chargeCapacityResponse, 'payload', []).forEach(i => {
          if (moment(i.time).isValid() && moment(i.time).isSameOrAfter(start) && moment(i.time).isSameOrBefore(end)) {
            const interval = getIndex(moment(i.time), selectedDate, moment(selectedDate).add(1, 'd'), bidInterval)
            const startTime = moment(i.time)
            const endTime = moment(startTime).add(4, 'seconds')
            const timeWindow = `${startTime.format(TIME_FORMAT_WITH_SEC)} to ${endTime.format(TIME_FORMAT_WITH_SEC)}`
            const chargeCapacityMw = convertkWToMW(i.value)
            const chargeCapacity = _.isNaN(chargeCapacityMw) ? '' : numeral(-chargeCapacityMw).format('0.00')
            _.set(data, [moment(i.time).format(), INTERVAL], interval + 1)
            _.set(data, [moment(i.time).format(), TIME_WINDOW], timeWindow)
            _.set(data, [moment(i.time).format(), ACTUAL_OR_FORECAST], 'A')
            _.set(data, [moment(i.time).format(), CHARGE_CAPCITY_LIMIT], chargeCapacity)
          }
        })

        _.get(overrideDischargeCapacityResponse, 'payload', []).forEach(i => {
          if (moment(i.time).isValid() && moment(i.time).isSameOrAfter(start) && moment(i.time).isSameOrBefore(end)) {
            const interval = getIndex(moment(i.time), selectedDate, moment(selectedDate).add(1, 'd'), bidInterval)
            const startTime = moment(i.time)
            const endTime = moment(startTime).add(4, 'seconds')
            const timeWindow = `${startTime.format(TIME_FORMAT_WITH_SEC)} to ${endTime.format(TIME_FORMAT_WITH_SEC)}`
            const overrideDischargeMw = convertkWToMW(i.value)
            const overrideDischarge = _.isNaN(overrideDischargeMw) ? '' : numeral(overrideDischargeMw).format('0.00')
            _.set(data, [moment(i.time).format(), INTERVAL], interval + 1)
            _.set(data, [moment(i.time).format(), TIME_WINDOW], timeWindow)
            _.set(data, [moment(i.time).format(), ACTUAL_OR_FORECAST], 'A')
            _.set(data, [moment(i.time).format(), OVERRIDE_DISCHARGE], overrideDischarge)
          }
        })

        _.get(overrideChargeCapacityResponse, 'payload', []).forEach(i => {
          if (moment(i.time).isValid() && moment(i.time).isSameOrAfter(start) && moment(i.time).isSameOrBefore(end)) {
            const interval = getIndex(moment(i.time), selectedDate, moment(selectedDate).add(1, 'd'), bidInterval)
            const startTime = moment(i.time)
            const endTime = moment(startTime).add(4, 'seconds')
            const timeWindow = `${startTime.format(TIME_FORMAT_WITH_SEC)} to ${endTime.format(TIME_FORMAT_WITH_SEC)}`
            const overrideChargeMw = convertkWToMW(i.value)
            const overrideCharge = _.isNaN(overrideChargeMw) ? '' : numeral(-overrideChargeMw).format('0.00')
            _.set(data, [moment(i.time).format(), INTERVAL], interval + 1)
            _.set(data, [moment(i.time).format(), TIME_WINDOW], timeWindow)
            _.set(data, [moment(i.time).format(), ACTUAL_OR_FORECAST], 'A')
            _.set(data, [moment(i.time).format(), OVERRIDE_CHARGE], overrideCharge)
          }
        })

        _.get(minActivePowerLimitResponse, 'payload', []).forEach(i => {
          if (moment(i.time).isValid() && moment(i.time).isSameOrAfter(start) && moment(i.time).isSameOrBefore(end)) {
            const interval = getIndex(moment(i.time), selectedDate, moment(selectedDate).add(1, 'd'), bidInterval)
            const startTime = moment(i.time)
            const endTime = moment(startTime).add(4, 'seconds')
            const timeWindow = `${startTime.format(TIME_FORMAT_WITH_SEC)} to ${endTime.format(TIME_FORMAT_WITH_SEC)}`
            const minActivePowerMw = convertkWToMW(i.value)
            const minActivePower = _.isNaN(minActivePowerMw) ? '' : numeral(minActivePowerMw).format('0.00')
            _.set(data, [moment(i.time).format(), INTERVAL], interval + 1)
            _.set(data, [moment(i.time).format(), TIME_WINDOW], timeWindow)
            _.set(data, [moment(i.time).format(), ACTUAL_OR_FORECAST], 'A')
            _.set(data, [moment(i.time).format(), MIN_ACTIVE_POWER], minActivePower)
          }
        })

        _.get(maxActivePowerLimitResponse, 'payload', []).forEach(i => {
          if (moment(i.time).isValid() && moment(i.time).isSameOrAfter(start) && moment(i.time).isSameOrBefore(end)) {
            const interval = getIndex(moment(i.time), selectedDate, moment(selectedDate).add(1, 'd'), bidInterval)
            const startTime = moment(i.time)
            const endTime = moment(startTime).add(4, 'seconds')
            const timeWindow = `${startTime.format(TIME_FORMAT_WITH_SEC)} to ${endTime.format(TIME_FORMAT_WITH_SEC)}`
            const maxActivePowerMw = convertkWToMW(i.value)
            const maxActivePower = _.isNaN(maxActivePowerMw) ? '' : numeral(maxActivePowerMw).format('0.00')
            _.set(data, [moment(i.time).format(), INTERVAL], interval + 1)
            _.set(data, [moment(i.time).format(), TIME_WINDOW], timeWindow)
            _.set(data, [moment(i.time).format(), ACTUAL_OR_FORECAST], 'A')
            _.set(data, [moment(i.time).format(), MAX_ACTIVE_POWER], maxActivePower)
          }
        })

        _.get(consumedReservedEnergyResponse, 'payload', []).forEach(i => {
          if (moment(i.time).isValid() && moment(i.time).isSameOrAfter(start) && moment(i.time).isSameOrBefore(end)) {
            const interval = getIndex(moment(i.time), selectedDate, moment(selectedDate).add(1, 'd'), bidInterval)
            const startTime = moment(i.time)
            const endTime = moment(startTime).add(4, 'seconds')
            const timeWindow = `${startTime.format(TIME_FORMAT_WITH_SEC)} to ${endTime.format(TIME_FORMAT_WITH_SEC)}`
            const consumedReserveMw = convertkWToMW(i.value)
            const consumedReserve = _.isNaN(consumedReserveMw) ? '' : numeral(consumedReserveMw).format('0.00')
            _.set(data, [moment(i.time).format(), INTERVAL], interval + 1)
            _.set(data, [moment(i.time).format(), TIME_WINDOW], timeWindow)
            _.set(data, [moment(i.time).format(), ACTUAL_OR_FORECAST], 'A')
            _.set(data, [moment(i.time).format(), CONSUMED_RESERVE], consumedReserve)
          }
        })

        if (isSelectedDateActiveMarketDay(selectedDate, marketStartHour)) {
          const forecastStartIndex = getIndex(
            forecastStartTime,
            selectedDate,
            moment(selectedDate).add(1, 'd'),
            bidInterval,
          )
          const startIndexOfDisplayRange = Math.max(0, startIndex, forecastStartIndex)
          if (startIndexOfDisplayRange <= endIndex) {
            _.range(startIndexOfDisplayRange + 1, endIndex + 1).forEach(index => {
              const startTime = moment(selectedDate).add(index * bidInterval, 'minutes')
              const endTime = moment(startTime).add(bidInterval, 'minutes')
              const timeWindow = `${startTime.format(TIME_FORMAT)} to ${endTime.format(TIME_FORMAT)}`
              const rowInterval = index + 1
              const powerMw = convertkWToMW(_.get(powerData, ['values', 0, index]))
              const power = _.isNaN(powerMw) ? '' : numeral(powerMw).format('0.00')
              const soeMwh = asset.dualType ? convertkWToMW(_.get(soeData, ['values', 0, index])) : null
              const soe = _.isNumber(soeMwh) && !_.isNaN(soeMwh) ? `${numeral(soeMwh).format('0.00')}` : ''
              const availableCapacity = isLive ? lastCapacity : totalCapacity
              const soeLimits = processSOELimits(availableCapacity, moment(startTime), warrantyConstraintChartData)
              const SOEMaxValueMwh = convertkWToMW(soeLimits.soeMax)
              const SOEMaxValue =
                _.isNumber(SOEMaxValueMwh) && !_.isNaN(SOEMaxValueMwh)
                  ? `${numeral(SOEMaxValueMwh).format('0.00')}`
                  : ''
              const SOEMinValueMwh = convertkWToMW(soeLimits.soeMin)
              const SOEMinValue =
                _.isNumber(SOEMinValueMwh) && !_.isNaN(SOEMinValueMwh)
                  ? `${numeral(SOEMinValueMwh).format('0.00')}`
                  : ''
              _.set(data, [startTime.format(), INTERVAL], rowInterval)
              _.set(data, [startTime.format(), TIME_WINDOW], timeWindow)
              _.set(data, [startTime.format(), ACTUAL_OR_FORECAST], 'F')
              _.set(data, [startTime.format(), POWER], power)
              _.set(data, [startTime.format(), STATE_OF_ENERGY], soe)
              _.set(data, [startTime.format(), STATE_OF_ENERGY_MAX], SOEMaxValue)
              _.set(data, [startTime.format(), STATE_OF_ENERGY_MIN], SOEMinValue)
            })
          }
          if (!isLive) {
            const simStartIndex = Math.max(0, startIndex)
            const simEndIndex = Math.min(forecastStartIndex, endIndex + 1)
            if (simStartIndex <= simEndIndex) {
              _.range(simStartIndex, simEndIndex).forEach(index => {
                const startTime = moment(selectedDate).add(index * bidInterval, 'minutes')
                const soeLimits = processSOELimits(totalCapacity, moment(startTime), warrantyConstraintChartData)
                const SOEMaxValueMwh = convertkWToMW(soeLimits.soeMax)
                const SOEMaxValue =
                  _.isNumber(SOEMaxValueMwh) && !_.isNaN(SOEMaxValueMwh)
                    ? `${numeral(SOEMaxValueMwh).format('0.00')}`
                    : ''
                const SOEMinValueMwh = convertkWToMW(soeLimits.soeMin)
                const SOEMinValue =
                  _.isNumber(SOEMinValueMwh) && !_.isNaN(SOEMinValueMwh)
                    ? `${numeral(SOEMinValueMwh).format('0.00')}`
                    : ''
                if (_.has(data, [startTime.format(), TIME_WINDOW])) {
                  _.set(data, [startTime.format(), STATE_OF_ENERGY_MAX], SOEMaxValue)
                  _.set(data, [startTime.format(), STATE_OF_ENERGY_MIN], SOEMinValue)
                }
              })
            }
          }
        }
        for (const [, row] of Object.entries(data)) {
          if (row[STATE_OF_ENERGY] === undefined) {
            row[STATE_OF_ENERGY] = ''
          }
          if (row[STATE_OF_ENERGY_MAX] === undefined) {
            row[STATE_OF_ENERGY_MAX] = ''
          }
          if (row[STATE_OF_ENERGY_MIN] === undefined) {
            row[STATE_OF_ENERGY_MIN] = ''
          }
          if (row[DISCHARGE_CAPCITY_LIMIT] === undefined) {
            row[DISCHARGE_CAPCITY_LIMIT] = ''
          }
          if (row[CHARGE_CAPCITY_LIMIT] === undefined) {
            row[CHARGE_CAPCITY_LIMIT] = ''
          }
          if (row[OVERRIDE_DISCHARGE] === undefined) {
            row[OVERRIDE_DISCHARGE] = ''
          }
          if (row[OVERRIDE_CHARGE] === undefined) {
            row[OVERRIDE_CHARGE] = ''
          }
          if (row[MIN_ACTIVE_POWER] === undefined) {
            row[MIN_ACTIVE_POWER] = ''
          }
          if (row[MAX_ACTIVE_POWER] === undefined) {
            row[MAX_ACTIVE_POWER] = ''
          }
          if (row[CONSUMED_RESERVE] === undefined) {
            row[CONSUMED_RESERVE] = ''
          }
          dataCsvString += `${row[INTERVAL]},${row[TIME_WINDOW]},${row[ACTUAL_OR_FORECAST]},${row[POWER]},${row[STATE_OF_ENERGY]},${row[STATE_OF_ENERGY_MIN]},${row[STATE_OF_ENERGY_MAX]},${row[DISCHARGE_CAPCITY_LIMIT]},${row[CHARGE_CAPCITY_LIMIT]},${row[OVERRIDE_DISCHARGE]},${row[OVERRIDE_CHARGE]},${row[MIN_ACTIVE_POWER]},${row[MAX_ACTIVE_POWER]},${row[CONSUMED_RESERVE]}\n`
        }
      }
    } else {
      _.range(startIndex, endIndex + 1).forEach(index => {
        const startTime = moment(selectedDate).add(index * bidInterval, 'minutes')
        const endTime = moment(startTime).add(bidInterval, 'minutes')
        const timeWindow = `${startTime.format(TIME_FORMAT)} to ${endTime.format(TIME_FORMAT)}`
        const isActual = startTime.isBefore(forecastStartTime) ? 'A' : 'F'
        const tradingInterval = Math.floor(index / (settlementInterval / bidInterval) + 1)
        const dispatchInterval = (index % (settlementInterval / bidInterval)) + 1
        const rowInterval = index + 1
        const powerMw = convertkWToMW(_.get(powerData, ['values', 0, index]))
        const power = _.isNaN(powerMw) ? '' : numeral(powerMw).format('0.00')
        const availabilityForecastMw = convertkWToMW(_.get(availabilityForecaseValues, index))
        const availabilityForecast = _.isNaN(availabilityForecastMw)
          ? ''
          : numeral(availabilityForecastMw).format('0.00')
        const availabilityActualMw = convertkWToMW(_.get(availabilityActualValues, index))
        const availabilityActual = _.isNaN(availabilityActualMw) ? '' : numeral(availabilityActualMw).format('0.00')
        const intervalColumns =
          settlementIntervalByTime === bidInterval ? `${rowInterval}` : `${tradingInterval},${dispatchInterval}`
        _.set(data, [startTime.format(), INTERVAL], intervalColumns)
        _.set(data, [startTime.format(), TIME_WINDOW], timeWindow)
        _.set(data, [startTime.format(), ACTUAL_OR_FORECAST], isActual)
        _.set(data, [startTime.format(), POWER], power)
        _.set(data, [startTime.format(), FORECAST_AVAILABILITY], availabilityForecast)
        _.set(data, [startTime.format(), ACTUAL_AVAILABILITY], availabilityActual)
      })
      for (const [, row] of Object.entries(data)) {
        dataCsvString += `${row[INTERVAL]},${row[TIME_WINDOW]},${row[ACTUAL_OR_FORECAST]},${row[POWER]},${row[FORECAST_AVAILABILITY]},${row[ACTUAL_AVAILABILITY]}\n`
      }
    }
    // creating csv top
    let output = `Asset: ${asset.name}\nSize/Duration: ${getAssetCapacityText(asset).both
      }\nTrading Date: ${selectedDate.format(DATE_FORMAT)}\n`
    output += `Time Range: ${start.format(DATETIME_FORMAT)} to ${end.format(
      DATETIME_FORMAT,
    )}\nData Current as Of: ${asOf}\n`
    const region = `Region: ${asset.region.name}`
    const dataType = isBattery
      ? `Data Type: Power (MW) and state of energy (MWh)`
      : `Data Type: Power (MW) and availability (MW)`
    output += `${region}\n${dataType}\n`
    output += `${dataColumnHeaders}\n`
    output += dataCsvString
    const fileName = `Operations_${assetId}_${selectedDate.format(DATE_FORMAT_NO_SEPARATOR)}_${moment(asOf)
      .tz(timezone)
      .format(DATE_FORMAT_NO_SEPARATOR_WITH_SECONDS)}.csv`
    download(output, fileName, 'text/csv')
  }

  const handleDownloadClick = () => {
    downloadIntervals(asset, selectedRange.start)
  }

  useEffect(() => {
    if (!_.isEmpty(asset) && !_.isNil(selectedRange.start)) {
      setLoading(true)
      const result = fetchData(asset, selectedRange.start)
      Promise.resolve(result).then(() => {
        setLastUpdated(moment())
        setLoading(false)
      })
    }
  }, [asset, assetId, fetchData, selectedRange.start])

  const title = 'Operations'
  const start = moment.unix(displayRange.start).tz(timezone)
  const end = moment.unix(displayRange.end).tz(timezone)
  const subtitle = getCardSubtitleByDate(start, end, lastUpdated, timezone, marketStartHour)
  const inProgress = loading || _.isEmpty(asset)

  const saveLatestVals = (val, index) => {
    // only save latest val if current day is today
    // today is always the first selected date so this will work unless we implement
    // what ercot has where the date is in the url. then it will fail.
    const now = moment()
    const isWithinToday = now.isSameOrAfter(startTime) && now.isBefore(endTime)
    if (isWithinToday) {
      dispatchSaveExtendedTelemetryLatestVals({ val, index })
    }
  }

  return (
    <Box className={clsx(classes.root, classNameProp)}>
      <Card
        title={title}
        subheader={subtitle}
        action={
          <Box className={classes.action}>
            <IconButton title="Download" className={classes.actionBtn} onClick={handleDownloadClick}>
              <DownloadIcon />
            </IconButton>
          </Box>
        }
        className={classes.card}
        inProgress={downloadInProgress}
      >
        <AssetOperationsGraph
          asset={asset}
          inProgress={inProgress}
          displayRange={displayRange}
          selectedRange={selectedRange}
          setDisplayRange={setDisplayRange}
          availabilityChartData={availabilityChartData}
          powerChartData={powerChartData}
          soeChartData={soeChartData}
          SOEMaxValues={SOEMaxValues}
          SOEMinValues={SOEMinValues}
          expandedTelemetryChartData={expandedTelemetryChartData}
          saveLatestVals={saveLatestVals}
          expandedTelemetryLatestVals={expandedTelemetryLatestVals}
          consumedReserveEnergyChartData={consumedReserveEnergyChartData}
          scheduledDisChargeLimitValues={scheduledDisChargeLimitValues}
          scheduledChargeLimitValues={scheduledChargeLimitValues}
          scheduledMinSOEValues={scheduledMinSOEValues}
          scheduledMaxSOEValues={scheduledMaxSOEValues}
          commercialDischargeLimitValues={commercialDischargeLimitValues}
          commercialChargeLimtValues={commercialChargeLimtValues}
          commercialMinSOEValues={commercialMinSOEValues}
          commercialMaxSOEValues={commercialMaxSOEValues}
        />
      </Card>
    </Box>
  )
}
AssetOperationsChartCard.displayName = DISPLAY_NAME
AssetOperationsChartCard.propTypes = {
  className: PropTypes.string,
}

const mapStateToProps = state => {
  const { assetOperation, collated, forecast } = state
  const getSettlementIntervalByTime = globalAppSelectors.getSettlementIntervalFn(state)

  return {
    getSettlementIntervalByTime,
    collated,
    forecast,
    assetOperation,
    expandedTelemetryLatestVals: assetOperation.expandedTelemetryLatestVals,
  }
}

const mapDispatchToProps = dispatch => ({
  fetchData: (asset, dateTime) => getData(dispatch, asset, dateTime),
  dispatchGetAssetIntervals: (assetId, tag, startTime, endTime) =>
    dispatch(getAssetIntervals(assetId, tag, startTime, endTime)),
  dispatchSaveExtendedTelemetryLatestVals: (val, index) => dispatch(saveExtendedTelemetryLatestVals(val, index)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AssetOperationsChartCard)

export const getForecastStartTime = (asset, selectedDate) => {
  const market = _.get(asset, 'market')
  const now = moment()
  const bidIntervalMinutesAfterNow = now.add(asset.market.data.bid_interval, 'minutes')
  const nextActiveTradingDate = getTradingStart(market, now).add(1, 'days')
  const isSelectedDateAfterCurrentTradingDay = moment(selectedDate).isSameOrAfter(nextActiveTradingDate)
  return isSelectedDateAfterCurrentTradingDay ? selectedDate : bidIntervalMinutesAfterNow
}

const getData = (dispatch, asset, selectedDate) => {
  const assetId = _.get(asset, 'assetId')
  const tag = asset.data.tag_prefix + powerTag
  const productId = findProduct(asset, 'ENERGY', 'GEN').productId
  const horizon = 0
  const isBattery = _.get(asset, 'data.tag_prefix') === 'battery'
  const isLive = _.get(asset, 'data.is_live', false)
  const endTime = moment(selectedDate).add(1, 'd')
  const durationSeconds = 300 // 300 sec = 5 minutes

  const intervalTimeConfig = new IntervalTimeConfig(
    selectedDate,
    endTime,
    getForecastStartTime(asset, selectedDate),
    null,
    horizon,
    asset.market.data.bid_interval,
  )

  const results = []
  if (isBattery) {
    const tag = isLive ? BATTERY_4SEC_TAGS.LIVE.POWER_TAG : BATTERY_4SEC_TAGS.SIMULATED.POWER_TAG
    const intervalConfig = new IntervalConfig(`${assetId}_${tag}`, tag, intervalTimeConfig)
    results.push(
      dispatch(
        getAggregatedIntervals(
          assetId,
          tag,
          selectedDate,
          endTime,
          durationSeconds,
          'average',
          'none',
          intervalConfig,
          intervalTimeConfig,
        ),
      ),
    )

    results.push(
      dispatch(getRangeOfActiveAssetScheduledData(assetId, selectedDate, endTime, intervalConfig, intervalTimeConfig))
    )

    results.push(
      dispatch(getCommercialScheduledData(assetId, selectedDate, endTime, intervalConfig, intervalTimeConfig))
    )

    if (isLive) {
      const capacityTag4s = BATTERY_4SEC_TAGS.LIVE.AVAILABLE_CAPACITY_TAG
      const intervalConfigCapacity = new IntervalConfig(
        `${assetId}_${capacityTag4s}`,
        capacityTag4s,
        intervalTimeConfig,
      )
      results.push(
        dispatch(
          getAggregatedIntervals(
            assetId,
            capacityTag4s,
            selectedDate,
            endTime,
            durationSeconds,
            'average',
            'none',
            intervalConfigCapacity,
            intervalTimeConfig,
          ),
        ),
      )
    }
  }
  const expandedTelemetryConfig = new IntervalConfig(`${assetId}_${EXPANDED_TELEMETRY_PREFIX}`, intervalTimeConfig)
  results.push(
    dispatch(
      getAggregatedIntervalsMultiple(
        assetId,
        EXPANDED_TELEMETRY_TAGS,
        selectedDate,
        endTime,
        durationSeconds,
        'average',
        'none',
        expandedTelemetryConfig,
        intervalTimeConfig,
      ),
    ),
  )

  results.push(
    dispatch(getCollatedAssetIntervals(new IntervalConfig(`${assetId}_${tag}`, tag, assetId), intervalTimeConfig)),
  )

  const capacityTag = 'battery_available_capacity_kwh'
  results.push(
    dispatch(
      getCollatedAssetIntervals(
        new IntervalConfig(`${assetId}_${capacityTag}`, capacityTag, assetId),
        intervalTimeConfig,
      ),
    ),
  )

  results.push(
    dispatch(
      getAssetProductInterval(
        new IntervalConfig(
          `${assetId}_${productId}_${availabilityTag}_${horizon}`,
          availabilityTag,
          assetId,
          productId,
          horizon,
        ),
        intervalTimeConfig,
      ),
    ),
  )
  if (asset.dualType) {
    const tag = isLive ? BATTERY_4SEC_TAGS.LIVE.SOE_TAG : BATTERY_4SEC_TAGS.SIMULATED.SOE_TAG
    const intervalConfigSoe = new IntervalConfig(`${assetId}_${tag}`, tag, intervalTimeConfig)
    results.push(
      dispatch(
        getAggregatedIntervals(
          assetId,
          tag,
          selectedDate,
          endTime,
          durationSeconds,
          'average',
          'none',
          intervalConfigSoe,
          intervalTimeConfig,
        ),
      ),
    )
    const intervalConfigReservedEnergy = new IntervalConfig(
      `${assetId}_${CONSUMED_RESERVE_ENERGY}`,
      CONSUMED_RESERVE_ENERGY,
      intervalTimeConfig,
    )
    results.push(
      dispatch(
        getAggregatedIntervals(
          assetId,
          CONSUMED_RESERVE_ENERGY,
          selectedDate,
          endTime,
          durationSeconds,
          'average',
          'none',
          intervalConfigReservedEnergy,
          intervalTimeConfig,
        ),
      ),
    )
    const consumedReserveTag = CONSUMED_RESERVE_ENERGY
    const intervalConfigConsumedReserve = new IntervalConfig(
      `${assetId}_${consumedReserveTag}`,
      consumedReserveTag,
      intervalTimeConfig,
    )
    results.push(
      dispatch(
        getAggregatedIntervals(
          assetId,
          consumedReserveTag,
          selectedDate,
          endTime,
          durationSeconds,
          'max',
          'none',
          intervalConfigConsumedReserve,
          intervalTimeConfig,
        ),
      ),
    )
    const energyTag = asset.data.tag_prefix + soeTagSuffix
    results.push(
      dispatch(
        getCollatedAssetIntervals(
          new IntervalConfig(`${assetId}_${energyTag}`, energyTag, assetId),
          intervalTimeConfig,
        ),
      ),
    )
    results.push(
      dispatch(
        getWarrantyConstraintIntervals(
          new IntervalConfig(`${assetId}_${asset.data.tag_prefix}_${WARRANTY_TAG}`, WARRANTY_TAG, assetId),
          intervalTimeConfig,
        ),
      ),
    )
  }

  return Promise.all(results.map(p => _.values(p)))
}
