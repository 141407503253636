import React, { useCallback, useEffect, useReducer, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment-timezone'
import { makeStyles } from '@material-ui/core/styles'
import { Box, FormControlLabel, Grid, MenuItem, Switch, TextField, Typography } from '@material-ui/core'
import { Button, useNotifier } from '@fluence/core'
import { convertkWToMW, convertMWToKW, getTradingStart } from '../../utility/utility'
import { isPastEodCutoff, getNextIntervalTime } from '../../utility/time-utils'
import { batteryCommandSettingResource, commericalBatteryCommandSettingResource } from '../../redux/features/setting'
import { currentUserPermissionsSelector } from '../../redux/features/user'
import { globalAppSelectors } from '../../redux/features/app'
import { API } from '../../redux/api'
import Card from '../Card'
import KeyboardDatePicker from '../KeyboardDatePicker'
import InputNumberFormat from '../InputNumberFormat'
import Toggle from '../Toggle'
import { DAYS_OF_THE_WEEK, DAYS_OF_THE_WEEK_NUMBER, SOC_MAX, SOC_MIN, SOE_MAX, SOE_MIN, BATTERY_COMMAND_SETTING_TAG, COMMERCIAL_BATTERY_COMMAND_SETTING_TAG } from '../../utility/constants'
import IntervalRangePicker from '../ScheduledChangeRangePicker'

const DISPLAY_NAME = 'NewScheduledChangeCard'

const useStyles = makeStyles(theme => ({
  root: {},
  card: {
    background: _.get(theme, 'palette.background.paper', '#1D2126'),
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cardHeader: {
    borderBottom: `2px solid ${_.get(theme, 'palette.background.default', '#2f353d')}`,
    flexWrap: 'wrap',
  },
  chargeContainer: {
    display: 'flex',
    gap: theme.spacing(4),
  },
  dateAndToggle: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 800,
  },
  fullWidth: {
    width: '100%',
  },
  gridGap: {
    gap: theme.spacing(4),
    border: `solid 4px rgba(255, 255, 255, 0.2)`,
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  gridSoc: {
    border: `solid 4px rgba(255, 255, 255, 0.2)`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  verticallyCenterBoxContent: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  gap: {
    gap: theme.spacing(4),
  },
  energyInputContainer: {
    display: 'block',
  },
  intervalRangPicker: {},
  menu: {
    width: 300,
  },
  menuInput: {
    fontSize: '0.8rem',
    maxHeight: theme.spacing(0),
    minWidth: '200px',
  },
  powerAndChargeContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    maxWidth: 1000,
    gap: theme.spacing(2),
  },
  productTextField: {
    marginTop: 18.75,
  },
  row: {
    paddingTop: theme.spacing(2),
  },
  reasonBox: {
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(2),
    width: 315,
  },
  reasonInput: {
    borderRadius: 2,
    marginTop: theme.spacing(1),
    maxHeight: theme.spacing(6),
  },
  smallLabel: {
    fontSize: theme.typography.pxToRem(10.5),
    marginBottom: theme.spacing(0.375),
    color: theme.palette.text.secondary,
  },
  helperText: {
    fontSize: theme.typography.pxToRem(10.5),
    marginBottom: theme.spacing(0.375),
    color: theme.palette.text.secondary,
  },
  submitContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  switchBox: {
    paddingBottom: theme.spacing(2),
  },
  limitTextField: {
    width: theme.spacing(14),
  },
}))

const MAX_INPUT = 99999

const PRODUCTS = {
  energy: 'energy',
  power: 'power',
  powerMin: 'power_min',
}

const ACTIONS = {
  charge: 'charge',
  discharge: 'discharge',
}

const PRODUCTS_ACTIONS = _.values(PRODUCTS)
  .map(product => {
    return _.values(ACTIONS).map(action => {
      return { product, action }
    })
  })
  .flat(1)

const findProductsActionIdx = (product, action) =>
  PRODUCTS_ACTIONS.findIndex(productAction => productAction.product === product && productAction.action === action)

const CHARGE_FIELD = 'charge'
const SOC_MIN_FIELD = 'socMin'
const SOC_MAX_FIELD = 'socMax'
const SOE_MIN_FIELD = 'soeMin'
const SOE_MAX_FIELD = 'soeMax'
const CHARGE_LIMIT_FIELD = 'chargeLimit'
const DISCHARGE_LIMIT_FIELD = 'dischargeLimit'
const REASON_FIELD = 'reason'
const COMMERCIAL_VIEW = 'Commercial'
const CONTRACTUAL_VIEW = 'Contractual'
const LOSS_THRESHOLD_LIMIT_FEILD = 'lossThresholdLimit'

const ENERGY_OR_CHARGE_STATES = {
  soc: 'soc',
  soe: 'soe',
}

const ENERGY_OR_CHARGE_STATE_OPTIONS = _.values(ENERGY_OR_CHARGE_STATES)
const findSoeOrSocOptionIdx = option =>
  ENERGY_OR_CHARGE_STATE_OPTIONS.findIndex(soeOrSocOption => soeOrSocOption === option)

const schedulableDataFields = {
  [CHARGE_FIELD]: 'charge',
  [SOC_MIN_FIELD]: SOC_MIN,
  [SOC_MAX_FIELD]: SOC_MAX,
  [SOE_MIN_FIELD]: SOE_MIN,
  [SOE_MAX_FIELD]: SOE_MAX,
  [CHARGE_LIMIT_FIELD]: 'charge_limit',
  [DISCHARGE_LIMIT_FIELD]: 'discharge_limit',
  [LOSS_THRESHOLD_LIMIT_FEILD]: 'commercial_price_per_kwh'
}

const emptySchedulableData = _.keys(schedulableDataFields).reduce((acc, field) => ({ ...acc, [field]: '' }), {})

const initFormState = ({ endDate }) => {
  return {
    ...emptySchedulableData,
    action: ACTIONS.charge,
    daysOfTheWeek: [],
    endDate,
    endTime: '',
    product: PRODUCTS.energy,
    recurring: false,
    startTime: '',
    reason: '',
    socOrSoeOption: ENERGY_OR_CHARGE_STATES.soc,
  }
}

const TYPES = {
  UPDATE: 'UPDATE_FIELD',
  RESET: 'RESET',
}

const formReducer = (state, action) => {
  switch (action.type) {
    // Input: an array of fields to update.
    case TYPES.UPDATE: {
      const newState = { ...state }
      for (const update of action.payload) {
        newState[update.field] = update.value
      }
      return newState
    }
    case TYPES.RESET: {
      const { endDate } = action.payload
      return initFormState({ endDate })
    }
    default:
      return state
  }
}

function NewScheduledChangeCard(props) {
  const { asset, className: classNameProp, dispatchSubmitSchedule, getBatteryCommandSetting, getCommericalBatteryCommandSetting, getMarketPriceCap } = props
  const { notifySuccess, notifyError } = useNotifier()

  const classes = useStyles()

  const [reasonError, setReasonError] = useState(null)

  const assetId = _.get(asset, 'assetId')
  const commercialOnlineDate = _.get(asset, 'data.commercial_online_date')
  const market = _.get(asset, 'market')
  const mtz = _.get(asset, 'market.data.timezone')
  const marketStartHour = _.get(asset, 'market.data.trading_day_start_hour', 0)
  const marketTimezone = _.get(market, 'data.timezone')
  const marketTimezoneDisplayName = _.get(asset, 'market.data.timezone_display_name', '')
  moment.tz.setDefault(mtz)

  const { 'battery_opt_start_cutoff_secs': batteryOptStartCutoffSecs } = _.get(
    asset,
    'market.data.gate_closure_cutoffs',
    {},
  )

  const [requestInProgress, setRequestInProgress] = useState(false)
  const DEFAULT_INTERVAL_RANGE_MILISEC = moment.duration(1, 'hours').asMilliseconds()
  const [selectedInterval, setSelectedInterval] = useState({
    start: 0,
    end: DEFAULT_INTERVAL_RANGE_MILISEC,
  })
  const [initialStartInterval, setInitialStartInterval] = useState(moment().valueOf())
  const [selectedScheduledView, SetSelectedScheduledView] = useState('')
  const [tag, setTag] = useState('')

  const today = getTradingStart(market)
  const tomorrowMarketStartTime = moment(today).add(1, 'day')
  const [hasIntervalInputError, setHasIntervalInputError] = useState()

  if (isPastEodCutoff(today, batteryOptStartCutoffSecs, marketStartHour)) {
    today.add(1, 'days')
  }

  const [form, dispatch] = useReducer(
    formReducer,
    {
      endDate: moment(today).add(1, 'weeks'),
    },
    initFormState,
  )

  const isFormValid = formValues => {
    const intervalCutoffDuration = moment.duration(batteryOptStartCutoffSecs, 'seconds')
    const nextIntervalTime = getNextIntervalTime(moment(), intervalCutoffDuration.asSeconds()).valueOf()

    const rules = [
      [
        _.keys(schedulableDataFields),
        (schedulableDataFieldkeys, formValues) =>
          schedulableDataFieldkeys.some(fieldKey => !_.isEmpty(_.get(formValues, fieldKey))),
      ],
      [
        'recurring',
        (recurring, formValues) => {
          const formDaysOfTheWeek = _.get(formValues, 'daysOfTheWeek', [])
          return _.get(formValues, recurring) ? !_.isEmpty(formDaysOfTheWeek) : true
        },
      ],
      [
        'startTime',
        (startTime, formValues) => {
          const formStartTime = _.get(formValues, startTime)
          return moment(formStartTime).isSameOrAfter(nextIntervalTime, 'minute') && !_.isNil(formStartTime)
        },
      ],
      [
        'endTime',
        (endTime, formValues) => {
          const formEndTime = _.get(formValues, endTime)
          const formStartTime = _.get(formValues, 'startTime')
          return moment(formEndTime).isAfter(formStartTime, 'minute') && !_.isNil(formEndTime)
        },
      ],
    ]

    const isEveryRuleValid = rules.every(rule => {
      const [prop, action] = rule
      if (_.isFunction(action)) {
        return action(prop, formValues)
      }
      return false
    })

    const checkCommercialLossThreshold = (thresholdValue) => {
      if (selectedScheduledView === COMMERCIAL_VIEW) {
        return !_.isNil(thresholdValue) && !_.isEmpty(thresholdValue);
      }
      return true;
    }

    const finalResult = isEveryRuleValid && checkCommercialLossThreshold((formValues?.lossThresholdLimit))
    return finalResult
  }

  const isDirtyCheck = formValues => {
    const initialForm = initFormState(moment(today).add(1, 'weeks'))
    const intervalCutoffDuration = moment.duration(batteryOptStartCutoffSecs, 'seconds')
    const nextIntervalTime = getNextIntervalTime(moment(), intervalCutoffDuration.asSeconds()).valueOf()
    const defaultEndTime = moment(nextIntervalTime).add(DEFAULT_INTERVAL_RANGE_MILISEC, 'milliseconds')
    const boundedDefaultEndTime = defaultEndTime.isBefore(tomorrowMarketStartTime)
      ? defaultEndTime
      : tomorrowMarketStartTime

    const rules = [
      [CHARGE_FIELD, _.isEqual],
      [CHARGE_LIMIT_FIELD, _.isEqual],
      [DISCHARGE_LIMIT_FIELD, _.isEqual],
      [SOC_MIN_FIELD, _.isEqual],
      [SOC_MAX_FIELD, _.isEqual],
      [SOE_MIN_FIELD, _.isEqual],
      [SOE_MAX_FIELD, _.isEqual],
      [LOSS_THRESHOLD_LIMIT_FEILD, _.isEqual],
      ['action', _.isEqual],
      ['recurring', _.isEqual],
      ['product', _.isEqual],
      ['startTime', startTime => moment(startTime).isSame(nextIntervalTime, 'minute') && !_.isNil(startTime)],
      ['endTime', endTime => moment(endTime).isSame(boundedDefaultEndTime, 'minute') && !_.isNil(endTime)],
    ]

    const clean = rules.every(rule => {
      const [prop, action] = rule
      if (_.isFunction(action)) {
        return action(formValues[prop], initialForm[prop])
      }
      return false
    })

    return !clean
  }

  const isDirty = isDirtyCheck(form)

  // Total possible capacity (Mega Watt hours)
  const batteryCapacityKwh = _.get(asset, 'data.battery_capacity_kwh')
  const maximumEnergyCapacityMwh = convertkWToMW(batteryCapacityKwh) * _.get(asset, 'data.number_batteries')
  // Total possible output rate (Mega Watts)
  const maximumPowerMw = _.round(convertkWToMW(_.get(asset, 'data.properties.energy_bid_unit_limits.pasa_kw')), 0)
  const timeInHours = _.round(_.divide(selectedInterval.end - selectedInterval.start, 1000 * 60 * 60), 2)
  const maximumEnergyMwh = _.round(Math.min(maximumEnergyCapacityMwh * timeInHours, maximumEnergyCapacityMwh), 0)
  const maxProductInput = form.product === PRODUCTS.energy ? maximumEnergyMwh : maximumPowerMw
  const maxChargeLimitInput = maximumPowerMw
  const maxAllowedSoeInput = _.round(maximumEnergyCapacityMwh, 0)
  const MARKET_PRICE_MAXIMUM = getMarketPriceCap(moment(form.endTime))

  const getMinSocInputHelperText = () => {
    const minSocVal = form[SOC_MIN_FIELD]
    const minVal = _.isNil(minSocVal) || minSocVal === '' ? 0 : minSocVal
    return `min: ${minVal}, max: 100`
  }
  const getMaxSocInputHelperText = () => {
    const maxSocVal = form[SOC_MAX_FIELD]
    const maxVal = _.isNil(maxSocVal) || maxSocVal === '' ? 100 : maxSocVal
    return `min: 0, max: ${maxVal}`
  }

  const getSoeInputHelperText = isMinSoe => {
    const maxSoeLowerLimit = _.isEmpty(_.get(form, SOE_MIN_FIELD)) ? 0 : _.get(form, SOE_MIN_FIELD)
    const minSoeUpperLimit = _.isEmpty(_.get(form, SOE_MAX_FIELD)) ? maxAllowedSoeInput : _.get(form, SOE_MAX_FIELD)
    return isMinSoe ? `min: 0, max: ${minSoeUpperLimit}` : `min: ${maxSoeLowerLimit}, max: ${maxAllowedSoeInput}`
  }

  const getMaxInputHelperText = maxVal => `maximum: ${maxVal}`

  const getLossThresholdInputHelperText = () => {
    return `min: 0, max: ${MARKET_PRICE_MAXIMUM}`
  }

  const schedulableDataInputErrorMap = useMemo(
    () =>
      _.keys(schedulableDataFields).reduce((acc, field) => {
        const value = _.get(form, field)
        const numericValue = _.toNumber(value)
        let minInput = 0
        let maxInput
        if (field === CHARGE_FIELD) {
          maxInput = _.toNumber(maxProductInput)
        } else if (field === SOC_MIN_FIELD) {
          // These are % values
          // socMin <= socMax otherwise error
          const socMax =
            form[SOC_MAX_FIELD] !== '' && !_.isNil(form[SOC_MAX_FIELD]) ? _.toNumber(form[SOC_MAX_FIELD]) : null
          maxInput = !_.isNil(socMax) && !_.isNil(form[SOC_MAX_FIELD]) && !(form[SOC_MAX_FIELD] > 100) ? socMax : 100
        } else if (field === SOC_MAX_FIELD) {
          const socMin =
            form[SOC_MIN_FIELD] !== '' && !_.isNil(form[SOC_MAX_FIELD]) ? _.toNumber(form[SOC_MIN_FIELD]) : null
          minInput = !_.isNil(socMin) && !_.isNil(form[SOC_MAX_FIELD]) && form[SOC_MAX_FIELD] !== '' ? socMin : 0
          maxInput = 100
        } else if ([SOE_MAX_FIELD, SOE_MIN_FIELD].includes(field)) {
          if (SOE_MAX_FIELD === field) {
            maxInput = maxAllowedSoeInput
            minInput = _.isEmpty(form[SOE_MIN_FIELD]) ? 0 : form[SOE_MIN_FIELD]
          } else {
            maxInput = _.isEmpty(form[SOE_MAX_FIELD]) ? maxAllowedSoeInput : form[SOE_MAX_FIELD]
          }
        } else if (field === LOSS_THRESHOLD_LIMIT_FEILD) {
          maxInput = MARKET_PRICE_MAXIMUM
        } else {
          maxInput = _.toNumber(maxChargeLimitInput)
        }
        const hasError =
          !_.isNumber(+value) || (numericValue < minInput && !_.isEmpty(value)) || numericValue > maxInput
        return { ...acc, [field]: hasError }
      }, {}),
    [form, maxAllowedSoeInput, maxChargeLimitInput, maxProductInput, MARKET_PRICE_MAXIMUM],
  )

  const handleDaySelection = (event, newDays) => {
    dispatch({
      type: TYPES.UPDATE,
      payload: [{ field: 'daysOfTheWeek', value: newDays }],
    })
  }

  const handleTextChange = field => event => {
    const value = event.target.value
    dispatch({
      type: TYPES.UPDATE,
      payload: [{ field, value }],
    })
  }

  const handleChangeEndDatePicker = date => {
    const type = TYPES.UPDATE
    const payload = [{ field: 'endDate', value: moment(date) }]
    dispatch({
      type,
      payload,
    })
  }

  const handleSwitch = event => {
    const field = _.get(event, 'target.value')
    const checked = _.get(event, 'target.checked')
    dispatch({
      type: TYPES.UPDATE,
      payload: [{ field, value: checked }],
    })
  }

  const handleIntervalOnChange = (start, end) => {
    setSelectedInterval(prev => {
      const payload = [
        { field: 'startTime', value: start },
        { field: 'endTime', value: end },
      ]

      const newMinimumEndDate = getTradingStart(market, moment(start)).add(1, 'weeks')
      if (newMinimumEndDate.isAfter(moment(form.endDate))) {
        const update = { field: 'endDate', value: newMinimumEndDate }
        payload.push(update)
      }
      dispatch({
        type: TYPES.UPDATE,
        payload,
      })
      return { start, end }
    })
  }

  const todayTimestamp = moment(today).valueOf()
  const resetForm = useCallback(() => {
    dispatch({
      type: TYPES.RESET,
      payload: {
        endDate: moment(todayTimestamp).add(1, 'weeks'),
      },
    })
    setInitialStartInterval(moment().valueOf())
    setReasonError(null)
  }, [todayTimestamp])

  const handleCancel = async () => {
    SetSelectedScheduledView('')
    resetForm()
  }

  const handleDetectIntervalTimeInputError = isTimeInputError => {
    if (isTimeInputError !== hasIntervalInputError) {
      setHasIntervalInputError(isTimeInputError)
      if (isTimeInputError) {
        const payload = [
          { field: 'startTime', value: '' },
          { field: 'endTime', value: '' },
        ]
        dispatch({
          type: TYPES.UPDATE,
          payload,
        })
      }
    }
  }
  const handleDoubleClick = fieldName => {
    let filledInputValue
    if (fieldName === CHARGE_FIELD) {
      filledInputValue = maxProductInput
    } else if (fieldName === SOC_MIN_FIELD) {
      filledInputValue = 0
    } else if (fieldName === SOC_MAX_FIELD) {
      filledInputValue = 100
    } else if ([SOE_MIN_FIELD, SOE_MAX_FIELD].includes(fieldName)) {
      filledInputValue = fieldName === SOE_MIN_FIELD ? 0 : maxAllowedSoeInput
    } else {
      filledInputValue = maxChargeLimitInput
    }
    dispatch({
      type: TYPES.UPDATE,
      payload: [{ field: fieldName, value: filledInputValue }],
    })
  }

  const handleSubmit = async () => {
    setRequestInProgress(true)
    const invalidReasonErrorMsg = getInvalidReasonError(form[REASON_FIELD])
    if (invalidReasonErrorMsg) {
      setReasonError(invalidReasonErrorMsg)
      setRequestInProgress(false)
    } else {
      setReasonError(null)
      const data = {
        'charge_kw': null,
        'discharge_kw': null,
        'charge_kwh': null,
        'discharge_kwh': null,
        'charge_min_kw': null,
        'discharge_min_kw': null,
        'soc_min': null,
        'soc_max': null,
        [SOE_MIN]: null,
        [SOE_MAX]: null,
        'discharge_limit_kw': null,
        'charge_limit_kw': null,
        'commercial_price_per_kwh': null,
        'rebid_reason': null,
      }

      _.keys(schedulableDataFields).forEach(formKey => {
        const modifiedData = _.get(form, formKey)
        const apiTag = _.get(schedulableDataFields, formKey)
        if (!_.isEmpty(modifiedData)) {
          if (formKey === CHARGE_FIELD) {
            let dataPrefix = form.action
            if (form.product === PRODUCTS.powerMin) {
              dataPrefix = `${dataPrefix}_min`
            }
            const dataSuffix = form.product === PRODUCTS.energy ? 'kwh' : 'kw'
            data[`${dataPrefix}_${dataSuffix}`] = convertMWToKW(modifiedData)
          } else if (formKey === SOC_MIN_FIELD || formKey === SOC_MAX_FIELD) {
            data[apiTag] = _.toNumber(modifiedData) / 100.0
          } else if ([SOE_MIN_FIELD, SOE_MAX_FIELD].includes(formKey)) {
            data[schedulableDataFields[formKey]] = convertMWToKW(modifiedData)
          } else if (formKey === LOSS_THRESHOLD_LIMIT_FEILD) {
            if (selectedScheduledView === CONTRACTUAL_VIEW) {
              data[apiTag] = null
            } else {
              data[apiTag] = _.toNumber(modifiedData) / 1000
            }
          } else {
            // send request to update charge limit/discharge limit
            data[`${apiTag}_kw`] = convertMWToKW(modifiedData)
          }
        }
      })

      if (form[REASON_FIELD] !== '') {
        data['rebid_reason'] = form[REASON_FIELD]
      }

      const startTime = moment(form.startTime)
      let endTime
      if (form.recurring) {
        // Add one day so endTime will include the selected end date
        const endHour = moment(form.endTime).hour()
        const endMinute = moment(form.endTime).minute()
        endTime = moment(form.endDate).startOf('day').add(endHour, 'hours').add(endMinute, 'minutes').format()
      } else {
        endTime = moment(form.endTime).format()
      }

      const createRecurringSchedule = () => {
        const startTime = moment(form.startTime)
        const endTimeObj = moment(form.endTime)
        const endTimeOffset = endTimeObj.day() > startTime.day() ? 1 : 0

        // Check if start and end times are between 12 AM and 4 AM
        const isEarlyMorning = (time) => time.hour() >= 0 && time.hour() <= 4;

        return form.daysOfTheWeek.map(day => {
          let fromDayOfWeek = DAYS_OF_THE_WEEK_NUMBER[day]
          let toDayOfWeek = (DAYS_OF_THE_WEEK_NUMBER[day] + endTimeOffset) % 7

          if (isEarlyMorning(startTime) && isEarlyMorning(endTimeObj)) {
            fromDayOfWeek = (fromDayOfWeek + 1) % 7;
            toDayOfWeek = (toDayOfWeek + 1) % 7;
          }

          return {
            fromDayOfWeek,
            fromHour: startTime.hour(),
            fromMinute: startTime.minute(),
            toDayOfWeek,
            toHour: endTimeObj.hour(),
            toMinute: endTimeObj.minute(),
          }
        })
      }

      const schedule = {
        timezone: marketTimezone,
      }

      if (form.recurring) {
        schedule.periods = createRecurringSchedule()
      }
      const scheduleType = form.recurring ? 'weekly' : 'event'
      const response = await dispatchSubmitSchedule(assetId, startTime.format(), endTime, schedule, data, scheduleType, tag)
      if (response.error) {
        notifyError('Unable to save new scheduled change')
      } else {
        resetForm()
        SetSelectedScheduledView('')
        notifySuccess('New scheduled change created successfully')
        getBatteryCommandSetting(assetId, commercialOnlineDate)
        getCommericalBatteryCommandSetting(assetId, commercialOnlineDate)
      }
      setRequestInProgress(false)
    }
  }

  const handleSelectedProductsActionOnChange = event => {
    const productActionIdx = event.target.value
    const { action, product } = _.get(PRODUCTS_ACTIONS, productActionIdx)
    dispatch({
      type: TYPES.UPDATE,
      payload: [
        { 'field': 'product', 'value': product },
        { 'field': 'action', 'value': action },
      ],
    })
  }

  const handleSelectedSoeOrSocOptionOnChange = event => {
    const optionIdx = event.target.value
    const option = _.get(ENERGY_OR_CHARGE_STATE_OPTIONS, optionIdx)
    const clearSoeSocInForm = [SOC_MIN_FIELD, SOC_MAX_FIELD, SOE_MIN_FIELD, SOE_MAX_FIELD].map(fieldName => ({
      'field': fieldName,
      'value': '',
    }))

    dispatch({
      type: TYPES.UPDATE,
      payload: [...clearSoeSocInForm, { 'field': 'socOrSoeOption', 'value': option }],
    })
  }

  const handleChangeScheduleView = (event, selectedScheduledView) => {
    if (!_.isNil(selectedScheduledView) && !_.isNil(event)) {
      SetSelectedScheduledView(selectedScheduledView)
      if (selectedScheduledView === COMMERCIAL_VIEW) {
        setTag(COMMERCIAL_BATTERY_COMMAND_SETTING_TAG)
      } else if (selectedScheduledView === CONTRACTUAL_VIEW) {
        setTag(BATTERY_COMMAND_SETTING_TAG)
      }
    }
  }

  useEffect(() => {
    resetForm()
  }, [assetId, resetForm])
  const chargeEnergyOrPowerUnits = form.product === PRODUCTS.energy ? 'MWh' : 'MW'
  const chargeLimitEnergyUnit = 'MW'
  const isSocOptionSelected = form.socOrSoeOption === ENERGY_OR_CHARGE_STATES.soc
  const socOrSoeUnit = isSocOptionSelected ? '%' : 'MWh'
  const thresholdUnit = '$/MWh'
  const disableEndDate = !form.recurring
  const isSchedulableDataInputError = _.values(schedulableDataInputErrorMap).some(hasError => hasError)

  const minEndDate = getTradingStart(market, moment(form.startTime)).add(1, 'weeks')
  const maxEndDate = moment(minEndDate).add(2, 'years')

  const disableSubmitButton = (!isFormValid(form) || isSchedulableDataInputError || requestInProgress || selectedScheduledView === "")

  const getEnergyOrPowerDropdownLabel = productAction => {
    if (productAction.product === PRODUCTS.powerMin) {
      return `Power - Min ${_.capitalize(productAction.action)}`
    }
    let middle = 'Exact'
    if (productAction.product === PRODUCTS.energy) {
      middle = 'Exact NET'
    }
    return `${_.capitalize(productAction.product)} - ${middle} ${_.capitalize(productAction.action)}`
  }
  const getSocOrSoeDropdownLabel = option =>
    option === ENERGY_OR_CHARGE_STATES.soc ? 'State of Charge' : 'State of Energy'

  const socOrSoeMinField = isSocOptionSelected ? SOC_MIN_FIELD : SOE_MIN_FIELD
  const socOrSoeMaxField = isSocOptionSelected ? SOC_MAX_FIELD : SOE_MAX_FIELD

  return (
    <>
      <Card
        classes={{
          root: classes.card,
          content: classes.cardContent,
          header: classes.cardHeader,
          action: classes.submitContainer,
        }}
        title={
          <Box display="inline-flex" alignItems="center">
            <Box display="flex" flex="0 1 auto" mr={2}>
              <Typography variant="h3">New Scheduled Change</Typography>
            </Box>
            <Box display="flex" flex="0 1 auto">
              <Toggle
                values={[
                  {
                    text: COMMERCIAL_VIEW,
                    key: COMMERCIAL_VIEW,
                    toolTip: 'Commercial Schedule Changes',
                  },
                  {
                    text: CONTRACTUAL_VIEW,
                    key: CONTRACTUAL_VIEW,
                    toolTip: 'Contractual Schedule Changes',
                  },
                ]}
                selected={selectedScheduledView}
                onChange={handleChangeScheduleView}
              />
            </Box>
          </Box>
        }
        titleTypographyProps={{ variant: 'h3' }}
        inProgress={requestInProgress}
        action={
          <ScheduleCancelControlButtons
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            disableCancel={!isDirty}
            disableSubmit={disableSubmitButton}
          />
        }
      >
        <Box p={2} className={classNameProp}>
          <form className={classes.root} noValidate autoComplete="off">
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Box className={classes.powerAndChargeContainer} pb={1}>
                  <Box className={classes.gridGap}>
                    <Box className={classes.energyInputContainer}>
                      <Box xs={12} mr={4} pb={2}>
                        <TextField
                          select
                          name="productAction"
                          value={findProductsActionIdx(form.product, form.action)}
                          onChange={handleSelectedProductsActionOnChange}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu,
                              anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                              },
                            },
                          }}
                          InputProps={{ classes: { input: classes.menuInput } }}
                          InputLabelProps={{ shrink: true }}
                          margin="dense"
                        >
                          {PRODUCTS_ACTIONS.map((productAction, productActionIdx) => {
                            return (
                              <MenuItem key={productActionIdx} value={productActionIdx} style={{ fontSize: 13 }}>
                                {getEnergyOrPowerDropdownLabel(productAction)}
                              </MenuItem>
                            )
                          })}
                        </TextField>
                      </Box>
                      <Box className={classes.productTextField} mt={2}>
                        <TextField
                          required
                          name="charge"
                          onDoubleClick={() => handleDoubleClick(CHARGE_FIELD)}
                          InputProps={{
                            inputComponent: InputNumberFormat,
                            inputProps: {
                              isAllowed: values => {
                                const { formattedValue, floatValue } = values
                                const isValid =
                                  formattedValue === '' ||
                                  formattedValue === '-' ||
                                  (floatValue >= 0 && floatValue <= MAX_INPUT)
                                return isValid
                              },
                            },
                            endAdornment: <Typography variant="body1">{chargeEnergyOrPowerUnits}</Typography>,
                          }}
                          value={form[CHARGE_FIELD]}
                          onChange={handleTextChange(CHARGE_FIELD)}
                          error={_.get(schedulableDataInputErrorMap, CHARGE_FIELD)}
                          helperText={getMaxInputHelperText(maxProductInput)}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.gridSoc}>
                    <Box flex="1 0 auto">
                      <TextField
                        select
                        name="soeOrSocOption"
                        className={classes.fullWidth}
                        value={findSoeOrSocOptionIdx(form.socOrSoeOption)}
                        onChange={handleSelectedSoeOrSocOptionOnChange}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                            anchorOrigin: {
                              vertical: 'top',
                              horizontal: 'right',
                            },
                          },
                        }}
                        InputProps={{ classes: { input: classes.menuInput } }}
                        InputLabelProps={{ shrink: true }}
                        margin="dense"
                      >
                        {ENERGY_OR_CHARGE_STATE_OPTIONS.map((soeOrSocOption, soeOrSocIdx) => {
                          return (
                            <MenuItem key={soeOrSocIdx} value={soeOrSocIdx} style={{ fontSize: 13 }}>
                              {getSocOrSoeDropdownLabel(soeOrSocOption)}
                            </MenuItem>
                          )
                        })}
                      </TextField>
                    </Box>
                    <Box display="flex" flexWrap="nowrap" mt={2}>
                      <Box mr={4}>
                        <Typography className={classes.smallLabel}>MIN {_.toUpper(form.socOrSoeOption)}</Typography>
                        <TextField
                          className={classes.limitTextField}
                          required
                          onDoubleClick={() => handleDoubleClick(socOrSoeMinField)}
                          name="Min Soc/Soe"
                          InputProps={{
                            inputComponent: InputNumberFormat,
                            inputProps: {
                              decimalScale: isSocOptionSelected ? 0 : 1,
                              isAllowed: values => {
                                const { formattedValue, floatValue } = values
                                const isValid = formattedValue === '' || (floatValue >= 0 && floatValue <= MAX_INPUT)
                                return isValid
                              },
                            },
                            endAdornment: <Typography variant="body1">{socOrSoeUnit}</Typography>,
                          }}
                          value={form[socOrSoeMinField]}
                          InputLabelProps={{ shrink: true }}
                          onChange={handleTextChange(socOrSoeMinField)}
                          error={_.get(schedulableDataInputErrorMap, socOrSoeMinField)}
                          helperText={isSocOptionSelected ? getMaxSocInputHelperText() : getSoeInputHelperText(true)}
                        />
                      </Box>
                      <Box>
                        <Typography className={classes.smallLabel}>MAX {_.toUpper(form.socOrSoeOption)}</Typography>
                        <TextField
                          className={classes.limitTextField}
                          required
                          onDoubleClick={() => handleDoubleClick(socOrSoeMaxField)}
                          name="Max Soc/Soe"
                          InputProps={{
                            inputComponent: InputNumberFormat,
                            inputProps: {
                              decimalScale: isSocOptionSelected ? 0 : 1,
                              isAllowed: values => {
                                const { formattedValue, floatValue } = values
                                const isValid = formattedValue === '' || (floatValue >= 0 && floatValue <= MAX_INPUT)
                                return isValid
                              },
                            },
                            endAdornment: <Typography variant="body1">{socOrSoeUnit}</Typography>,
                          }}
                          InputLabelProps={{ shrink: true }}
                          value={form[socOrSoeMaxField]}
                          onChange={handleTextChange(socOrSoeMaxField)}
                          error={_.get(schedulableDataInputErrorMap, socOrSoeMaxField)}
                          helperText={isSocOptionSelected ? getMinSocInputHelperText() : getSoeInputHelperText(false)}
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Box display="flex" flexWrap="nowrap" className={classes.gridGap}>
                    <Box className={classes.verticallyCenterBoxContent}>
                      <Typography className={classes.smallLabel}>CHARGE LIMIT</Typography>
                      <TextField
                        className={classes.limitTextField}
                        required
                        onDoubleClick={() => handleDoubleClick(CHARGE_LIMIT_FIELD)}
                        name="Charge Limit"
                        InputProps={{
                          inputComponent: InputNumberFormat,
                          inputProps: {
                            isAllowed: values => {
                              const { formattedValue, floatValue } = values
                              const isValid =
                                formattedValue === '' ||
                                formattedValue === '-' ||
                                (floatValue >= 0 && floatValue <= MAX_INPUT)
                              return isValid
                            },
                          },
                          endAdornment: <Typography variant="body1">{chargeLimitEnergyUnit}</Typography>,
                        }}
                        value={form[CHARGE_LIMIT_FIELD]}
                        InputLabelProps={{ shrink: true }}
                        onChange={handleTextChange(CHARGE_LIMIT_FIELD)}
                        error={_.get(schedulableDataInputErrorMap, CHARGE_LIMIT_FIELD)}
                        helperText={getMaxInputHelperText(maxChargeLimitInput)}
                      />
                    </Box>
                    <Box className={classes.verticallyCenterBoxContent}>
                      <Typography className={classes.smallLabel}>DISCHARGE LIMIT</Typography>
                      <TextField
                        className={classes.limitTextField}
                        required
                        onDoubleClick={() => handleDoubleClick(DISCHARGE_LIMIT_FIELD)}
                        name="Discharge Limit"
                        InputProps={{
                          inputComponent: InputNumberFormat,
                          inputProps: {
                            isAllowed: values => {
                              const { formattedValue, floatValue } = values
                              const isValid =
                                formattedValue === '' ||
                                formattedValue === '-' ||
                                (floatValue >= 0 && floatValue <= MAX_INPUT)
                              return isValid
                            },
                          },
                          endAdornment: <Typography variant="body1">{chargeLimitEnergyUnit}</Typography>,
                        }}
                        InputLabelProps={{ shrink: true }}
                        value={form[DISCHARGE_LIMIT_FIELD]}
                        onChange={handleTextChange(DISCHARGE_LIMIT_FIELD)}
                        error={_.get(schedulableDataInputErrorMap, DISCHARGE_LIMIT_FIELD)}
                        helperText={getMaxInputHelperText(maxChargeLimitInput)}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} className={classes.row}>
                <Box sx={{
                  display: 'flex',
                  flexWrap: 'wrap'
                }}>
                  <div className={classes.reasonBox}>
                    <TextField
                      variant="filled"
                      value={form.reason}
                      label="Reason"
                      onChange={handleTextChange(REASON_FIELD)}
                      fullWidth
                      error={reasonError}
                      helperText={reasonError}
                      InputProps={{ disableUnderline: true, className: classes.reasonInput }}
                    />
                  </div>
                  {
                    selectedScheduledView === COMMERCIAL_VIEW && (
                      <div>
                        <Typography className={classes.smallLabel}>COMMERCIAL VALUE</Typography>
                        <TextField
                          name="Loss Threshold"
                          value={form[LOSS_THRESHOLD_LIMIT_FEILD]}
                          onChange={handleTextChange(LOSS_THRESHOLD_LIMIT_FEILD)}
                          error={_.get(schedulableDataInputErrorMap, LOSS_THRESHOLD_LIMIT_FEILD)}
                          InputProps={{
                            inputComponent: InputNumberFormat,
                            inputProps: {
                              isAllowed: values => {
                                const { formattedValue, floatValue } = values
                                const isValid =
                                  formattedValue === '' ||
                                  formattedValue === '-' ||
                                  (floatValue >= 0 && floatValue <= MAX_INPUT)
                                return isValid
                              },
                              decimalScale: 2,
                            },
                            endAdornment: <Typography variant="body1" style={{ whiteSpace: 'nowrap' }}>{thresholdUnit}</Typography>,
                          }}
                          InputLabelProps={{ shrink: true }}
                          margin="dense"
                          helperText={getLossThresholdInputHelperText()}
                        >
                        </TextField>
                      </div>
                    )
                  }
                </Box>
                <Box display="flex" flexWrap="wrap">
                  <Box flex="2 1 auto">
                    <IntervalRangePicker
                      className={classes.intervalRangPicker}
                      onChange={handleIntervalOnChange}
                      initialStartDate={initialStartInterval}
                      intervalCutoffSec={batteryOptStartCutoffSecs}
                      timezone={marketTimezone}
                      marketStartHour={marketStartHour}
                      marketTimezoneDisplayName={marketTimezoneDisplayName}
                      detectTimeInputError={handleDetectIntervalTimeInputError}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} className={classes.row}>
                <div className={classes.switchBox}>
                  <FormControlLabel
                    control={
                      <Switch
                        id="schedule-modal-recurring-tgl"
                        checked={form.recurring}
                        color="primary"
                        onChange={handleSwitch}
                        value="recurring"
                        inputProps={{ 'aria-label': 'toggle recurring' }}
                      />
                    }
                    label="Recurring"
                  />
                </div>
              </Grid>
              <Grid container className={classes.dateAndToggle}>
                <Box minWidth="220px" pr={2}>
                  <KeyboardDatePicker
                    id="schedule-modal-end-date"
                    disabled={disableEndDate}
                    timezone={marketTimezone}
                    marketStartHour={marketStartHour}
                    inputVariant="standard"
                    label="END DATE"
                    ariaLabel="change end date"
                    minDate={minEndDate}
                    maxDate={maxEndDate}
                    selectedDate={form.endDate}
                    onChange={handleChangeEndDatePicker}
                  />
                </Box>
                <Box>
                  <Toggle
                    disabled={disableEndDate}
                    values={DAYS_OF_THE_WEEK}
                    selected={form.daysOfTheWeek}
                    onChange={handleDaySelection}
                    exclusive={false}
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Card>
    </>
  )
}

const ScheduleCancelControlButtons = React.memo(({ handleSubmit, handleCancel, disableSubmit, disableCancel }) => (
  <>
    <Button variant="secondary" onClick={handleCancel} disabled={disableCancel}>
      Cancel
    </Button>
    <Button variant="primary" disabled={disableSubmit} onClick={handleSubmit}>
      Schedule
    </Button>
  </>
))

const getInvalidReasonError = reason => {
  if (_.isNil(reason) || _.isEmpty(reason)) {
    return null
  }
  const trimmed = reason.trim()
  const validReason = /^[0-2][0-9][0-5][0-9] [aefpAEFP] .+/
  if (!validReason.test(trimmed)) {
    const wrongCategory = /^[0-2][0-9][0-5][0-9] [^aefpAEFP] .+/
    if (wrongCategory.test(trimmed)) {
      const category = trimmed[5]
      return `Invalid Reason category ${category}. Category must be A, F, P, or E`
    } else {
      return `Invalid Reason. Following reason format must be used “HHMM F Explanation”`
    }
  }
  return null
}

NewScheduledChangeCard.displayName = DISPLAY_NAME

const makeMapStateToProps = () => {
  return state => {
    const permissions = currentUserPermissionsSelector(state)
    const getMarketPriceCap = globalAppSelectors.getMarketPriceCapFn(state)

    return {
      permissions,
      getMarketPriceCap,
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatchSubmitSchedule: async (assetId, startTime, endTime, schedule, data, scheduleType, tag) => {
      const action = API.bess.postSchedule(assetId, startTime, endTime, schedule, data, scheduleType, tag)
      return await dispatch(action)
    },
    getBatteryCommandSetting: (assetId, commercialOnlineDate) => {
      const startTime = moment(commercialOnlineDate)
      return dispatch(batteryCommandSettingResource.get(assetId, startTime, moment().add(1, 'year')))
    },
    getCommericalBatteryCommandSetting: (assetId, commercialOnlineDate) => {
      const startTime = moment(commercialOnlineDate)
      return dispatch(commericalBatteryCommandSettingResource.get(assetId, startTime, moment().add(1, 'year')))
    },
  }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(NewScheduledChangeCard)
