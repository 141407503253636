import { combineReducers } from 'redux'
import _ from 'lodash'
import moment from 'moment-timezone'
import { createSelector } from 'reselect/lib/index'
import { API, makeResourceReducer, ResourceAction } from '../api'
import {
  BATTERY_COMMAND_SETTING_TAG,
  BEP_APPLIED_TAG,
  BEP_CURRENT_TYPE_TAG,
  BEP_LAST_TYPE_TAG,
  BEP_LOG_TYPE_TAG,
  BEP_SETTING_TAG,
  BID_FILE_SETTINGS_TAG,
  BID_SPREAD_SETTING_TAG,
  BID_STRATEGY_SETTING_TAG,
  COMMERCIAL_BATTERY_COMMAND_SETTING_TAG,
  CONFIGURATION_TAG,
  DEGRADATION_COST_SETTING,
  FORECAST_SETTING_TAG,
  LGC_SETTING_TAG,
  MARGINAL_LOSS_FACTOR_SETTING,
  OPERATIONAL_CAPACITY_SETTING_TAG,
  RISK_SETTING_TAG,
  THROUGHPUT_TAG,
  PASA_SETTING_TAG,
} from '../../utility/constants'

const getAssetDataResource = tag =>
  new ResourceAction({
    type: `GET_ASSET_${tag.toUpperCase()}`,
    config: (assetId, startTime, endTime) => {
      return {
        endpoint: `/market/assets/asset/${assetId}/assetData/range?startTime=${encodeURIComponent(
          startTime.toISOString(),
        )}&endTime=${encodeURIComponent(endTime.toISOString())}&tag=${tag}&deleted=false`,
      }
    },
  })

export const upsertAssetDataResource = new ResourceAction({
  type: `CREATE_ASSET_ASSETDATA`,
  method: 'POST',
  config: (assetId, tag, startTime, data) => ({
    endpoint: '/market/assets/assetData',
    body: JSON.stringify({
      assetId,
      tag,
      startTime: moment(startTime).toISOString(),
      data,
    }),
  }),
})

export const postMultipleAssetDataResource = new ResourceAction({
  type: `CREATE_ASSET_ASSETDATA_BULK`,
  method: 'POST',
  config: (assetId, tags, startTime, dataList) => ({
    endpoint: '/market/frontend/asset/{assetId}/assetData',
    body: JSON.stringify(
      dataList.map((data, idx) => ({
        assetId,
        tag: tags[idx],
        startTime: moment(startTime).toISOString(),
        data,
      })),
    ),
  }),
})

export const deleteAssetDataResource = new ResourceAction({
  type: `DELETE_ASSET_DATA`,
  method: `PUT`,
  config: assetDataId => ({
    endpoint: `/market/assets/assetData/delete?assetDataId=${assetDataId}`,
  }),
})

export const deleteMultipleAssetDataResource = new ResourceAction({
  type: `DELETE_ASSET_DATA`,
  method: `PUT`,
  config: assetDataIds => ({
    endpoint: `/market/assets/assetData/multiple/delete?&assetDataIds=${assetDataIds.join('&assetDataIds=')}`,
  }),
})

const getLastAssetDataResource = tag =>
  new ResourceAction({
    type: `GET_LAST_ASSET_${tag}`.toUpperCase(),
    config: (assetId, startTime, deleted = false) => {
      return {
        endpoint: `/market/assets/asset/${assetId}/assetData/last?tag=${tag}&deleted=${deleted}&startTime=${encodeURIComponent(
          startTime.toISOString(),
        )}`,
      }
    },
  })

const marketAssetDataScheduleResource = tag =>
  new ResourceAction({
    type: `GET_MARKET_ASSET_DATA_SCHEDULE_${tag}`.toUpperCase(),
    config: (assetId, startTime) => {
      return {
        endpoint: `/market/frontend/asset/${assetId}/assetData/schedule?tag=${tag}&startTime=${encodeURIComponent(
          startTime.toISOString(),
        )}`,
      }
    },
  })

export const getActivePPAContractResource = new ResourceAction({
  type: `GET_ACTIVE_PPA_CONTRACT`,
  config: (assetId, startTime, contractType) => {
    return {
      endpoint: `/market/contracts/asset/${assetId}/last?startTime=${encodeURIComponent(
        startTime.toISOString(),
      )}&contractType=${contractType}`,
    }
  },
})

export const getLastIntervalDataResource = (tag, type) =>
  new ResourceAction({
    type: `GET_LAST_INTERVAL_${tag}_${type}`.toUpperCase(),
    config: (assetId, asOf, productId) => {
      return {
        endpoint: `/market/intervals/asset/${assetId}/product/${productId}/last?asOf=${encodeURIComponent(
          asOf.toISOString(),
        )}&tag=${tag}`,
      }
    },
  })

const getAssetScheduledDataResource = tag =>
  new ResourceAction({
    type: `GET_ASSET_SCHEDULED_${tag.toUpperCase()}`,
    config: (assetId, startTime, endTime) => {
      return {
        endpoint: `/market/assets/asset/${assetId}/assetScheduledData/range?startTime=${encodeURIComponent(
          startTime.toISOString(),
        )}&endTime=${encodeURIComponent(endTime.toISOString())}&tag=${tag}`,
      }
    },
  })

export const bepImpliedLogResource = getLastIntervalDataResource(BEP_APPLIED_TAG, BEP_LOG_TYPE_TAG)
export const bepImpliedResource = getLastIntervalDataResource(BEP_APPLIED_TAG, BEP_LAST_TYPE_TAG)
export const currentBepImpliedResource = getLastIntervalDataResource(BEP_APPLIED_TAG, BEP_CURRENT_TYPE_TAG)

export const batteryCommandSettingResource = getAssetScheduledDataResource(BATTERY_COMMAND_SETTING_TAG)
export const commericalBatteryCommandSettingResource = getAssetScheduledDataResource(COMMERCIAL_BATTERY_COMMAND_SETTING_TAG)

export const bidFileSettingsResource = getLastAssetDataResource(BID_FILE_SETTINGS_TAG)
export const bidSpreadSettingResource = getLastAssetDataResource(BID_SPREAD_SETTING_TAG)
export const bidStrategySettingResource = getLastAssetDataResource(BID_STRATEGY_SETTING_TAG)
export const priceForecastSettingResource = getLastAssetDataResource(FORECAST_SETTING_TAG)

export const allActiveBepResource = getAssetDataResource(BEP_SETTING_TAG)
export const allActiveLgcResource = getAssetDataResource(LGC_SETTING_TAG)
export const allBidSpreadSettingsResource = getAssetDataResource(BID_SPREAD_SETTING_TAG)
export const allFCASResource = getAssetDataResource(BID_STRATEGY_SETTING_TAG)
export const allPriceForecastResource = getAssetDataResource(FORECAST_SETTING_TAG)
export const configurationsResource = getAssetDataResource(CONFIGURATION_TAG)
export const costSettingResource = getAssetDataResource(DEGRADATION_COST_SETTING)
export const mlfRangeResource = getAssetDataResource(MARGINAL_LOSS_FACTOR_SETTING)
export const risksResource = getAssetDataResource(RISK_SETTING_TAG)

export const marketAssetDataBepResource = marketAssetDataScheduleResource(BEP_SETTING_TAG)
export const marketAssetDataCostSettingResource = marketAssetDataScheduleResource(DEGRADATION_COST_SETTING)
export const marketAssetDataLgcResource = marketAssetDataScheduleResource(LGC_SETTING_TAG)
export const marketAssetDataMlfResource = marketAssetDataScheduleResource(MARGINAL_LOSS_FACTOR_SETTING)
export const marketAssetDataOperationalCapacityResource = marketAssetDataScheduleResource(
  OPERATIONAL_CAPACITY_SETTING_TAG,
)
export const marketAssetDataPasaSettingResource = marketAssetDataScheduleResource(PASA_SETTING_TAG)

const risks = makeResourceReducer(risksResource)
const configurations = makeResourceReducer(configurationsResource)
const bidFileSettings = makeResourceReducer(bidFileSettingsResource)
const bep = makeResourceReducer(marketAssetDataBepResource, [])
const allActiveBep = makeResourceReducer(allActiveBepResource, [])
const bepImplied = makeResourceReducer(bepImpliedResource)
const currentBepImplied = makeResourceReducer(currentBepImpliedResource)
const bepImpliedLog = makeResourceReducer(bepImpliedLogResource)
const bidStrategySettings = makeResourceReducer(bidStrategySettingResource, [])
const mlf = makeResourceReducer(marketAssetDataMlfResource, [])
const mlfRange = makeResourceReducer(mlfRangeResource, [])
const ppa = makeResourceReducer(getActivePPAContractResource, [])
const lgc = makeResourceReducer(marketAssetDataLgcResource, [])
const allActiveLgc = makeResourceReducer(allActiveLgcResource, [])
const priceForecast = makeResourceReducer(priceForecastSettingResource, [])
const allPriceForecast = makeResourceReducer(allPriceForecastResource, [])
const allBidSpread = makeResourceReducer(allBidSpreadSettingsResource, [])
const allFCAS = makeResourceReducer(allFCASResource, [])
const bidSpreadSetting = makeResourceReducer(bidSpreadSettingResource)
const batteryCommandSetting = makeResourceReducer(batteryCommandSettingResource, [])
const commercialBatteryCommandSetting = makeResourceReducer(commericalBatteryCommandSettingResource, [])
const costSetting = makeResourceReducer(marketAssetDataCostSettingResource, [])
const riskLogCostSetting = makeResourceReducer(costSettingResource, [])
const operationalCapacitySetting = makeResourceReducer(marketAssetDataOperationalCapacityResource)
const pasaSetting = makeResourceReducer(marketAssetDataPasaSettingResource)

export const bepSelector = state => _.get(state, 'setting.bep.payload', [])
export const bepEntriesSelector = createSelector(bepSelector, items => {
  return _.isArray(items) ? items.filter(entry => !_.get(entry, 'deleted', false)) : []
})
export const endOfIntervalSelector = createSelector(
  state => _.get(state, 'dispatchInterval.status.endOfInterval'),
  item => item,
)
export const bidStrategySettingSelector = state => _.get(state, 'setting.bidStrategySettings.payload.data', false)
export const fcasSelector = state =>
  _.get(state, 'setting.bidStrategySettings.payload.data.use_fcas_cost_recovery_in_opportunity_price', false)
export const mlfSelector = state => _.get(state, 'setting.mlf.payload', [])
export const mlfScheduleSelector = createSelector(mlfSelector, items =>
  items.filter(entry => !_.get(entry, 'data.isDeleted', false)),
)
export const lgcSelector = state => _.get(state, 'setting.lgc.payload', [])
export const lgcEntriesSelector = createSelector(lgcSelector, items =>
  items.filter(entry => !_.get(entry, 'data.isDeleted', false)),
)
export const batteryCommandSettingSelector = state => _.get(state, 'setting.batteryCommandSetting.payload', [])
export const batteryCommandSettingEntriesSelector = createSelector(batteryCommandSettingSelector, items => {
  return _.isArray(items) ? items.filter(entry => !_.get(entry, 'deleted', false)) : []
})
export const commercialBatteryCommandSettingSelector = state => _.get(state, 'setting.commercialBatteryCommandSetting.payload', [])
export const commercialBatteryCommandSettingEntriesSelector = createSelector(commercialBatteryCommandSettingSelector, items => {
  return _.isArray(items) ? items.filter(entry => !_.get(entry, 'deleted', false)) : []
})

export const costSettingSelector = state => _.get(state, 'setting.costSetting.payload', [])
export const selectCostSetting = createSelector(costSettingSelector, items =>
  items.filter(entry => !_.get(entry, 'data.isDeleted', false)),
)

// Risk Log feature for Raise Reg Utilization changes
// **************************************************
const RISK_LOG_RAISE_REG_RANGE_TYPES = {
  request: 'RISK_LOG_RAISE_REG_RANGE_REQUEST',
  success: 'RISK_LOG_RAISE_REG_RANGE_SUCCESS',
  error: 'RISK_LOG_RAISE_REG_RANGE_ERROR',
}

export const getRegulationThroughputRange = (assetId, productId, startTime, deleted = false) => {
  const queryParams = {
    tag: THROUGHPUT_TAG,
    startTime: moment(startTime).toISOString(),
    endTime: moment().toISOString(),
    deleted,
  }
  return API.marketAssetProductData.getAssetProductDataRange({
    pathParams: { assetId, productId },
    queryParams,
    types: RISK_LOG_RAISE_REG_RANGE_TYPES,
    cache: false,
    meta: { assetId, productId, updatedOn: moment() },
  })
}

const riskLogRegulationThroughput = (state = {}, action) => {
  switch (action.type) {
    case RISK_LOG_RAISE_REG_RANGE_TYPES.success: {
      const { assetId, productId } = _.get(action, 'meta', {})
      const path = `${assetId}_${productId}`

      const prevItems = _.get(state, _.toPath(path))
      const payload = _.get(action, 'payload')
      if (!_.isEqual(payload, prevItems)) {
        const newState = { ...state }
        _.set(newState, path, payload)
        return newState
      }
      return state
    }
    default:
      return state
  }
}

export const selectRegulationThroughputSchedule = (
  state,
  assetId,
  genProductId,
  loadProductId,
  adjustToEffectiveStartTime = false,
) => {
  const genItems = _.get(state, `${assetId}_${genProductId}`, [])
  const loadItems = _.get(state, `${assetId}_${loadProductId}`, [])

  // Ensure we display only the latest eliment per time slot by comparing createdOn dates. Newest wins
  const genItemsUnique = Object.values(
    genItems.reduce((acc, next) => {
      const prev = _.get(acc, next.startTime)
      if (!prev || moment(next.createdOn) > moment(prev.createdOn)) {
        acc[next.startTime] = next
      }
      return acc
    }, {}),
  )
  const combinedItems = {}
  genItemsUnique.forEach(item => {
    if (!item.deleted) {
      const startTime = adjustToEffectiveStartTime ? moment(_.get(item, 'startTime')) : _.get(item, 'startTime')
      // tag, startTime, createdBy.name
      _.set(combinedItems, [item.startTime, 'assetProductDataIds', 0], item.assetProductDataId)
      _.set(combinedItems, [item.startTime, 'tag'], `${item.tag} ${genProductId} ${loadProductId}`)
      _.set(combinedItems, [item.startTime, 'productId'], item.productId)
      _.set(combinedItems, [item.startTime, 'createdOn'], item.createdOn)
      _.set(combinedItems, [item.startTime, 'createdBy.name'], item?.createdBy?.name)
      _.set(combinedItems, [item.startTime, 'startTime'], startTime)
      _.set(combinedItems, [item.startTime, 'value', 0], _.get(item, 'data.value'))
    }
  })

  const loadItemsUnique = Object.values(
    loadItems.reduce((acc, next) => {
      const prev = _.get(acc, next.startTime)
      if (!prev || moment(next.createdOn) > moment(prev.createdOn)) {
        acc[next.startTime] = next
      }
      return acc
    }, {}),
  )
  loadItemsUnique.forEach(item => {
    if (!item.deleted) {
      const startTime = adjustToEffectiveStartTime ? moment(_.get(item, 'startTime')) : _.get(item, 'startTime')
      _.set(combinedItems, [item.startTime, 'assetProductDataIds', 1], item.assetProductDataId)
      _.set(combinedItems, [item.startTime, 'tag'], `${item.tag} ${genProductId} ${loadProductId}`)
      _.set(combinedItems, [item.startTime, 'productId'], item.productId)
      _.set(combinedItems, [item.startTime, 'createdOn'], item.createdOn)
      const prevCreatedByName = _.get(combinedItems, [item.startTime, 'createdBy.name'])
      if (prevCreatedByName && prevCreatedByName !== item?.createdBy?.name) {
        _.set(combinedItems, [item.startTime, 'createdBy.name'], `${prevCreatedByName}, ${item?.createdBy?.name}`)
      } else {
        _.set(combinedItems, [item.startTime, 'createdBy.name'], item?.createdBy?.name)
      }
      _.set(combinedItems, [item.startTime, 'startTime'], startTime)
      _.set(combinedItems, [item.startTime, 'value', 1], _.get(item, 'data.value'))
    }
  })

  const sortedItems = Object.values(combinedItems).sort((a, b) => moment(a.startTime) - moment(b.startTime))
  return sortedItems
}
// **************************************************

export default combineReducers({
  allActiveBep,
  allActiveLgc,
  allBidSpread,
  allFCAS,
  allPriceForecast,
  batteryCommandSetting,
  bep,
  bepImplied,
  commercialBatteryCommandSetting,
  currentBepImplied,
  bepImpliedLog,
  bidFileSettings,
  bidSpreadSetting,
  bidStrategySettings,
  configurations,
  costSetting,
  lgc,
  mlf,
  mlfRange,
  operationalCapacitySetting,
  pasaSetting,
  ppa,
  priceForecast,
  riskLogCostSetting,
  riskLogRegulationThroughput,
  risks,
})
