import React, { useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Table, TableHead, TableBody, TableCell, TableRow, Typography, Tooltip } from '@material-ui/core'
import moment, { Moment } from 'moment-timezone'
import numeral from 'numeral'
import clsx from 'clsx'
import Card from '../Card'
import { TooltipContent } from '../TooltipContent'
import {
  OperationalCapacityData,
  useGetBatteryCommandSettings,
  useGetCommercialBatteryCommandSettings,
  useGetOpCapAndPasaAssetDataRange,
} from '../../redux/api'
import {
  SOC_MAX,
  SOC_MIN,
  BATTERY_COMMAND_LIMIT_SETTING_DISPLAY_NAME_MAP,
  DATE_FORMAT,
  OPERATIONAL_CAPACITY_SETTING_TAG,
  PASA_SETTING_TAG,
  THRESHOLD,
} from '../../utility/constants'
import { convertkWToMW, formatTimeAsTradingDate, getOpcapDisplayValue } from '../../utility/utility'

import {
  ConfigurationKey,
  getEnabledConfigurationKeys,
  getProductNameGivenConfigurationKey,
  isAssetRenewable,
  isAssetScheduledBattery,
  isNonScheduledBattery,
} from '../../utility/asset-utils'

const useStyles = makeStyles(theme => ({
  table: {
    tableLayout: 'auto',
    '& td': {
      borderBottom: '1px solid  #30353c',
      padding: 4,
    },
    '& th': {
      padding: 4,
      borderBottom: '1px solid  #30353c',
      textTransform: 'uppercase',
      backgroundColor: theme.palette.background.paper,
    },
  },
  panelBody: {
    overflow: 'auto',
    height: 270,
  },
  fontGrey: {
    '& td': {
      color: (theme.palette.text as any).tertiary,
    },
  },
  fontWhite: {
    '& td': {
      color: theme.palette.text.primary,
    },
  },
  rowTextOverflow: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    lineClamp: 1,
    overflow: 'hidden',
  },
  staticWidthTableCell: {
    minWidth: 150,
  },
}))

interface Props {
  asset: any
  timezone?: string
  operationalCapacityMarketAssetDataNameMap: OperationalCapacityData
}

type ChangeLogEntry = {
  amount: string
  amountTooltip?: any
  amountTooltipHeader1?: string
  amountTooltipHeader2?: string
  change: string
  changeTooltip?: string
  createdOn: string
  createdOnCalendar: number
  effectiveTime: string
  effectiveTimeCalendar: number
  isActive: boolean
  user: string
  type: string
}

const ChangeLog: React.FC<Props> = props => {
  const classes = useStyles(props)
  const { asset, timezone = 'Etc/GMT-10', operationalCapacityMarketAssetDataNameMap } = props
  const assetId = _.get(asset, 'assetId')
  const configuration = _.pickBy(_.get(asset, 'data.configuration'), (value, key) => key.includes('_'))
  const market = _.get(asset, 'market')
  const products = _.get(asset, 'products')
  const productNames: string[] = _.get(asset, 'productNames')
  const commercialOnlineDate = _.get(asset, 'data.commercial_online_date')
  const isRenewable = isAssetRenewable(asset)
  const isScheduledBattery = isAssetScheduledBattery(asset)
  const isNsBattery = isNonScheduledBattery(asset?.data['asset_type'])
  moment.tz.setDefault(timezone)

  const {
    data: bcsEntries,
    isError: bcsError,
    isLoading: bcsIsLoading,
  } = useGetBatteryCommandSettings(assetId, commercialOnlineDate, moment().startOf('day').add(1, 'years'))

  const {
    data: cbcsEntries,
    isError: cbcsError,
    isLoading: cbcsIsLoading,
  } = useGetCommercialBatteryCommandSettings(assetId, commercialOnlineDate, moment().startOf('day').add(1, 'years'))

  const {
    data: ocEntries,
    isError: ocIsError,
    isLoading: ocIsLoading,
  } = useGetOpCapAndPasaAssetDataRange(assetId, commercialOnlineDate, moment().startOf('day').add(1, 'years'))

  const batteryCommandSettingEntries = useMemo(() => {
    return bcsEntries
      ?.filter(bcs => !bcs.deleted)
      .sort((a, b) => moment(b.startTime).valueOf() - moment(a.startTime).valueOf())
  }, [bcsEntries])

  const commercialBatteryCommandSettingEntries = useMemo(() => {
    return cbcsEntries
      ?.filter(cbcs => !cbcs.deleted)
      .sort((a, b) => moment(b.startTime).valueOf() - moment(a.startTime).valueOf())
  }, [cbcsEntries])

  useEffect(() => {
    if (bcsError) {
      console.warn('bcsError', bcsError)
    }
    if (ocIsError) {
      console.warn('ocIsError', ocIsError)
    }
    if (cbcsError) {
      console.warn('cbcsError', cbcsError)
    }
  }, [bcsError, ocIsError, cbcsError])

  const isLoading = bcsIsLoading || ocIsLoading || cbcsIsLoading

  const batteryRowItems: ChangeLogEntry[] =
    batteryCommandSettingEntries
      ?.filter(bcse => moment(bcse.startTime).isBefore(moment()))
      ?.map(bcse => {
        const { changeColDisplayNames, amountColValues: amount, amountLabels } = getRowDisplayMetaData(bcse)
        const isActive = moment(bcse.endTime).isAfter(moment())
        return {
          amount,
          amountTooltip: amountLabels,
          amountTooltipHeader1: '',
          amountTooltipHeader2: '',
          change: changeColDisplayNames,
          changeTooltip: changeColDisplayNames,
          createdOn: formatTimeAsTradingDate(bcse?.createdOn, market, DATE_FORMAT),
          createdOnCalendar: moment(bcse?.createdOn).valueOf(),
          effectiveTime: formatTimeAsTradingDate(bcse.startTime, market, DATE_FORMAT),
          effectiveTimeCalendar: moment(bcse.startTime).valueOf(),
          isActive,
          type: 'Contractual Change',
          user: _.get(bcse, 'createdBy.name'),
        }
      }) || []

  const commercialBatteryRowItems: ChangeLogEntry[] =
    commercialBatteryCommandSettingEntries
      ?.filter(cbcse => moment(cbcse.startTime).isBefore(moment()))
      ?.map(cbcse => {
        const { changeColDisplayNames, amountColValues: amount, amountLabels } = getRowDisplayMetaData(cbcse)
        const isActive = moment(cbcse.endTime).isAfter(moment())
        return {
          amount,
          amountTooltip: amountLabels,
          amountTooltipHeader1: '',
          amountTooltipHeader2: '',
          change: changeColDisplayNames,
          changeTooltip: changeColDisplayNames,
          createdOn: formatTimeAsTradingDate(cbcse?.createdOn, market, DATE_FORMAT),
          createdOnCalendar: moment(cbcse?.createdOn).valueOf(),
          effectiveTime: formatTimeAsTradingDate(cbcse.startTime, market, DATE_FORMAT),
          effectiveTimeCalendar: moment(cbcse.startTime).valueOf(),
          isActive,
          type: 'Commercial Change',
          user: _.get(cbcse, 'createdBy.name'),
        }
      }) || []

  let latestOpcapIdx: number | null = null
  let latestEffectiveTime: Moment | null = null
  let latestCreatedOn: Moment | null = null

  const enabledConfigurations = getEnabledConfigurationKeys(configuration)
  if (isNsBattery) {
    enabledConfigurations[operationalCapacityMarketAssetDataNameMap.ENERGY_GEN] = _.get(
      configuration,
      operationalCapacityMarketAssetDataNameMap.ENERGY_GEN,
    )
    enabledConfigurations[operationalCapacityMarketAssetDataNameMap.ENERGY_LOAD] = _.get(
      configuration,
      operationalCapacityMarketAssetDataNameMap.ENERGY_LOAD,
    )
  }
  const operationalCapacityRowItems: ChangeLogEntry[] =
    ocEntries
      ?.filter(oce => moment(_.first(oce).startTime).isBefore(moment()))
      ?.map((oce, idx) => {
        const isActive = false

        const opcapAssetData = _.find(oce, o => o.tag === OPERATIONAL_CAPACITY_SETTING_TAG) || {}
        const pasaAssetData = _.find(oce, o => o.tag === PASA_SETTING_TAG) || {}
        const allItems = _.toPairs(_.get(opcapAssetData, 'data')).filter(([k, v]) => _.has(enabledConfigurations, k))

        const change = 'Operational Capacity'

        const opcapStartTime = _.get(opcapAssetData, 'startTime', null)
        const pasaStartTime = _.get(pasaAssetData, 'startTime', null)
        const combinedStartTime = moment(opcapStartTime).isValid()
          ? opcapStartTime
          : moment(pasaStartTime).isValid()
            ? pasaStartTime
            : undefined
        const createdOn = _.has(opcapAssetData, 'createdOn')
          ? _.get(opcapAssetData, 'createdOn')
          : _.get(pasaAssetData, 'createdOn')
        const user = _.has(opcapAssetData, 'createdBy.name')
          ? _.get(opcapAssetData, 'createdBy.name')
          : _.get(pasaAssetData, 'createdBy.name')

        const amount = []
        const amountTooltip: object[] = []

        if (
          latestEffectiveTime === null ||
          moment(combinedStartTime).isAfter(latestEffectiveTime) ||
          (moment(combinedStartTime).isSame(latestEffectiveTime) && moment(createdOn).isAfter(latestCreatedOn))
        ) {
          latestEffectiveTime = moment(combinedStartTime)
          latestCreatedOn = moment(createdOn)
          latestOpcapIdx = idx
        }

        const isEmptyOpcap = _.isEmpty(opcapAssetData)
        const isNotEmptyAndNullOpcap = !isEmptyOpcap && allItems.every(item => item[1] === null)
        const isEmptyPasa = _.isEmpty(pasaAssetData)
        const isNotEmptyAndNullPasa = !isEmptyPasa && _.values(_.get(pasaAssetData, 'data', {})).every(v => v === null)

        const isOverrideRemoved =
          (isNotEmptyAndNullOpcap && isNotEmptyAndNullPasa) ||
          (isNotEmptyAndNullOpcap && isEmptyPasa) ||
          (isEmptyOpcap && isNotEmptyAndNullPasa)

        if (isOverrideRemoved) {
          if (isRenewable) {
            amount.push('Override removed')
          } else {
            amount.push('Overrides removed')
          }
        } else {
          allItems.forEach(([k, value]) => {
            if (_.isNumber(value)) {
              const displayValue = getOpcapDisplayValue(value, k, isRenewable, isScheduledBattery, isNsBattery)
              amount.push(`${displayValue}MW`)
            }
          })
          // TODO: Make PASA insert in order after ENERGY_GEN and ENERGY_LOAD.
          _.values(_.get(pasaAssetData, 'data', {})).forEach(value => {
            if (_.isNumber(value)) {
              const displayValue = getOpcapDisplayValue(value, 'PASA', isRenewable, isScheduledBattery, isNsBattery)
              amount.push(`${displayValue}MW`)
            }
          })
        }

        if (isOverrideRemoved) {
          amountTooltip.push({ prevValue: 'Use registered capacity' })
        } else {
          const tooltipItems: any = {}
          allItems.forEach(([k, v]) => {
            const productName = getProductNameGivenConfigurationKey(k as ConfigurationKey, products) as string
            _.set(
              tooltipItems,
              [productName, 'label'],
              getProductNameGivenConfigurationKey(k as ConfigurationKey, products),
            )

            const displayValue = getOpcapDisplayValue(
              v as number,
              productName,
              isRenewable,
              isScheduledBattery,
              isNsBattery,
            )

            const value = _.isNumber(v) ? `${displayValue}MW` : _.isNull(v) ? '-' : ''
            if (k.endsWith('GEN')) {
              _.set(tooltipItems, [productName, 'prevValue'], value)
            } else {
              _.set(tooltipItems, [productName, 'newValue'], value)
            }
          })
          productNames.forEach(productName => {
            if (_.has(tooltipItems, productName)) {
              amountTooltip.push(tooltipItems[productName])
            }
          })
          if (isScheduledBattery) {
            const { PASA_GEN: pasaGen, PASA_LOAD: pasaLoad } = _.get(pasaAssetData, 'data', {})
            const displayValuePasaGen = getOpcapDisplayValue(
              pasaGen,
              'PASA',
              isRenewable,
              isScheduledBattery,
              isNsBattery,
            )
            const displayValuePasaLoad = getOpcapDisplayValue(
              pasaLoad,
              'PASA',
              isRenewable,
              isScheduledBattery,
              isNsBattery,
            )

            const pasaGenDisplayValue = _.isNumber(pasaGen) ? `${displayValuePasaGen}MW` : _.isNull(pasaGen) ? '-' : ''
            const pasaLoadDisplayValue = _.isNumber(pasaLoad)
              ? `${displayValuePasaLoad}MW`
              : _.isNull(pasaLoad)
                ? '-'
                : ''
            const pasaEntry = { label: 'PASA', prevValue: pasaGenDisplayValue, newValue: pasaLoadDisplayValue }
            amountTooltip.splice(1, 0, pasaEntry)
          }
        }

        return {
          amount: amount.join(', '),
          amountTooltip,
          amountTooltipHeader1: isRenewable ? '' : 'GEN',
          amountTooltipHeader2: isRenewable ? '' : 'LOAD',
          change,
          changeTooltip: change,
          createdOn: formatTimeAsTradingDate(createdOn, market, DATE_FORMAT),
          createdOnCalendar: moment(createdOn).valueOf(), // <- For sorting purposes
          effectiveTime: formatTimeAsTradingDate(moment(combinedStartTime), market, DATE_FORMAT),
          effectiveTimeCalendar: moment(combinedStartTime).valueOf(), // <- For sorting purposes
          isActive,
          type: '',
          user,
        }
      }) || []

  if (_.isNumber(latestOpcapIdx)) {
    operationalCapacityRowItems[latestOpcapIdx].isActive = true
  }

  const changeLogItems = _.sortBy(
    [...batteryRowItems, ...commercialBatteryRowItems, ...operationalCapacityRowItems],
    [item => -1 * item.effectiveTimeCalendar, item => -1 * item.createdOnCalendar],
  )

  return (
    <Card title="Change Log" inProgress={isLoading}>
      <Box className={classes.panelBody}>
        {!isLoading && changeLogItems?.length > 0 ? (
          <Table stickyHeader size="small" className={classes.table}>
            <colgroup>
              <col />
              <col />
              <col style={{ width: '30%' }} />
              <col style={{ width: '30%' }} />
              <col />
              <col />
            </colgroup>
            <TableHead>
              <TableHeadRow />
            </TableHead>
            <TableBody>
              {changeLogItems.map((item, i) => (
                <Row key={i} {...item} />
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography>No changes scheduled</Typography>
        )}
      </Box>
    </Card>
  )
}

const TableHeadRow = React.memo(() => {
  const headers = [
    { name: 'Effective Time', key: 'effectiveTime' },
    { name: 'Type', key: 'type' },
    { name: 'Change', key: 'change' },
    { name: 'Amount', key: 'amount' },
    { name: 'User', key: 'user' },
    { name: 'Created', key: 'created' },
  ]
  return (
    <TableRow>
      {headers.map(header => (
        <TableCell key={header.key}>{header.name}</TableCell>
      ))}
    </TableRow>
  )
})

interface RowProps {
  amount: string
  amountTooltip?: []
  amountTooltipHeader1?: string
  amountTooltipHeader2?: string
  change: string
  changeTooltip?: string
  createdOn: string
  effectiveTime: string
  effectiveTimeCalendar?: number
  isActive: boolean
  user: string
  type: string
}

const Row: React.FC<RowProps> = React.forwardRef<HTMLTableRowElement, RowProps>((props, ref) => {
  const {
    amount,
    change,
    effectiveTime,
    effectiveTimeCalendar,
    user,
    createdOn,
    changeTooltip = '',
    amountTooltip,
    amountTooltipHeader1 = '',
    amountTooltipHeader2 = '',
    isActive,
    type,
  } = props
  const classes = useStyles(props)
  const changeColDisplayNameRef = useRef(null)
  const [changeColToolTipText, setChangeColToolTipText] = useState('')

  const isActiveClass = clsx({
    [classes.fontWhite]: isActive,
    [classes.fontGrey]: !isActive,
  })

  // for change column, determine whether cell is overflowing (i.e. showing ellipsis) and show/hide tooltip
  React.useLayoutEffect(() => {
    const current = _.get(changeColDisplayNameRef, ['current'])
    const showColToolTipText = () => {
      if (
        !_.isNil(current) &&
        _.get(current, 'scrollHeight') > _.get(current, 'clientHeight') &&
        _.isEmpty(changeColToolTipText)
      ) {
        setChangeColToolTipText(changeTooltip)
      } // scrollHeight > clientHeight means lineclamp is activated (showing ellipsis)
      else if (
        !_.isNil(current) &&
        _.get(current, 'scrollHeight') <= _.get(current, 'clientHeight') &&
        !_.isEmpty(changeColToolTipText)
      ) {
        setChangeColToolTipText('')
      }
    }
    if (current) {
      if ('ResizeObserver' in window) {
        new ResizeObserver(showColToolTipText).observe(current)
      }
    }
  }, [changeTooltip, changeColDisplayNameRef, changeColToolTipText])

  return (
    <TableRow ref={ref} className={isActiveClass}>
      <Tooltip title={moment(effectiveTimeCalendar).format() || ''}>
        <TableCell className={classes.staticWidthTableCell}>{effectiveTime}</TableCell>
      </Tooltip>
      <TableCell className={classes.staticWidthTableCell}>{type}</TableCell>
      <TableCell>
        <Tooltip title={changeColToolTipText} placement="top" enterDelay={200} leaveDelay={200}>
          <Typography ref={changeColDisplayNameRef} className={classes.rowTextOverflow}>
            {change}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip
          title={
            <TooltipContent
              changes={amountTooltip}
              thirdHeader={amountTooltipHeader2}
              secondHeader={amountTooltipHeader1}
            />
          }
          placement="top"
          enterDelay={700}
          leaveDelay={200}
        >
          <Typography className={classes.rowTextOverflow}>{amount}</Typography>
        </Tooltip>
      </TableCell>
      <TableCell className={classes.staticWidthTableCell}>{user}</TableCell>
      <TableCell className={classes.staticWidthTableCell}>{createdOn}</TableCell>
    </TableRow>
  )
})

type RowMetaData = {
  data: object
  scheduledType: string
}

const getRowDisplayMetaData = (row: RowMetaData) => {
  const displayNames: string[] = []
  const displayValues: string[] = []
  const amountLabels: object[] = []
  Object.entries(BATTERY_COMMAND_LIMIT_SETTING_DISPLAY_NAME_MAP).forEach(([commandSetting, displayData]) => {
    const scheduledValue = _.get(row, `data.${commandSetting}`)
    const { displayName, displayUnit } = displayData
    const isSocLabel = _.includes([SOC_MAX, SOC_MIN], commandSetting)
    const isThresholdLabel = _.includes([THRESHOLD], commandSetting)
    if (!_.isNil(scheduledValue)) {
      displayNames.push(displayName)
      let displayValue: string
      if (isSocLabel) {
        displayValue = `${numeral(scheduledValue * 100).format('0')}${displayUnit}`
      } else if (isThresholdLabel) {
        displayValue = `$${numeral(scheduledValue * 1000).format('0')}/${displayUnit}`
      } else {
        displayValue = `${convertkWToMW(scheduledValue)} ${displayUnit}`
      }
      displayValues.push(displayValue)
      amountLabels.push({
        label: displayName,
        newValue: displayValue,
      })
    }
  })
  const reason = _.get(row, 'data.rebid_reason')
  displayNames.push(_.get(row, 'scheduleType') === 'event' ? 'Once' : 'Recurring')
  if (!_.isNil(reason) && !_.isEmpty(reason)) {
    displayNames.push(reason)
  }
  const result = {
    'changeColDisplayNames': displayNames.join('; '),
    'amountColValues': displayValues.join('; '),
    amountLabels,
  }

  return result
}

export default ChangeLog
