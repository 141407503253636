import moment from 'moment-timezone'

export const {
  WEB_API_ROOT,
  AUTH_CLIENT_ID,
  AUTH_DOMAIN,
  AUTH_DATABASE_CONNECTION,
  MAPBOX_API_KEY,
  FEATURE_LOGIN_REDIRECT_ENABLED = false,
  AUTH_AUDIENCE,
} = window

export const LOGIN_REDIRECT_ENABLED =
  FEATURE_LOGIN_REDIRECT_ENABLED && `${FEATURE_LOGIN_REDIRECT_ENABLED}`.toLowerCase() === 'true'

// CONVERSIONS
export const KW_TO_MW = 1 / 1000

// DURATIONS
export const MINUTES_IN_DAY = 1440
export const MINUTES_IN_HOUR = 60
export const FIVE_MINUTE_DURATION = moment.duration(5, 'minute')
export const FIFTEEN_MINUTE_DURATION = moment.duration(15, 'minute')
export const ONE_HOUR_DURATION = moment.duration(1, 'hour')
export const ONE_MINUTE_DURATION = moment.duration(1, 'minute')
export const TRADING_DAY_START_HOUR = 4

// DATE TIME FORMATS
export const DATE_FORMAT_DAY_OF_WEEK = 'dddd'
export const DATE_FORMAT = 'YYYY-MM-DD HH:mm'
export const DATE_FORMAT_DAY = 'YYYY-MM-DD'
export const DATE_FORMAT_FULL_WITH_SEC = 'YYYY-MM-DD HH:mm:ss'
export const DATE_FORMAT_NO_SEPARATOR = 'YYYYMMDD'
export const DATE_FORMAT_NO_SEPARATOR_WITH_SECONDS = 'YYYYMMDDHHmmss'
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm'
export const DATETIME_FORMAT_WITH_SECONDS = 'YYYY-MM-DD HH:mm:ss'

export const TIME_FORMAT = 'HH:mm'
export const TIME_FORMAT_WITH_SEC = 'HH:mm:ss'

// TAGS
export const OPTIMIZATION_SETTINGS_TAG = 'optimization_settings'
export const SPIN_PRODUCT = 'spin'
export const NON_SPIN_PRODUCT = 'non_spin'
export const REGULATION_UP_PRODUCT = 'regulation_up'
export const REGULATION_DOWN_PRODUCT = 'regulation_down'

export const AWARD_TAG = 'award'
export const POWER_TAG_SUFFIX = '_kw'
export const STATE_OF_ENERGY_TAG_SUFFIX = '_kwh'

export const SETTLEMENT_TAG = 'settlement'
export const AMS_FORECAST_TAG = 'AMSPriceForecast'
export const PRE_DISPATCH_PRICE_TAG = 'PreDispatchPrice'
export const PRE_DISPATCH_DEMAND_TAG = 'PreDispatchDemand'

// Asset Data Tags
export const BID_FILE_SETTINGS_TAG = 'bid_file_settings'
export const BID_SPREAD_SETTING_TAG = 'bid_spreading_setting'
export const LGC_SETTING_TAG = 'lgc_setting'
export const FORECAST_SETTING_TAG = 'forecast_setting'
export const BID_STRATEGY_SETTING_TAG = 'bid_strategy_setting'
export const WARRANTY_CONSTRAINT_SETTING_TAG = 'warranty_constraint_setting'
export const DEGRADATION_COST_SETTING = 'degradation_cost_setting'
export const PASA_SETTING_TAG = 'pasa_setting'
export const CONFIGURATION_TAG = 'configuration'
export const MARGINAL_LOSS_FACTOR_SETTING = 'marginal_loss_factor_setting'
export const RISK_SETTING_TAG = 'risk_setting'
export const OPERATIONAL_CAPACITY_SETTING_TAG = 'operational_capacity_setting'

// Interval Data BEP Tags
export const BEP_SETTING_TAG = 'break_even_setting'
export const BEP_APPLIED_TAG = 'break_even_price_applied'
export const BEP_LOG_TYPE_TAG = 'log'
export const BEP_LAST_TYPE_TAG = 'last'
export const BEP_CURRENT_TYPE_TAG = 'current'

// Asset Product Data
export const THROUGHPUT_TAG = 'throughput'

// Contract Type Tags
export const PPA_SWAP_TAG = 'ppa_swap'
export const WARRANTY_TAG = 'warranty'

// asset market intervals tags
export const BATTERY_ACCUMULATED_THROUGHPUT_KWH_TAG = 'battery_accumulated_throughput_kwh'
export const BATTERY_DAILY_CYCLE_COUNT_TAG = 'battery_daily_cycle_count'
export const BATTERY_ANNUAL_CYCLE_COUNT_TAG = 'battery_annual_cycle_count'

// 4s telemetry
export const BATTERY_4SEC_TAGS = {
  LIVE: {
    POWER_TAG: 'customer_battery_kw_hf4',
    SOE_TAG: 'customer_battery_kwh_hf4',
    AVAILABLE_CAPACITY_TAG: 'customer_battery_available_capacity_kwh_hf4',
    AVAIL_CHARGE_TAG: 'customer_battery_available_charge_kw_hf4',
    AVAIL_DISCHARGE_TAG: 'customer_battery_available_discharge_kw_hf4',
    OVERRIDE_DISCHARGE_TAG: 'customer_battery_override_discharge_limit_kw_hf4',
    OVERRIDE_CHARGE_TAG: 'customer_battery_override_charge_limit_kw_hf4',
    MIN_ACTIVE_POWER_LIMIT_TAG: 'customer_battery_power_capacity_event_minimum_active_power_kw_hf4',
    MAX_ACTIVE_POWER_LIMIT_TAG: 'customer_battery_power_capacity_event_maximum_active_power_kw_hf4',
    CONSUMED_RESERVE_ENERGY_TAG: 'customer_battery_power_capacity_event_reserved_energy_consumed_kwh_hf4',
  },
  SIMULATED: {
    POWER_TAG: 'battery_kw',
    SOE_TAG: 'battery_kwh',
    AVAILABLE_CAPACITY_TAG: 'battery_available_capacity_kwh',
    AVAIL_CHARGE_TAG: 'battery_available_charge_kw',
    AVAIL_DISCHARGE_TAG: 'battery_available_discharge_kw',
    OVERRIDE_DISCHARGE_TAG: 'battery_override_discharge_limit_kw_hf4',
    OVERRIDE_CHARGE_TAG: 'battery_override_charge_limit_kw_hf4',
    MIN_ACTIVE_POWER_LIMIT_TAG: 'battery_power_capacity_event_minimum_active_power_kw_hf4',
    MAX_ACTIVE_POWER_LIMIT_TAG: 'battery_power_capacity_event_maximum_active_power_kw_hf4',
    CONSUMED_RESERVE_ENERGY_TAG: 'battery_power_capacity_event_reserved_energy_consumed_kwh_hf4',
  },
}

// expanded telemetry tags
export const EXPANDED_TELEMETRY_PREFIX = 'expandedTelemetryIntervals'
export const LIVE_CUSTOMER_BATTERY_AVAILABLE_DISCHARGE_KW_TAG = 'customer_battery_available_discharge_kw_hf4'
export const LIVE_CUSTOMER_BATTERY_AVAILABLE_CHARGE_KW_TAG = 'customer_battery_available_charge_kw_hf4'
export const LIVE_CUSTOMER_BATTERY_OVERRIDE_DISCHARGE_LIMIT_KW_TAG = 'customer_battery_override_discharge_limit_kw_hf4'
export const LIVE_CUSTOMER_BATTERY_OVERRIDE_CHARGE_LIMIT_KW_TAG = 'customer_battery_override_charge_limit_kw_hf4'
export const LIVE_CUSTOMER_POWER_CAPACITY_EVENT_MINIMUM_ACTIVE_POWER_KW_TAG =
  'customer_battery_power_capacity_event_minimum_active_power_kw_hf4'
export const LIVE_CUSTOMER_POWER_CAPACITY_EVENT_MAXIMUM_ACTIVE_POWER_KW_TAG =
  'customer_battery_power_capacity_event_maximum_active_power_kw_hf4'

export const CONSUMED_RESERVE_ENERGY = 'customer_battery_power_capacity_event_reserved_energy_consumed_kwh_hf4'

export const EXPANDED_TELEMETRY_TAGS = [
  LIVE_CUSTOMER_BATTERY_AVAILABLE_DISCHARGE_KW_TAG,
  LIVE_CUSTOMER_BATTERY_AVAILABLE_CHARGE_KW_TAG,
  LIVE_CUSTOMER_BATTERY_OVERRIDE_DISCHARGE_LIMIT_KW_TAG,
  LIVE_CUSTOMER_BATTERY_OVERRIDE_CHARGE_LIMIT_KW_TAG,
  LIVE_CUSTOMER_POWER_CAPACITY_EVENT_MINIMUM_ACTIVE_POWER_KW_TAG,
  LIVE_CUSTOMER_POWER_CAPACITY_EVENT_MAXIMUM_ACTIVE_POWER_KW_TAG,
]

export const BID_COLLATION_INTERVALS = {
  SETTLEMENT_INTERVAL: 'SettlementInterval',
  BID_INTERVAL: 'BidInterval',
}

export const BID_STRATEGIES = {
  PRICE_BAND_1: 'bid_at_floor',
  OPPORTUNITY_PRICE: 'bid_at_opportunity_price',
  BREAK_EVEN_PRICE: 'bid_at_break_even_price',
}

export const BID_STRATEGY_DISPLAY_NAMES = {
  [BID_STRATEGIES.PRICE_BAND_1]: 'Price Band 1',
  [BID_STRATEGIES.OPPORTUNITY_PRICE]: 'Opportunity Price',
  [BID_STRATEGIES.BREAK_EVEN_PRICE]: 'Break Even Price',
}

// TRADE SETTING DAY OF THE WEEK CONVERSIONS
export const DAYS_OF_THE_WEEK_SHORT_ABBR = {
  0: 'Su',
  1: 'M',
  2: 'T',
  3: 'W',
  4: 'Th',
  5: 'F',
  6: 'S',
}

export const DAYS_OF_THE_WEEK = [
  { text: 'S', key: 'sunday' },
  { text: 'M', key: 'monday' },
  { text: 'T', key: 'tuesday' },
  { text: 'W', key: 'wednesday' },
  { text: 'T', key: 'thursday' },
  { text: 'F', key: 'friday' },
  { text: 'S', key: 'saturday' },
]

export const DAYS_OF_THE_WEEK_NUMBER = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
}

// Copied from generate_data.py
export const DEFAULT_BID_SPREADING = {
  'lower_quantile': 0.3,
  'median_quantile': 0.5,
  'upper_quantile': 0.7,
  'price_forecast_error_per_kwh': 0.01,
}

// Asset Forecast Products Horizon constants
export const HORIZONS = ['5m', '30m', '1h', '3h', '12h', '24h']
export const HORIZON_DURATIONS = [5 - 5, 30 - 5, 60 - 5, 180 - 5, 720 - 5, 1440 - 5]

// See Market Results table for example of this scroll offset
export const EXTRA_SCROLL_OFFSET = 160

export const MARKET_PRICE_ENERGY_MINIMUM = -1000
export const MARKET_PRICE_FCAS_MINIMUM = 0
export const MARKET_PRICE_CHART_INTERVAL = 10

export const INTERVAL_SIZE_MINS = 5
export const NUM_FIVE_MIN_INTERVALS_PER_DAY = 288
export const NUM_THIRTY_MIN_INTERVALS_PER_DAY = 48
export const ZERO = 0

// USED BY LEGACY BIDS (pre 5 minute settlements)
export const SETTLEMENT_INTERVAL_MINS = 30

export const ASSET_TYPE = {
  battery: 'battery_ifom',
  batteryNonScheduled: 'battery_non_scheduled',
  renewable: 'renewable_ifom',
}

// We want these names to be dynamic but in cases like raisereg throughput we need
// to be able to start with these product names as there is business logic associated with it in the application itself.
export const PRODUCT_NAMES = {
  ENERGY: 'ENERGY',
  LOWER5MIN: 'LOWER5MIN',
  LOWER60SEC: 'LOWER60SEC',
  LOWER6SEC: 'LOWER6SEC',
  LOWERREG: 'LOWERREG',
  RAISE5MIN: 'RAISE5MIN',
  RAISE60SEC: 'RAISE60SEC',
  RAISE6SEC: 'RAISE6SEC',
  RAISEREG: 'RAISEREG',
}
export const FFR_PRODUCT_NAMES = {
  RAISE1SEC: 'RAISE1SEC',
  LOWER1SEC: 'LOWER1SEC',
}
export const PRODUCT_TYPES = {
  GEN: 'GEN',
  LOAD: 'LOAD',
}
export const PRODUCT_GROUP_NAMES = {
  ENERGY: 'ENERGY',
  RAISE_FCAS: 'RAISE FCAS',
  LOWER_FCAS: 'LOWER FCAS',
}

export const PRODUCT_TIME_LABEL_TO_SEC = {
  'MIN': 60,
  'SEC': 1,
  'REG': 0,
}

export const ENERGY_PRODUCTS = [PRODUCT_NAMES.ENERGY]
export const FCAS_REGULATION_PRODUCTS = [PRODUCT_NAMES.LOWERREG, PRODUCT_NAMES.RAISEREG]

export const RAISE_FCAS_CONTINGENCY_PRODUCT_NAMES = new Set([
  PRODUCT_NAMES.RAISE5MIN,
  PRODUCT_NAMES.RAISE60SEC,
  PRODUCT_NAMES.RAISE6SEC,
  FFR_PRODUCT_NAMES.RAISE1SEC,
])

export const FCAS_CONTINGENCY_PRODUCT_NAME = 'FCAS Contingency'
export const LOWER_FCAS_CONTINGENCY_PRODUCT_NAMES = new Set([
  PRODUCT_NAMES.LOWER5MIN,
  PRODUCT_NAMES.LOWER60SEC,
  PRODUCT_NAMES.LOWER6SEC,
  FFR_PRODUCT_NAMES.LOWER1SEC,
])

// Non-Scheduled BESS Asset
export const FCAS_REGULATION_OR_ENERGY_PRODUCT_NAMES = new Set(ENERGY_PRODUCTS.concat(FCAS_REGULATION_PRODUCTS))
export const FCAS_CONTINGENCY_PRODUCT_NAMES = new Set([
  ...RAISE_FCAS_CONTINGENCY_PRODUCT_NAMES,
  ...LOWER_FCAS_CONTINGENCY_PRODUCT_NAMES,
])

export const BATTERY_NON_SCHEDULED_ASSET_TYPE = ASSET_TYPE.batteryNonScheduled
export const BATTERY_SCHEDULED_ASSET_TYPE = ASSET_TYPE.battery

export const BATTERY_ASSET_TYPE = 'battery'
export const SOLAR_ASSET_TYPE = 'solar'
export const WIND_ASSET_TYPE = 'wind'
export const HYDRO_ASSET_TYPE = 'hydro'
export const RENEWABLE_ASSETS = [SOLAR_ASSET_TYPE, WIND_ASSET_TYPE, HYDRO_ASSET_TYPE]

// Asset Scheduled Battery Command Limit Settings Data Tags
export const EXACT_CHARGE_POWER = 'charge_kw'
export const EXACT_DISCHARGE_POWER = 'discharge_kw'
export const EXACT_CHARGE_ENERGY = 'charge_kwh'
export const EXACT_DISCHARGE_ENERGY = 'discharge_kwh'
export const CHARGE_LIMIT = 'charge_limit_kw'
export const DISCHARGE_LIMIT = 'discharge_limit_kw'
export const POWER_MIN_CHARGE = 'charge_min_kw'
export const POWER_MIN_DISCHARGE = 'discharge_min_kw'
export const SOC_MAX = 'soc_max'
export const SOC_MIN = 'soc_min'
export const SOE_MAX = 'soe_max_kwh'
export const SOE_MIN = 'soe_min_kwh'
export const THRESHOLD = 'commercial_price_per_kwh'
export const BATTERY_COMMAND_LIMIT_SETTING_DISPLAY_NAME_MAP = {
  [CHARGE_LIMIT]: { displayName: 'Charge Limit', displayUnit: 'MW' },
  [DISCHARGE_LIMIT]: { displayName: 'Discharge Limit', displayUnit: 'MW' },
  [EXACT_CHARGE_ENERGY]: { displayName: 'Exact NET Energy Charge', displayUnit: 'MWh' },
  [EXACT_CHARGE_POWER]: { displayName: 'Exact Charge Power', displayUnit: 'MW' },
  [EXACT_DISCHARGE_ENERGY]: { displayName: 'Exact NET Energy Discharge', displayUnit: 'MWh' },
  [EXACT_DISCHARGE_POWER]: { displayName: 'Exact Discharge Power', displayUnit: 'MW' },
  [POWER_MIN_CHARGE]: { displayName: 'Min Charge Power', displayUnit: 'MW' },
  [POWER_MIN_DISCHARGE]: { displayName: 'Min Discharge Power', displayUnit: 'MW' },
  [SOC_MIN]: { displayName: 'Min SoC', displayUnit: '%' },
  [SOC_MAX]: { displayName: 'Max SoC', displayUnit: '%' },
  [SOE_MIN]: { displayName: 'Min SoE', displayUnit: 'MWh' },
  [SOE_MAX]: { displayName: 'Max SoE', displayUnit: 'MWh' },
  [THRESHOLD]: { displayName: 'Commercial Value', displayUnit: 'MWh' },
}

// Asset Scheduled Data
export const BATTERY_COMMAND_SETTING_TAG = 'battery_command_setting'
export const COMMERCIAL_BATTERY_COMMAND_SETTING_TAG = 'commercial_battery_command_setting'

// This should be dynamic, but left here in case we need this when application starts
export const OPERATIONAL_CAPACITY_PRODUCTS = {
  ENERGY_GEN: 'ENERGY_GEN',
  ENERGY_LOAD: 'ENERGY_LOAD',
  LOWERREG_GEN: 'LOWERREG_GEN',
  RAISEREG_GEN: 'RAISEREG_GEN',
  LOWER5MIN_GEN: 'LOWER5MIN_GEN',
  LOWER6SEC_GEN: 'LOWER6SEC_GEN',
  LOWERREG_LOAD: 'LOWERREG_LOAD',
  RAISE5MIN_GEN: 'RAISE5MIN_GEN',
  RAISE6SEC_GEN: 'RAISE6SEC_GEN',
  RAISEREG_LOAD: 'RAISEREG_LOAD',
  LOWER5MIN_LOAD: 'LOWER5MIN_LOAD',
  LOWER60SEC_GEN: 'LOWER60SEC_GEN',
  LOWER6SEC_LOAD: 'LOWER6SEC_LOAD',
  RAISE5MIN_LOAD: 'RAISE5MIN_LOAD',
  RAISE60SEC_GEN: 'RAISE60SEC_GEN',
  RAISE6SEC_LOAD: 'RAISE6SEC_LOAD',
  LOWER60SEC_LOAD: 'LOWER60SEC_LOAD',
  RAISE60SEC_LOAD: 'RAISE60SEC_LOAD',
}

export const GENERIC_PASA_NAME = 'PASA'

export const PASA_SETTINGS = {
  PASA_GEN: 'PASA_GEN',
  PASA_LOAD: 'PASA_LOAD',
}

// User Management
export const MARKET_LEVEL = 'MARKET'
export const CUSTOMER_LEVEL = 'CUSTOMER'
export const ASSET_LEVEL = 'ASSET'
export const CAN_DO_ANYTHING_PERMISSION = 'IsInternalAdmin'
export const CAN_ADMINISTER_USERS = 'CanAdministerUsers'
export const CAN_ADJUST_RISK_APPETITE = 'CanAdjustRiskAppetite'
export const CAN_SUBMIT_MANUAL_BIDS = 'CanSubmitManualBids'
export const CAN_ADJUST_PRICE_BANDS = 'CanAdjustPriceBands'
export const CAN_PAUSE_BID_DELIVERY = 'CanPauseBidDelivery'
export const CAN_ACCESS_UI = 'canAccessUi'
export const HAS_MFA = 'hasMfa'
export const CAN_ACCESS_API = 'CanAccessAPI'

export const CAN_ONLY_PAUSE_BID_DELIVERY = 'canOnlyPauseBidDelivery'
export const CAN_ACCESS_ASSET = 'CanAccessAsset'

export const AEMO_MARKET = 'AEMO'
