import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment-timezone'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Table, TableHead, TableBody, TableCell, TableRow, Typography, IconButton } from '@material-ui/core'

import { Info, Button, useNotifier } from '@fluence/core'
import { batteryCommandSettingResource, batteryCommandSettingEntriesSelector, commericalBatteryCommandSettingResource, commercialBatteryCommandSettingEntriesSelector } from '../../redux/features/setting'
import { DATE_FORMAT_DAY, SOE_MAX, SOE_MIN } from '../../utility/constants'
import { formatTimeAsTradingDate, getTradingStart, convertPeriodTimeToCalendarDateTime } from '../../utility/utility'
import { API } from '../../redux/api'
import Card from '../Card'
import KeyboardDatePicker from '../KeyboardDatePicker'
import { getMarketStartGivenTimestamp } from '../../utility/time-utils'
import ScheduledChangesInfo from './ScheduledChangesInfo'
import ScheduledChangesRow from './ScheduledChangesRow'

const DISPLAY_NAME = 'ScheduledChangesCard'

const useStyles = makeStyles(theme => ({
  card: {
    background: _.get(theme, 'palette.background.paper', '#1D2126'),
    height: '100%',
  },
  cardContent: {
    minHeight: 180,
    maxHeight: 250,
    overflowY: 'scroll',
  },
  cardHeader: {
    borderBottom: `2px solid ${_.get(theme, 'palette.background.default', '#2f353d')}`,
    flexWrap: 'wrap',
  },
  cardHeaderContent: {},
  cardTitle: {},
  cardAction: {
    padding: theme.spacing(2, 0, 1, 0),
  },
  table: {
    '& td': {
      borderBottom: '1px solid  #30353c',
      padding: 4,
    },
    '& td:last-child': {
      padding: 0,
    },
    '& th': {
      padding: 4,
      borderBottom: '1px solid  #30353c',
      textTransform: 'uppercase',
      backgroundColor: theme.palette.background.paper,
    },
    '& th:last-child': {
      padding: 0,
    },
  },
  panelBody: {
    padding: 2,
    overflow: 'auto',
    maxHeight: 570,
  },
}))

const headers = [
  { name: 'Interval', key: 'interval' },
  { name: 'Type', key: 'type' },
  { name: 'Exact Charge', key: 'charge_power' },
  { name: 'Exact Discharge', key: 'discharge_power' },
  { name: 'Minimum Charge', key: 'charge_min_power' },
  { name: 'Minimum Discharge', key: 'discharge_min_power' },
  { name: 'Charge Limit', key: 'charge_limit' },
  { name: 'Discharge Limit', key: 'discharge_limit' },
  { name: 'Min Soc', key: 'soc_min' },
  { name: 'Max SoC', key: 'soc_max' },
  { name: 'Min SoE', key: [SOE_MIN] },
  { name: 'Max SoE', key: [SOE_MAX] },
  { name: ' ● ', key: 'rebid_reason' },
  { name: 'Commercial Value', key: 'threshold' },
  { name: 'Frequency', key: 'frequencyOfChange' },
  { name: 'Created', key: 'created' },
  { name: '', key: 'delete' },
]

const TableHeadRow = () => (
  <TableRow>
    {headers.map(header => (
      <TableCell key={header.key}>{header.name}</TableCell>
    ))}
  </TableRow>
)

const ScheduledChangesCard = props => {
  const classes = useStyles(props)
  const { asset, dispatchDeleteSchedule, getBatteryCommandSetting, batteryCommandSettingEntries, isLoading, getCommericalBatteryCommandSetting, commercialBatteryCommandSettingEntries } = props
  const { notifySuccess, notifyError } = useNotifier()

  const assetId = _.get(asset, 'assetId')
  const commercialOnlineDate = _.get(asset, 'data.commercial_online_date')
  const market = _.get(asset, 'market')
  const marketTimezone = _.get(market, 'data.timezone')
  const marketStartHour = _.get(market, 'data.trading_day_start_hour')
  moment.tz.setDefault(marketTimezone)

  // State
  const [scheduledChangesInfoOpen, setScheduledChangesInfoOpen] = useState(false)
  const [selectedTime, setSelectedTime] = useState({
    start: null,
    end: null,
  })
  const [scheduledChangesEntries, setScheduledChangesEntries] = useState([])
  const [entriesMap, setEntriesMap] = useState({})
  const [scheduledDates, setScheduledDates] = useState([])

  // useEffects
  useEffect(() => {
    if (!_.isNil(market) && _.isNil(selectedTime.start)) {
      setSelectedTime({
        start: getTradingStart(market),
        end: getTradingStart(market).add(1, 'days'),
      })
    }
  }, [market, selectedTime, selectedTime.start])

  useEffect(() => {
    setScheduledChangesInfoOpen(false)
    setSelectedTime({
      start: getTradingStart(market),
      end: getTradingStart(market).add(1, 'days'),
    })
  }, [assetId, market])

  useEffect(() => {
    if (!_.isNil(assetId) && !_.isNil(selectedTime.start)) {
      const futureDate = _.max(moment(selectedTime.start).add(1, 'years'), moment().add(1, 'years'))
      getBatteryCommandSetting(assetId, commercialOnlineDate, futureDate)
      getCommericalBatteryCommandSetting(assetId, commercialOnlineDate, futureDate)
    }
  }, [assetId, commercialOnlineDate, getBatteryCommandSetting, getCommericalBatteryCommandSetting, selectedTime.start])

  useEffect(() => {
    if (!_.isNil(batteryCommandSettingEntries) && !_.isNil(commercialBatteryCommandSettingEntries)) {
      const entriesByDate = {}
      const totalCommandSettingEntries = [...batteryCommandSettingEntries, ...commercialBatteryCommandSettingEntries]
      totalCommandSettingEntries.forEach(entry => {
        entry.startTime = moment(entry.startTime).tz(marketTimezone).format()
        entry.endTime = moment(entry.endTime).tz(marketTimezone).format()
        const scheduleType = _.get(entry, 'scheduleType')
        const startTime = moment(_.get(entry, 'startTime'))
        const endTime = moment(_.get(entry, 'endTime'))
        if (scheduleType === 'event') {
          const activeDate = formatTimeAsTradingDate(startTime, market, DATE_FORMAT_DAY)
          if (_.isNil(entriesByDate[activeDate])) {
            entriesByDate[activeDate] = []
          }
          entriesByDate[activeDate].push(entry)
        } else if (scheduleType === 'weekly') {
          entry.schedule.periods.forEach(period => {
            const fromCalendarDateTime = convertPeriodTimeToCalendarDateTime(
              startTime,
              period.fromDayOfWeek,
              period.fromHour,
              period.fromMinute,
            )
            const toCalendarDateTime = convertPeriodTimeToCalendarDateTime(
              endTime,
              period.toDayOfWeek,
              period.toHour,
              period.toMinute,
            )
            if (!_.isNil(fromCalendarDateTime) && !_.isNil(toCalendarDateTime)) {
              let iterDay = fromCalendarDateTime
              while (
                iterDay.isBefore(endTime) &&
                iterDay.isSameOrAfter(moment(selectedTime.start).subtract(1, 'year')) &&
                iterDay.isBefore(moment(selectedTime.start).add(1, 'year'))
              ) {
                if (
                  iterDay.isBetween(
                    getMarketStartGivenTimestamp(startTime, marketStartHour, marketTimezone),
                    endTime,
                    undefined,
                    '[]',
                  )
                ) {
                  const activeDate = formatTimeAsTradingDate(iterDay, market, DATE_FORMAT_DAY)
                  if (_.isNil(entriesByDate[activeDate])) {
                    entriesByDate[activeDate] = []
                  }
                  entriesByDate[activeDate].push(entry)
                }
                iterDay = iterDay.add(1, 'week')
              }
            }
          })
        }
      })
      setEntriesMap(entriesByDate)
      setScheduledDates(Object.keys(entriesByDate))
    }
  }, [batteryCommandSettingEntries, commercialBatteryCommandSettingEntries, market, marketStartHour, marketTimezone, selectedTime.start])

  useEffect(() => {
    if (!_.isNil(entriesMap) && !_.isNil(selectedTime.start)) {
      const entries = entriesMap[moment(selectedTime.start).format(DATE_FORMAT_DAY)] || []
      setScheduledChangesEntries(entries.sort((a, b) => moment(b.createdOn) - moment(a.createdOn)))
    }
  }, [batteryCommandSettingEntries, commercialBatteryCommandSettingEntries, selectedTime.start, selectedTime.end, entriesMap])

  // Event Handlers
  const handleChangeDatePicker = date => {
    const newDate = moment(date).tz(marketTimezone).startOf('day').add(marketStartHour, 'hours')
    setSelectedTime({
      start: newDate,
      end: moment(newDate).endOf('day').add(marketStartHour, 'hours'),
    })
  }

  const handleSnapToToday = () => {
    setSelectedTime({
      start: getTradingStart(market),
      end: getTradingStart(market).add(1, 'days'),
    })
  }

  const handleDeleteScheduledChange = async assetScheduledDataId => {
    const response = await dispatchDeleteSchedule(assetScheduledDataId)
    if (response.error) {
      notifyError('Unable to delete scheduled change entry')
    } else {
      notifySuccess('Scheduled change deleted')
      getBatteryCommandSetting(assetId, commercialOnlineDate)
      getCommericalBatteryCommandSetting(assetId, commercialOnlineDate)
    }
  }

  const disableSnapToToday = getMarketStartGivenTimestamp(moment(), marketStartHour).isSame(
    moment(selectedTime.start),
    'date',
  )
  const title = 'Scheduled Changes'
  return (
    <>
      <Card
        classes={{
          root: classes.card,
          content: classes.cardContent,
          header: classes.cardHeader,
          headerContent: classes.cardHeaderContent,
          title: classes.cardTitle,
          action: classes.cardAction,
        }}
        title={
          <Box display="inline-flex" alignItems="center">
            <Box display="flex" flex="0 1 auto" mr={2}>
              <Typography variant="h3">{title}</Typography>
            </Box>
            <Box display="flex" flex="0 1 auto">
              <IconButton
                aria-label="Scheduled Changes information"
                size="small"
                onClick={() => setScheduledChangesInfoOpen(true)}
              >
                <Info color="primary" fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        }
        inProgress={isLoading}
        action={
          <>
            <Button variant="text" onClick={handleSnapToToday} disabled={disableSnapToToday}>
              snap to today
            </Button>
            <KeyboardDatePicker
              id="scheduled-modal-start-time"
              timezone={marketTimezone}
              marketStartHour={marketStartHour}
              selectedDate={selectedTime.start}
              onChange={handleChangeDatePicker}
              ariaLabel="change market trading date"
              renderDayProps={{ daysWithDots: scheduledDates }}
            />
          </>
        }
      >
        <Box className={classes.panelBody}>
          {scheduledChangesEntries.length <= 0 ? (
            <Typography>No changes scheduled</Typography>
          ) : (
            <Table stickyHeader size="small" className={classes.table}>
              <TableHead>
                <TableHeadRow />
              </TableHead>
              <TableBody>
                {scheduledChangesEntries.map((row, i) => (
                  <ScheduledChangesRow
                    key={i}
                    row={row}
                    market={market}
                    selectedTime={selectedTime}
                    handleDeleteScheduledChange={handleDeleteScheduledChange}
                  />
                ))}
              </TableBody>
            </Table>
          )}
        </Box>
      </Card>
      <ScheduledChangesInfo open={scheduledChangesInfoOpen} onClose={() => setScheduledChangesInfoOpen(false)} />
    </>
  )
}

ScheduledChangesCard.displayName = DISPLAY_NAME

const mapStateToProps = state => {
  const batteryCommandSetting = _.get(state, 'setting.batteryCommandSetting', [])
  const batteryCommandSettingEntries = batteryCommandSettingEntriesSelector(state).sort(
    (x, y) => moment(y.startTime) - moment(x.startTime),
  )
  const commercialBatteryCommandSettingEntries = commercialBatteryCommandSettingEntriesSelector(state).sort(
    (x, y) => moment(y.startTime) - moment(x.startTime),
  )
  return {
    isLoading: _.get(batteryCommandSetting, 'isLoading', true),
    batteryCommandSettingEntries: batteryCommandSettingEntries,
    commercialBatteryCommandSettingEntries: commercialBatteryCommandSettingEntries
  }

}

const mapDispatchToProps = dispatch => {
  return {
    dispatchDeleteSchedule: async assetScheduledDataId => {
      const action = API.bess.deleteSchedule(assetScheduledDataId)
      return await dispatch(action)
    },
    getBatteryCommandSetting: (assetId, commercialOnlineDate, futureDate) => {
      const startTime = moment(commercialOnlineDate)
      futureDate = futureDate || moment().add(1, 'years')
      return dispatch(batteryCommandSettingResource.get(assetId, startTime, futureDate))
    },
    getCommericalBatteryCommandSetting: (assetId, commercialOnlineDate, futureDate) => {
      const startTime = moment(commercialOnlineDate)
      futureDate = futureDate || moment().add(1, 'years')
      return dispatch(commericalBatteryCommandSettingResource.get(assetId, startTime, futureDate))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledChangesCard)
