import React, { useEffect, useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import clsx from 'clsx'
import moment from 'moment-timezone'
import { makeStyles } from '@material-ui/core/styles'
import { Box, MenuItem, TextField, Typography } from '@material-ui/core'
import {
  createIntervals,
  getMarketStartGivenTimestamp,
  getNextIntervalChange,
  getNextIntervalTime,
} from '../utility/time-utils'
import { INTERVAL_SIZE_MINS } from '../utility/constants'
import { usePrevious } from '../utility/hooks'
import KeyboardDatePicker from './KeyboardDatePicker'

const DISPLAY_NAME = 'IntervalRangePicker'

const generateInitialFormState = ({ startDate }) => ({
  values: {
    startDate,
    startTime: '',
    endTime: '',
  },
  touched: {
    startTime: false,
    endTime: false,
  },
})

const TYPES = {
  UPDATE: 'UPDATE_FIELD',
  UPDATE_NEW_INTERVAL: 'UPDATE_NEW_INTERVAL',
}

const formReducer = (state, action) => {
  switch (action.type) {
    case TYPES.UPDATE: {
      const newValues = { ...state.values }
      for (const update of action.payload) {
        newValues[update.field] = update.value
      }
      const newState = { ...state, values: newValues }
      return newState
    }
    case TYPES.UPDATE_NEW_INTERVAL: {
      const ONE_HOUR = moment.duration(1, 'hours')
      const { intervalOptions } = action.payload
      const first = _.get(_.first(intervalOptions), 'value', null)
      const last = _.get(_.last(intervalOptions), 'value', null)

      const { values: prevValues } = state

      const prevStartTime = _.get(prevValues, 'startTime')
      let startTime
      if (_.isNumber(prevStartTime) && prevStartTime >= first && prevStartTime < last) {
        startTime = prevStartTime
      } else {
        startTime = first
      }

      const prevEndTime = _.get(prevValues, 'endTime')
      const duration =
        _.isNumber(prevStartTime) && _.isNumber(prevEndTime) ? prevEndTime - prevStartTime : ONE_HOUR.asMilliseconds()
      const endTime = Math.min(startTime + duration, last)

      const newState = { ...state, values: { ...state.values, startTime, endTime } }

      const newStartDate = getMarketStartGivenTimestamp(moment(startTime), 4)
      if (moment(newStartDate).isAfter(moment(prevValues.startDate))) {
        newState.values.startDate = newStartDate
      }

      return newState
    }
    default:
      return state
  }
}

const useStyles = makeStyles(
  theme => ({
    root: {},
    gridContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(4),
    },
    gridItem: {},
    textField: {
      minWidth: theme.spacing(14),
      marginTop: 0,
    },
    noWrap: {
      flexWrap: 'nowrap',
    },
    gridGap: {
      gap: theme.spacing(4),
    },
    startTime: {},
    endTime: {},
    inputLabel: {
      paddingTop: 2,
      paddingBottom: 4,
      ...theme.typography.inputLabel,
    },
  }),
  { name: DISPLAY_NAME },
)

const IntervalRangePicker = React.forwardRef(function IntervalRangePicker(props, ref) {
  const classes = useStyles(props)
  const {
    className: classNameProp,
    datePickerLabel = 'START DATE',
    defaultIntervalRange = 12,
    endTimeLabel: endTimeLabelProp,
    detectTimeInputError,
    initialStartDate: proposedStartDate = moment().valueOf(),
    onChange,
    intervalCutoffSec = 120,
    marketStartHour,
    onDatePickerError,
    startTimeLabel: startTimeLabelProp,
    timezone,
    marketTimezoneDisplayName,
  } = props
  moment.tz.setDefault(timezone)
  const timeNow = moment()

  const intervalCutoffDuration = moment.duration(intervalCutoffSec, 'seconds')

  const minutesBeforeEod = INTERVAL_SIZE_MINS - intervalCutoffDuration.asMinutes() + INTERVAL_SIZE_MINS

  // The initial start date is either the next valid interval or the first interval of a future day (whichever is bigger)
  const initialStartDate = Math.max(
    moment(proposedStartDate).add(minutesBeforeEod, 'minutes').valueOf(),
    moment(timeNow).add(minutesBeforeEod, 'minutes').valueOf(),
  )

  const initialMarketStart = getMarketStartGivenTimestamp(moment(initialStartDate), marketStartHour)

  const [form, dispatch] = useReducer(formReducer, { startDate: moment(initialMarketStart) }, generateInitialFormState)

  const nextIntervalTime = getNextIntervalTime(moment(timeNow), intervalCutoffDuration.asSeconds()).valueOf()
  const nextIntervalChange = getNextIntervalChange(moment(timeNow), intervalCutoffDuration.asSeconds())
  const nowMarketStartHour = getMarketStartGivenTimestamp(nextIntervalTime, marketStartHour)
  const startDateIsCurrentTradingDay = moment(form.values.startDate).isSameOrBefore(nowMarketStartHour)
  const nextIntervalTimeTimestamp = moment(nextIntervalTime).valueOf()
  const startDateTimestamp = moment(form.values.startDate).valueOf()

  const [intervalOptions, setIntervalOptions] = useState(() => {
    const start = startDateIsCurrentTradingDay ? nextIntervalTimeTimestamp : moment(initialMarketStart).valueOf()
    const initialIntervalOptions = createIntervals(start, moment(startDateTimestamp), marketStartHour)
    return initialIntervalOptions
  })

  const startIntervalOptions = intervalOptions.slice(0, -1)
  const startIndex = startIntervalOptions.findIndex(opt => opt.value === form.values.startTime)
  const endIntervalOptions = intervalOptions.slice(startIndex + 1)

  // TODO: make minDate configurable
  const minDate = moment(nowMarketStartHour)
  const maxDate = moment(minDate).add(1, 'years')

  /**
   * TL;DR; This function will force the component to rerender on a time interval.
   *
   * The rerender is needed to force our interval list to update and invalidate old intervals in near real time.
   */
  const nextIntervalChangeTimestamp = nextIntervalChange.valueOf()
  const [, setLastIntervalUpdate] = useState()
  useEffect(() => {
    if (startDateIsCurrentTradingDay && !_.isNil(timezone)) {
      // nextIntervalChange changes every five minutes right after end of interval (endOfInterval from parent component triggers rerender)
      const waitTime = nextIntervalChangeTimestamp - moment().valueOf() // milliseconds until next available interval
      const timeoutHandle = setTimeout(() => {
        setLastIntervalUpdate(moment())
      }, waitTime)
      return () => {
        clearTimeout(timeoutHandle)
      }
    }
  }, [timezone, nextIntervalChangeTimestamp, startDateIsCurrentTradingDay])

  const prevProposedStartDate = usePrevious(proposedStartDate)
  const prevForm = usePrevious(form)
  useEffect(() => {
    const isTimeInputValid = moment(form.values.startTime).isValid() && moment(form.values.endTime).isValid()
    if (_.isFunction(detectTimeInputError)) {
      detectTimeInputError(!isTimeInputValid)
    }
    if (_.isFunction(onChange) && prevForm && isTimeInputValid) {
      if (
        form.values.startTime !== prevForm.values.startTime ||
        form.values.endTime !== prevForm.values.endTime ||
        proposedStartDate !== prevProposedStartDate
      ) {
        onChange(form.values.startTime, form.values.endTime)
      }
    }
  }, [form, proposedStartDate, onChange, prevForm, prevProposedStartDate, detectTimeInputError])

  // Check if intervals need to be updated
  useEffect(() => {
    setIntervalOptions(() => {
      // EDGE CASE: When time is during EOD buffer we need to skip ahead to the next day. This will do the trick but it is not obvious that it will.
      const nextInterval = startDateIsCurrentTradingDay ? nextIntervalTimeTimestamp : startDateTimestamp
      const tradingDay = startDateIsCurrentTradingDay
        ? getMarketStartGivenTimestamp(nextIntervalTimeTimestamp, marketStartHour)
        : getMarketStartGivenTimestamp(startDateTimestamp, marketStartHour)
      const intervalOptions = createIntervals(nextInterval, tradingDay, marketStartHour)

      // Sync start date with intervals. Especially important when transitioning to next trade day.
      const type = TYPES.UPDATE
      const payload = [
        {
          field: 'startDate',
          value: moment(tradingDay),
        },
      ]
      dispatch({
        type,
        payload,
      })

      dispatch({
        type: TYPES.UPDATE_NEW_INTERVAL,
        payload: {
          intervalOptions,
        },
      })

      return intervalOptions
    })
  }, [marketStartHour, nextIntervalTimeTimestamp, startDateIsCurrentTradingDay, startDateTimestamp])

  // initialStartDate Changed
  useEffect(() => {
    if (prevProposedStartDate !== proposedStartDate) {
      const startTime = _.get(_.first(intervalOptions), 'value')
      const last = _.get(_.last(intervalOptions), 'value')
      const endTime = Math.min(
        moment(startTime)
          .add(defaultIntervalRange * INTERVAL_SIZE_MINS, 'minutes')
          .valueOf(),
        last,
      )
      const type = TYPES.UPDATE
      const payload = [
        { field: 'startDate', value: moment(initialMarketStart) },
        { field: 'startTime', value: startTime },
        { field: 'endTime', value: endTime },
      ]
      dispatch({
        type,
        payload,
      })
    }
  }, [defaultIntervalRange, proposedStartDate, initialMarketStart, intervalOptions, prevProposedStartDate])

  const handleDateChange = date => {
    const type = TYPES.UPDATE
    const payload = [{ field: 'startDate', value: date }]
    dispatch({
      type,
      payload,
    })
  }

  const handleSelectChange = event => {
    const value = _.get(event, 'target.value')
    const name = _.get(event, 'target.name')
    const payload = [{ field: name, value }]

    if (name === 'startTime' && value >= form.values.endTime) {
      const newEndTime = moment(value).add(INTERVAL_SIZE_MINS, 'minutes').valueOf()
      payload.push({ field: 'endTime', value: newEndTime })
    }
    dispatch({
      type: TYPES.UPDATE,
      payload,
    })
  }

  const startTimeLabel =
    startTimeLabelProp || `START TIME ${marketTimezoneDisplayName ? `(${marketTimezoneDisplayName})` : ''}`
  const endTimeLabel =
    endTimeLabelProp || `END TIME ${marketTimezoneDisplayName ? `(${marketTimezoneDisplayName})` : ''}`
  return (
    <>
      <div className={clsx(classes.gridContainer, classes.root, classNameProp)}>
        <div className={classes.gridItem}>
          <Box>
            <KeyboardDatePicker
              id="schedule-modal-start-date"
              disabled={false}
              timezone={timezone}
              marketStartHour={marketStartHour}
              inputVariant="standard"
              label={datePickerLabel}
              ariaLabel="change start date"
              minDate={minDate}
              maxDate={maxDate}
              onError={onDatePickerError}
              selectedDate={form.values.startDate}
              onChange={handleDateChange}
            />
          </Box>
        </div>
        <Box display="flex" flexWrap="nowrap" className={clsx(classes.noWrap, classes.gridGap)}>
          <div className={classes.gridItem}>
            <Box>
              <Typography className={classes.inputLabel} color="textSecondary">
                {startTimeLabel}
              </Typography>
              <TextField
                id="new-scheduled-start-time"
                className={clsx(classes.textField, classes.startTime)}
                disabled={false}
                required
                select
                name="startTime"
                value={_.get(form, 'values.startTime', '') || ''}
                onChange={handleSelectChange}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal"
              >
                {startIntervalOptions.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </div>
          <div className={classes.gridItem}>
            <Box>
              <Typography className={classes.inputLabel} color="textSecondary">
                {endTimeLabel}
              </Typography>
              <TextField
                id="new-scheduled-end-time"
                className={clsx(classes.textField, classes.endTime)}
                disabled={false}
                required
                select
                name="endTime"
                value={_.get(form, 'values.endTime', '') || ''}
                onChange={handleSelectChange}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal"
              >
                {endIntervalOptions.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </div>
        </Box>
      </div>
    </>
  )
})

IntervalRangePicker.displayName = DISPLAY_NAME

IntervalRangePicker.propTypes = {
  className: PropTypes.string,
}

export default IntervalRangePicker
