import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useTheme } from '@material-ui/core/styles'
import { Graph, GraphGroup, Legend } from '../../graph/GraphGroup'
import * as common from '../../../graphs/common'
import { getTimeAtStartOf } from '../../../utility/utility'
import { FIVE_MINUTE_DURATION, ONE_HOUR_DURATION } from '../../../utility/constants'
import { getTimeSeriesGraph } from '../../../utility/graphUtility'
import { powerGraphTemplate, stateOfEnergyGraphTemplate } from '../../../utility/graphTemplate'
import colors from '../../../config/colors'
import * as operationsGraphConfigs from './operationsGraphConfigs'

const TITLES = ['Power', 'State Of Energy']
const [TITLE_POWER, TITLE_ENERGY] = TITLES

const TEMPLATE_MAP_BY_TITLE = {
  [TITLE_POWER]: powerGraphTemplate,
  [TITLE_ENERGY]: stateOfEnergyGraphTemplate,
}

const DISPLAY_NAME = 'OperationsGraph'

const OperationsGraph = props => {
  const { asset, inProgress, selectedRange, displayRange, setDisplayRange } = props
  const {
    availabilityChartData,
    powerChartData,
    soeChartData,
    SOEMaxValues,
    SOEMinValues,
    expandedTelemetryChartData,
    saveLatestVals,
    expandedTelemetryLatestVals,
    consumedReserveEnergyChartData,
    scheduledDisChargeLimitValues,
    scheduledChargeLimitValues,
    scheduledMinSOEValues,
    scheduledMaxSOEValues,
    commercialDischargeLimitValues,
    commercialChargeLimtValues,
    commercialMinSOEValues,
    commercialMaxSOEValues,
  } = props
  const theme = useTheme()

  const marketStartHour = _.get(asset, 'market.data.trading_day_start_hour')
  const timezone = _.get(asset, 'market.data.timezone')
  const { start: startTime, end: endTime } = selectedRange

  const handleSetDisplayRange = useCallback(
    (start, end) => {
      setDisplayRange({ start, end })
    },
    [setDisplayRange],
  )

  const range = {
    start: moment(startTime).unix(),
    end: moment(endTime).unix(),
  }

  const bidInterval = _.has(asset, 'market.data.bid_interval') && _.get(asset, 'market.data.bid_interval') * 60

  const isPowerLoading = _.get(powerChartData, 'isLoading')
  const isExpandedTelemetryLoading = _.get(expandedTelemetryChartData, 'isLoading')
  const isAvailaiblityLoading = _.get(availabilityChartData, 'isLoading')
  const isSoeLoading = _.get(soeChartData, 'isLoading')
  const minSoeKwValueFromChartData = _.get(soeChartData, 'isLoading')
    ? 0
    : _.min(_.flatten(_.get(soeChartData, ['data', 'values'])))
  const isConsumedReserveLoading = _.get(consumedReserveEnergyChartData, 'isLoading')

  const isLoading =
    isPowerLoading !== false ||
    isExpandedTelemetryLoading !== false ||
    isAvailaiblityLoading !== false ||
    (asset.dualType && (isSoeLoading !== false || isConsumedReserveLoading !== false))

  const displayNames = TITLES.map(title => getDisplayNames(title))
  const dataList = []

  if (_.isNil(asset)) {
    return null
  }

  const combinedPowerData = combinePowerData(
    isLoading,
    powerChartData,
    availabilityChartData,
    expandedTelemetryChartData,
    scheduledDisChargeLimitValues,
    scheduledChargeLimitValues,
    commercialDischargeLimitValues,
    commercialChargeLimtValues
  )
  if (!_.isEmpty(combinedPowerData)) {
    dataList.push(combinedPowerData)
  }

  const numBatteries = _.get(asset, 'data.number_batteries')
  const batteryCapacity = _.get(asset, 'data.battery_capacity_kwh')
  const totalCapacity = batteryCapacity * numBatteries

  if (
    asset.dualType &&
    !_.isEmpty(soeChartData.data) &&
    !_.isNil(consumedReserveEnergyChartData) &&
    !_.isEmpty(consumedReserveEnergyChartData.data)
  ) {
    const combinedSOE = combineSOEData(
      isLoading,
      soeChartData,
      selectedRange,
      SOEMaxValues,
      SOEMinValues,
      totalCapacity,
      consumedReserveEnergyChartData.data.map(arr => (arr.value ? arr.value : null)),
      scheduledMinSOEValues,
      scheduledMaxSOEValues,
      commercialMinSOEValues,
      commercialMaxSOEValues,
    )
    dataList.push(combinedSOE)
  }

  const now = moment().tz(timezone)
  const isWithinToday = now.isSameOrAfter(startTime) && now.isBefore(endTime)
  const isFuture = now.isSameOrBefore(startTime)
  const graphs = dataList.map((line, index) => {
    const title = TITLES[index]
    const graphTemplate = _.cloneDeep(TEMPLATE_MAP_BY_TITLE[title])
    let extendLineIndexes = []
    let yesterdayLastVal = null
    if (isWithinToday || isFuture) {
      // order determined by EXPANDED_TELEMETRY_TAGS,
      // we only want to extend these ones (not all)
      extendLineIndexes = index === 0 ? [3, 4, 5, 6] : [3]
      if (isFuture) {
        yesterdayLastVal = expandedTelemetryLatestVals
      }
    }
    const extras = {
      colors: getColors(title, theme),
      extendLineIndexes: extendLineIndexes,
      yesterdayLastVal: yesterdayLastVal,
      saveLatestValsFn: saveLatestVals,
    }
    const graph = getTimeSeriesGraph(
      graphTemplate,
      title,
      displayNames[index],
      line.data || {},
      timezone,
      bidInterval,
      extras,
    )

    return graph
  })

  const [powerGraph = {}, soeGraph = {}] = graphs
  const [
    powerActual,
    powerForecast,
    availability,
    dischargePowerCapacityLimit,
    chargePowerCapacityLimit,
    overrideDischargePowerCapacityLimit,
    overrideChargePowerCapacityLimit,
    minimumActivePowerLimit,
    maximumActivePowerLimit,
    scheduledDisChargeLimit,
    scheduledChargeLimit,
    commercialDischargeLimit,
    commercialChargeLimt,
  ] = _.get(powerGraph, 'series', [])

  const [soe, soeMax, soeMin, consumedReserve, scheduledMinSOE, scheduledMaxSOE, commercialMinSOE, commercialMaxSOE] = _.get(soeGraph, 'series', [])

  const stripLineOptions = {
    type: 'line',
    name: 'stripLine',
    visible: false,
    axisXType: 'secondary',
    axisYType: 'secondary',
  }

  const getPowerData = async () => {
    const powerOptions = _.get(powerActual, 'options', {})

    const powerForecastOptions = _.get(powerForecast, 'options', {})
    powerForecastOptions.lineDashType = 'dash'
    const availabilityOptions = _.get(availability, 'options', {})
    availabilityOptions.lineDashType = 'dash'

    const dischargePowerCapacityLimitOptions = _.get(dischargePowerCapacityLimit, 'options', {})
    dischargePowerCapacityLimitOptions.color = colors.MEDIUM_SEA_GREEN

    const chargePowerCapacityLimitOptions = _.get(chargePowerCapacityLimit, 'options', {})
    chargePowerCapacityLimitOptions.color = colors.CORNFLOWER_BLUE

    const overrideDischargePowerCapacityLimitOptions = _.get(overrideDischargePowerCapacityLimit, 'options', {})
    overrideDischargePowerCapacityLimitOptions.color = colors.FERN_GREEN
    overrideDischargePowerCapacityLimitOptions.lineDashType = 'dash'

    const overrideChargePowerCapacityLimitOptions = _.get(overrideChargePowerCapacityLimit, 'options', {})
    overrideChargePowerCapacityLimitOptions.color = colors.YALE_BLUE
    overrideChargePowerCapacityLimitOptions.lineDashType = 'dash'

    const minimumActivePowerLimitOptions = _.get(minimumActivePowerLimit, 'options', {})
    minimumActivePowerLimitOptions.color = colors.SKY_MAGENTA

    const maximumActivePowerLimitOptions = _.get(maximumActivePowerLimit, 'options', {})
    maximumActivePowerLimitOptions.color = colors.SKY_MAGENTA

    const scheduledDisChargeLimitOptions = _.get(scheduledDisChargeLimit, 'options', {})
    scheduledDisChargeLimitOptions.color = colors.SOFT_RED

    const scheduledChargeLimitOptions = _.get(scheduledChargeLimit, 'options', {})
    scheduledChargeLimitOptions.color = colors.LAVENDER

    const commercialDisChargeLimitOptions = _.get(commercialDischargeLimit, 'options', {})
    commercialDisChargeLimitOptions.color = colors.SOFT_RED
    commercialDisChargeLimitOptions.lineDashType = 'dash'

    const commercialChargeLimitOptions = _.get(commercialChargeLimt, 'options', {})
    commercialChargeLimitOptions.color = colors.LAVENDER
    commercialChargeLimitOptions.lineDashType = 'dash'


    if (asset.dualType) {
      return {
        powerOptions,
        dischargePowerCapacityLimitOptions,
        chargePowerCapacityLimitOptions,
        overrideDischargePowerCapacityLimitOptions,
        overrideChargePowerCapacityLimitOptions,
        minimumActivePowerLimitOptions,
        maximumActivePowerLimitOptions,
        stripLineOptions,
        scheduledDisChargeLimitOptions,
        scheduledChargeLimitOptions,
        commercialDisChargeLimitOptions,
        commercialChargeLimitOptions,
      }
    } else {
      return {
        powerOptions,
        powerForecastOptions,
        availabilityOptions,
        stripLineOptions,
      }
    }
  }

  const getSoeData = async () => {
    const soeOptions = _.get(soe, 'options', {})
    const soeMaxOptions = _.get(soeMax, 'options', {})
    const soeMinOptions = _.get(soeMin, 'options', {})
    const consumedReserveOptions = _.get(consumedReserve, 'options', {})
    const scheduledMinSOEOptions = _.get(scheduledMinSOE, 'options', {})
    const scheduledMaxSOEOptions = _.get(scheduledMaxSOE, 'options', {})
    const commercialMinSOEOptions = _.get(commercialMinSOE, 'options', {})
    const commercialMaxSOEOptions = _.get(commercialMaxSOE, 'options', {})

    consumedReserve.type = 'area'
    soeOptions.type = 'area'
    soeMaxOptions.type = 'line'
    soeMinOptions.type = 'line'
    scheduledMinSOEOptions.type = 'line'
    scheduledMaxSOEOptions.type = 'line'
    commercialMinSOEOptions.type = 'line'
    commercialMaxSOEOptions.type = 'line'
    commercialMinSOEOptions.lineDashType = 'dash'
    commercialMaxSOEOptions.lineDashType = 'dash'

    soeMinOptions.color = colors.SKY_MAGENTA
    scheduledMinSOEOptions.color = colors.SOFT_RED
    scheduledMaxSOEOptions.color = colors.LAVENDER
    commercialMinSOEOptions.color = colors.SOFT_RED
    commercialMaxSOEOptions.color = colors.LAVENDER

    return {
      soeOptions,
      soeMaxOptions,
      soeMinOptions,
      consumedReserveOptions,
      stripLineOptions,
      scheduledMinSOEOptions,
      scheduledMaxSOEOptions,
      commercialMinSOEOptions,
      commercialMaxSOEOptions,
    }
  }

  const FIVE_MINUTES = FIVE_MINUTE_DURATION.asMilliseconds()
  const getEndOfInterval = time => {
    if (!_.isNil(time)) {
      const elapsedTimeInMs = time.valueOf() % FIVE_MINUTES
      const newEndOfInterval = moment(time.valueOf() - elapsedTimeInMs + FIVE_MINUTES)
        .subtract(1, 'seconds')
        .endOf('minute')
      return newEndOfInterval
    }
  }

  const nextInterval = getEndOfInterval(moment()).unix()
  const inProgressOrIsLoading = inProgress || isLoading
  return (
    <GraphGroup
      currentTime={getTimeAtStartOf(moment(), FIVE_MINUTE_DURATION.asMinutes()).unix()}
      setDisplayRange={handleSetDisplayRange}
      displayRange={displayRange}
      range={range}
      context={{ timezone, marketStartHour, nowFloorDuration: ONE_HOUR_DURATION }}
      inProgress={inProgressOrIsLoading}
    >
      <Graph
        name={TITLE_POWER}
        getData={getPowerData}
        height={250}
        formatXAxis={common.formatXAxis}
        formatX2Axis={operationsGraphConfigs.formatX2Axis(nextInterval)}
        formatYAxis={operationsGraphConfigs.formatPowerYAxis}
        formatSeries={operationsGraphConfigs.formatSeries}
        formatTooltip={common.formatTooltip}
      />
      <Legend items={getLegendItems(asset, TITLE_POWER, theme)} />
      {_.get(asset, 'dualType', false) && [
        <Graph
          key={0}
          name={TITLE_ENERGY}
          getData={getSoeData}
          height={250}
          formatXAxis={common.formatXAxis}
          formatX2Axis={operationsGraphConfigs.formatX2Axis(nextInterval)}
          formatYAxis={operationsGraphConfigs.formatSoeYAxis(totalCapacity, minSoeKwValueFromChartData)}
          formatSeries={operationsGraphConfigs.formatSeries}
          formatTooltip={operationsGraphConfigs.formatSOETooltip(totalCapacity)}
        />,
        <Legend key={1} items={getLegendItems(asset, TITLE_ENERGY, theme)} />,
      ]}
    </GraphGroup>
  )
}

OperationsGraph.displayName = DISPLAY_NAME

OperationsGraph.propTypes = {
  asset: PropTypes.object,
  selectedRange: PropTypes.object,
}

const mapStateToProps = state => ({
  collated: state.collated,
  forecast: state.forecast,
  assetOperation: state.assetOperation,
})

const mapValues = (dataArr, index, isCharge = false, isPowerCapLimit = false) => {
  return _.get(dataArr, index, []).map(point => {
    const val = _.get(point, 'value')
    return isCharge ? (!_.isNil(val) ? val * -1.0 : val) : val
  })
}

const combinePowerData = (
  isLoading,
  powerData,
  availabilityData,
  expandedTelemetryData,
  scheduledDisChargeLimitValues,
  scheduledChargeLimitValues,
  commercialDischargeLimitValues,
  commercialChargeLimtValues
) => {
  if (!isLoading && _.isArray(_.get(powerData, 'data.values'))) {
    const powerDataCopy = _.cloneDeep(powerData)
    // change order of line to ["Actual Power", "Forecast Availability", "Actual Availability"]
    powerDataCopy.data.values.push(availabilityData.data.values[0])
    powerDataCopy.data.values.push(availabilityData.data.values[1])
    // order determined by order of request, determined by EXPANDED_TELEMETRY_TAGS in constants.js
    const extendedTelemetryDataArr = _.get(expandedTelemetryData, 'data', [])
    powerDataCopy.data.values.push(mapValues(extendedTelemetryDataArr, 0, false, true))
    powerDataCopy.data.values.push(mapValues(extendedTelemetryDataArr, 1, true, true))
    powerDataCopy.data.values.push(mapValues(extendedTelemetryDataArr, 2, false, true))
    powerDataCopy.data.values.push(mapValues(extendedTelemetryDataArr, 3, true, true))
    powerDataCopy.data.values.push(mapValues(extendedTelemetryDataArr, 4))
    powerDataCopy.data.values.push(mapValues(extendedTelemetryDataArr, 5))
    powerDataCopy.data.values.push(scheduledDisChargeLimitValues)
    powerDataCopy.data.values.push(scheduledChargeLimitValues)
    powerDataCopy.data.values.push(commercialDischargeLimitValues)
    powerDataCopy.data.values.push(commercialChargeLimtValues)
    return powerDataCopy
  }
  return []
}

const combineSOEData = (
  isLoading,
  soeData,
  selectedRange,
  SOEMaxValues,
  SOEMinValues,
  capacity,
  consumedReserveEnergyValues,
  scheduledMinSOEValues,
  scheduledMaxSOEValues,
  commercialMinSOEValues,
  commercialMaxSOEValues,
) => {
  if (!isLoading) {
    const soeDataCopy = { ...soeData }
    soeDataCopy.data.values.push(SOEMaxValues)
    soeDataCopy.data.values.push(SOEMinValues)
    soeDataCopy.data.values.push(consumedReserveEnergyValues)
    soeDataCopy.data.values.push(scheduledMinSOEValues)
    soeDataCopy.data.values.push(scheduledMaxSOEValues)
    soeDataCopy.data.values.push(commercialMinSOEValues)
    soeDataCopy.data.values.push(commercialMaxSOEValues)
    return soeDataCopy
  }
  return []
}

const getDisplayNames = title => {
  switch (title) {
    case 'Power':
      return [
        'Power',
        'Forecast Availability',
        'Actual Availability',
        'Discharge Power Capacity Limit',
        'Charge Power Capacity Limit',
        'Override Discharge Power Capacity Limit',
        'Override Charge Power Capacity Limit',
        'Minimum Active Power Limit',
        'Maximum Active Power Limit',
        'Contractual Discharge Limit',
        'Contractual Charge Limit',
        'Commercial Discharge Limit',
        'Commercial Charge Limit',
      ]
    case 'State Of Energy':
      return ['State Of Energy', 'Max SOE', 'Min SOE', 'Consumed Reserve', 'Contractual Min SOE', 'Contractual Max SOE', 'Commercial Min SOE', 'Commercial Max SOE']
    default:
      return ['Power']
  }
}

const getColors = (title, theme) => {
  const [skyBlue, yaleBlue, fernGreen, skyMagenta, softRed, lavender] =
    theme.custom.palette.graphs.assetOperationsSeriesColors
  switch (title) {
    case 'Power':
      return [skyBlue, yaleBlue, fernGreen, softRed, lavender]
    case 'State Of Energy':
      return [skyBlue, skyMagenta, fernGreen, softRed, lavender]
    default:
      return [skyBlue]
  }
}

const getLegendItems = (asset, title, theme) => {
  const [skyBlue, yaleBlue, fernGreen, skyMagenta, mediumSeaGreen, cornflowerBlue, softRed, lavender] =
    theme.custom.palette.graphs.assetOperationsSeriesColors
  switch (title) {
    case 'Power':
      if (asset.dualType) {
        return [
          {
            id: 'POWER',
            name: 'Power',
            color: skyBlue,
          },
          {
            id: 'DISCHARGE_POWER_CAP_LIMIT',
            name: 'Discharge Power Capacity Limit',
            color: mediumSeaGreen,
          },
          {
            id: 'CHARGE_POWER_CAP_LIMIT',
            name: 'Charge Power Capacity Limit',
            color: cornflowerBlue,
          },
          {
            id: 'OVERRIDE_DISCHARGE_POWER_CAP_LIMIT',
            name: 'Override Discharge Power Capacity Limit',
            color: fernGreen,
          },
          {
            id: 'OVERRIDE_CHARGE_POWER_CAP_LIMIT',
            name: 'Override Charge Power Capacity Limit',
            color: yaleBlue,
          },
          {
            id: 'MIN_MAX_ACTIVE_POWER',
            name: 'Min/Max Active Power Limit',
            color: skyMagenta,
          },
          {
            id: 'SCHEDULED_DISCHARGE_LIMIT',
            name: 'Contractual Discharge Limit',
            color: softRed,
          },
          {
            id: 'SCHEDULED_CHARGE_LIMIT',
            name: 'Contractual Charge Limit',
            color: lavender,
          },
          {
            id: 'COMMERCIAL_DISCHARGE_LIMIT',
            name: 'Commercial Discharge Limit',
            color: softRed,
          },
          {
            id: 'COMMERCIAL_CHARGE_LIMIT',
            name: 'Commercial Charge Limit',
            color: lavender,
          },
        ]
      } else {
        return [
          {
            id: 'POWER',
            name: 'Power',
            color: skyBlue,
          },
          {
            id: 'FORECAST_AVAILABILITY',
            name: 'Forecast Availability',
            color: yaleBlue,
          },
          {
            id: 'ACTUAL_AVAILABILITY',
            name: 'Actual Availability',
            color: fernGreen,
          },
        ]
      }
    case 'State Of Energy':
      return [
        {
          id: 'SOE',
          name: 'State of Energy (SOE)',
          color: skyBlue,
        },
        {
          id: 'SOE_MINMAX',
          name: 'Max/Min SOE',
          color: skyMagenta,
        },
        {
          id: 'CONSUMED_RESERVE',
          name: 'Consumed Reserve',
          color: fernGreen,
        },
        {
          id: 'SCHEDULED_MIN_SOE',
          name: 'Contractual Min SOE',
          color: softRed,
        },
        {
          id: 'SCHEDULED_MAX_SOE',
          name: 'Contractual Max SOE',
          color: lavender,
        },
        {
          id: 'COMMERCIAL_MIN_SOE',
          name: 'Commercial Min SOE',
          color: softRed,
        },
        {
          id: 'COMMERCIAL_MAX_SOE',
          name: 'Commercial Max SOE',
          color: lavender,
        },
      ]
    default:
      return []
  }
}

export default connect(mapStateToProps)(OperationsGraph)
